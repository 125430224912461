import {Button, Modal, Space, Spin, Tag, Tooltip} from "antd";
import React, {FC, memo, useEffect, useState} from "react";
import {blue} from "@ant-design/colors";
import {EditOutlined} from "@ant-design/icons";
import EditRestrictiveBooking from "./EditRestrictiveBooking";
import {
    Course,
    Maybe,
    useGetRestrictiveBookingsByCourseIdQuery,
} from "../../../generated/graphql";
import PreviewSmall from "./PreviewSmall";

type EditRestrictiveBookingModalProps = {
    courseId: Maybe<Course["id"]> | undefined;
    tooltipText?: string;
    refetch?: () => void;
    initialOpen?: boolean;
    onSetOpenState?: (open: boolean) => void;
};

const EditRestrictiveBookingModal: FC<EditRestrictiveBookingModalProps> = ({
    courseId,
    tooltipText,
    refetch,
    initialOpen = false,
    onSetOpenState,
}) => {
    const [open, setOpen] = useState(initialOpen);
    const [active, setActive] = useState(false);

    React.useEffect(() => {
        onSetOpenState?.(open);
    }, [onSetOpenState, open]);

    const {data, loading} = useGetRestrictiveBookingsByCourseIdQuery({
        skip: !courseId,
        variables: {
            courseId: courseId ?? "",
        },
        fetchPolicy: "network-only",
    });
    const existingRestrictiveBooking = data?.getBookingRestrictionByCourseId;

    useEffect(() => {
        if (active !== existingRestrictiveBooking?.active) {
            if (existingRestrictiveBooking) {
                setActive(existingRestrictiveBooking.active);
            } else {
                setActive(false);
            }
        }
    }, [active, existingRestrictiveBooking]);

    const ExtraTag = ({active}: {active: boolean}) => {
        const text = active ? "Aktiv" : "Inaktiv";

        return (
            <Tag
                color={active ? "green" : "red"}
                style={{borderRadius: "20px"}}
            >
                {text}
            </Tag>
        );
    };

    if (!open) {
        return (
            <Tooltip title={tooltipText}>
                <div
                    style={{
                        border: `1px solid lightgrey`,
                        borderRadius: 6,
                        padding: 3,
                    }}
                >
                    <Button
                        onClick={() => setOpen(true)}
                        style={{
                            width: "100%",
                            color: blue[5],
                            borderColor: blue[3],
                        }}
                        disabled={!courseId}
                    >
                        <Space>
                            <EditOutlined />
                            {"Buchungsbeschränkung bearbeiten"}
                            <ExtraTag active={active} />
                        </Space>
                    </Button>
                    <PreviewSmall
                        courseId={courseId}
                        existingRestrictiveBooking={existingRestrictiveBooking}
                    />
                </div>
            </Tooltip>
        );
    }

    if (loading) {
        return <Spin />;
    }

    return (
        <Modal
            title={
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingRight: 20,
                    }}
                >
                    <div>{"Buchungsbeschränkung bearbeiten"}</div>
                    <ExtraTag active={active} />
                </div>
            }
            open={open}
            onCancel={() => setOpen(false)}
            footer={null}
            width={600}
            bodyStyle={{padding: 0}}
            destroyOnClose
        >
            <EditRestrictiveBooking
                courseId={courseId}
                setOpen={setOpen}
                setActive={setActive}
                existingRestrictiveBooking={existingRestrictiveBooking}
                refetch={refetch}
            />
        </Modal>
    );
};

export default memo(EditRestrictiveBookingModal);
