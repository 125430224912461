import {Button, Space, Table} from "antd";
import React, {FC, memo, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import Maybe from "graphql/tsutils/Maybe";
import {ColumnProps} from "antd/lib/table";
import {
    ArrayDataOptions,
    BlankMailTypes,
    GetCoursesWithPaginationQuery,
    MailDataType,
    TablesEnum,
} from "../../../../generated/graphql";
import i18n from "../../../../services/i18n";
import Page from "../../../../views/Page/Page";
import Section from "../../../../views/Section/Section";
import TableToolBar, {
    useRowSelection,
} from "../../../common/tableTools/TableToolBar";
import {TableType, useHandleColumns, rowKey} from "../CourseListColumns";
import ActiveFilters from "../../../common/tableTools/ActiveFilters";
import {allColumnsDefault as allColumns} from "../CourseList";
import {baseSetupVariables, onChange} from "./baseSetupVariables";

const {
    standardHiddenColumns,
    mandatoryColumns,
    standardColumns,
    createLink,
    editLink,
    detailsLink,
    defaultToolBarButtons,
} = baseSetupVariables;

type CourseListSelectionProps = {
    tableData: {
        dataSource: GetCoursesWithPaginationQuery["coursesWithPagination"]["items"];
        existMore?: GetCoursesWithPaginationQuery["coursesWithPagination"]["existsMore"];
        total?: GetCoursesWithPaginationQuery["coursesWithPagination"]["total"];
        loading?: boolean;
    };
    options: Maybe<ArrayDataOptions>;
    setOptions: (options: ArrayDataOptions) => void;
    refetch: () => void;
    tablesEnum: TablesEnum;
    toolBarButtons?: Array<
        "columnSelector" | "pdf" | "eMail" | "bulkDelete" | "checkList"
    >;
    tableTitle?: string;
    defaultColumns?: Array<Extract<keyof TableType, string>>;
    hiddenColumns?: Array<Extract<keyof TableType, string>>;
    actionColumn?: Function;
    handleCourseSelection?: Function;
    selectedCourseIds?: Maybe<Array<string>>;
    defaultPageSize?: number;
    customMenu?: React.ReactNode;
    selectionType?: "checkbox" | "radio";
    liftRowSelection?: (selectedRowKeys: Array<string>) => void;
    showHeader?: boolean;
    renderWithoutPage?: boolean;
};

const CourseListSelection: FC<CourseListSelectionProps> = ({
    toolBarButtons = defaultToolBarButtons,
    tableTitle = i18n.containers.courses.Courses.courseTableTitle(),
    defaultColumns = standardColumns,
    actionColumn,
    hiddenColumns = standardHiddenColumns,
    handleCourseSelection,
    selectedCourseIds,
    defaultPageSize = 15,
    tableData,
    customMenu,
    options,
    setOptions,
    refetch,
    tablesEnum,
    selectionType,
    liftRowSelection,
    showHeader = true,
    renderWithoutPage = false,
}) => {
    const history = useHistory();
    const [columnsToShow, setColumnsToShow] = useState<
        Array<Extract<keyof TableType, string>>
    >([]);

    const hideFiltersAndSorters = false;
    const columns = useHandleColumns(
        columnsToShow,
        editLink,
        detailsLink,
        options,
        setOptions,
        actionColumn,
        options?.filter,
        hideFiltersAndSorters,
    );

    const rowSelection = useRowSelection({
        type: selectionType,
        defaultSelectedKeys: selectedCourseIds ?? [],
        preserveSelectedRowKeys: true,
    });

    useEffect(() => {
        if (liftRowSelection) {
            liftRowSelection(rowSelection.selectedRowKeys);
        }
    }, [liftRowSelection, rowSelection.selectedRowKeys]);

    const menu = React.useMemo(
        () =>
            customMenu ? (
                customMenu
            ) : (
                <Button
                    type="primary"
                    size="small"
                    onClick={() => history.push(createLink)}
                >
                    {i18n.containers.courses.Courses.createCourse()}
                </Button>
            ),
        [customMenu, history],
    );

    const handleSetColumnsToShow = React.useCallback(
        (columns: Array<string>) => {
            setColumnsToShow(
                columns as Array<Extract<keyof TableType, string>>,
            );
        },
        [],
    );

    const handleTableChange = React.useCallback(
        async (pagination, filters, sorter, extra) => {
            return onChange({
                pagination,
                filters,
                sorter,
                extra,
                defaultPageSize,
                options,
                setOptions,
            });
        },
        [defaultPageSize, options, setOptions],
    );

    const paginationConfig = React.useMemo(
        () => ({
            current:
                options?.offset && options?.limit
                    ? options.offset / options.limit + 1
                    : 1,
            pageSize: options?.limit ?? defaultPageSize,
            showSizeChanger: true,
            pageSizeOptions: ["10", "15", "20", "25"],
            defaultPageSize,
            total: tableData.total,
        }),
        [defaultPageSize, options, tableData.total],
    );

    const renderCourseTable = () => (
        <Section
            title={tableTitle}
            menu={menu}
            showHeader={showHeader}
            secondLineLeft={
                <Space
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignContent: "center",
                        padding: "2px 0px",
                    }}
                >
                    <TableToolBar
                        buttons={toolBarButtons}
                        columns={columns}
                        allColumns={allColumns()}
                        variableKeys={allColumns()}
                        columnsToShow={columnsToShow}
                        setSelectedColumns={handleSetColumnsToShow}
                        mandatoryColumns={mandatoryColumns}
                        hiddenColumns={hiddenColumns}
                        rowSelection={rowSelection}
                        exportFileName={tableTitle}
                        dataSource={tableData.dataSource}
                        type={BlankMailTypes.Course}
                        mailDataType={MailDataType.Course}
                        tablesEnum={tablesEnum}
                        defaultColumns={defaultColumns as Array<string>}
                    />
                    <ActiveFilters
                        options={options}
                        setOptions={setOptions}
                        refetch={refetch}
                    />
                </Space>
            }
            content={
                <Table<TableType>
                    showSorterTooltip={false}
                    size="small"
                    bordered
                    columns={columns}
                    dataSource={tableData.dataSource as Array<TableType>}
                    rowKey={rowKey}
                    loading={tableData.loading}
                    sticky={tableData.dataSource.length > 0}
                    scroll={
                        tableData.dataSource.length
                            ? {
                                  x: `max-content`,
                                  y: "calc(100vh - 300px)",
                              }
                            : {x: "auto"}
                    }
                    onChange={handleTableChange}
                    rowSelection={rowSelection.result}
                    pagination={paginationConfig}
                    footer={(currentPageData) =>
                        `${i18n.containers.bookings.BookingList.totalCount()} ${
                            tableData.total
                        }`
                    }
                />
            }
        />
    );

    if (renderWithoutPage) {
        return renderCourseTable();
    }

    return (
        <Page data-testid="CourseListSelection" content={renderCourseTable()} />
    );
};

export default memo(CourseListSelection);
