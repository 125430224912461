import gql from "graphql-tag";

export const GET_LOCAL_STATE = gql`
    query getLocalState {
        LocalState @client {
            createCourse {
                locationId
            }
            bookAttendees {
                selectedAttendee {
                    id
                    firstname
                    bookedCourseIds
                }
                selectedCourseId
            }
        }
    }
`;

export const GET_INSTRUCTORS_BY_ID = gql`
    query getInstructorsById($ids: [ID!]!) {
        findInstructorByIds(instructorIds: $ids) {
            id
            firstname
            lastname
            level
        }
    }
`;

// getReferenceCoursesLink
export const GET_REFERENCE_COURSES_LINK = gql`
    query getReferenceCoursesLink($courseId: ID!) {
        getReferenceCoursesLink(courseId: $courseId)
    }
`;

export const REMOVE_COURSE_BY_ID = gql`
    mutation removeCourse($id: ID!) {
        removeCourse(id: $id)
    }
`;

export const BOOK_ATTENDEE_LOCAL_STATE = gql`
    query bookAttendeeGetLocalState {
        LocalState @client {
            bookAttendees {
                selectedAttendee {
                    id
                    firstname
                    bookedCourseIds
                }
                selectedCourseId
            }
        }
    }
`;

export const BOOK_ATTENDEE_GET_SELECTED_ATTENDEE = gql`
    query bookAttendeeGetSelectedAttendee {
        LocalState @client {
            bookAttendees {
                selectedAttendee {
                    id
                    firstname
                    bookedCourseIds
                }
            }
        }
    }
`;

export const BOOK_ATTENDEE_GET_SELECTED_COURSE = gql`
    query bookAttendeeGetSelectedCourse {
        LocalState @client {
            bookAttendees {
                selectedCourseId
            }
        }
    }
`;

export const BOOK_ATTENDEE_SET_SELECTED_ATTENDEE = gql`
    mutation bookAttendeeSetSelectedAttendee(
        $id: ID
        $firstname: String
        $bookedCourseIds: [String]
    ) {
        bookAttendeeSetSelectedAttendee(
            id: $id
            firstname: $firstname
            bookedCourseIds: $bookedCourseIds
        ) @client
    }
`;

export const BOOK_ATTENDEE_SET_SELECTED_COURSE = gql`
    mutation bookAttendeeSetSelectedCourse($id: ID) {
        bookAttendeeSetSelectedCourse(id: $id) @client
    }
`;

export const UPDATE_LOCAL_LOCATION_ID = gql`
    mutation updateLocalLocationId($id: ID) {
        updateLocalLocationId(id: $id) @client
    }
`;

export const GET_COURSES = gql`
    query GetCourses {
        courses {
            id
            courseNumber
            prefixedCourseNumber
            bookingCount
            #canceledBookingsCount
            billedBookingCount
            freePlaces
            settledBookingCount
            courseType {
                id
                name
                description
                color
            }
            startDateTime
            endDateTime
            location {
                id
                city
                name
            }
            venue {
                id
                name
            }
            annotation
            instructors {
                id
                firstname
                lastname
            }
            maxAttendees
            coursePrice {
                id
                grossPrice
                netPrice
                vat
                discount
                discountUnit
                isSpecial
            }
            paymentInterval
            allowMonthlyPayment
            monthlySchedule
            isActive
            showInWeb
        }
    }
`;

export const GET_COURSES_WITH_PAGINATION = gql`
    query GetCoursesWithPagination(
        $options: ArrayDataOptions
        $currentUserOnly: Boolean
        $excludedCourseIds: [String!]
    ) {
        coursesWithPagination(
            options: $options
            currentUserOnly: $currentUserOnly
            excludedCourseIds: $excludedCourseIds
        ) {
            existsMore
            total
            items {
                id
                courseNumber
                prefixedCourseNumber
                bookingCountWithoutWaitlist
                billedBookingCount
                freePlaces
                waitListCount
                bookingCount
                placeReservationsCount
                canceledBookingsCount
                settledBookingCount
                courseType {
                    id
                    name
                    description
                    color
                }
                startDateTime
                endDateTime
                endDateTimeFirstCourseLesson
                location {
                    id
                    city
                    name
                }
                venue {
                    id
                    name
                }
                annotation
                instructors {
                    id
                    firstname
                    lastname
                }
                maxAttendees
                coursePrice {
                    id
                    grossPrice
                    netPrice
                    vat
                    discount
                    discountUnit
                    isSpecial
                }
                paymentInterval
                allowMonthlyPayment
                monthlySchedule
                isActive
                showInWeb
                paymentType
                installmentRates {
                    id
                    courseId
                    installmentAmount
                    installmentDue
                    installmentType
                }
                lessonCount
                lessons {
                    id
                    startDateTime
                    endDateTime
                }
                visibility {
                    visibleForAll
                    restriction {
                        prerequisiteCourses {
                            id
                        }
                        membersOnly
                        startDateTime
                        endDateTime
                        inTimeRange
                        expireAction
                    }
                    reason
                    hideAfterDays
                }
            }
        }
    }
`;

export const GET_COURSE_BY_ID = gql`
    query getCourseById($id: String!) {
        courseById(id: $id) {
            id
            courseType {
                id
                name
                description
                showInWeb
            }
            courseNumber
            prefixedCourseNumber
            courseNumberFormat
            customCourseNumberPrefix
            location {
                id
                city
                name
                venues {
                    id
                    name
                }
            }
            venue {
                id
                name
            }
            instructors {
                id
                firstname
                lastname
                level
                email
            }
            coursePrice {
                id
                grossPrice
                vat
                discount
                discountUnit
                isSpecial
                includedEntranceFee
            }
            paymentInterval
            minAttendees
            maxAttendees
            maxWaitlist
            bookingCount
            canceledBookingsCount
            startDateTime
            endDateTime
            endDateTimeFirstCourseLesson
            cycle
            annotation
            websiteInfo
            additionalShortInfo
            allowMonthlyPayment
            allowUpFrontPayment
            monthlySchedule
            lessons {
                id
                locationId
                location {
                    id
                    city
                }
                venueId
                venue {
                    id
                    name
                }
                startDateTime
                endDateTime
                instructors {
                    id
                    firstname
                    lastname
                }
                courseLessonBookings {
                    id
                    bookingId
                    attendance
                    courseLessonId
                }
            }
            isActive
            showInWeb
            freePlaces
            installmentRates {
                id
                courseId
                installmentAmount
                installmentDue
                installmentType
            }
            paymentType
            visibility {
                visibleForAll
                restriction {
                    prerequisiteCourses {
                        id
                    }
                    membersOnly
                    startDateTime
                    endDateTime
                    inTimeRange
                    expireAction
                }
                reason
                hideAfterDays
            }
        }
    }
`;

export const GET_COURSE_BY_LOCATION_ID = gql`
    query GetCourseByLocationId($id: ID!) {
        courseByLocationId(id: $id) {
            id
            lessons {
                startDateTime
                endDateTime
            }
            courseType {
                name
                description
                annotation
                websiteInfo
            }
        }
    }
`;
export const UPDATE_COURSE = gql`
    mutation updateCourse(
        $id: String!
        $editCourseData: EditCourseInput!
        $fillFromWaitingList: Boolean
        $notifyBooker: Boolean
    ) {
        updateCourse(
            id: $id
            editCourseData: $editCourseData
            fillFromWaitingList: $fillFromWaitingList
            notifyBooker: $notifyBooker
        ) {
            id
            coursePrice {
                id
                vat
                grossPrice
                discount
                discountUnit
                courseId
                includedEntranceFee
            }
            paymentInterval
            minAttendees
            maxAttendees
            maxWaitlist
            startDateTime
            endDateTime
            endDateTimeFirstCourseLesson
            cycle
            annotation
            websiteInfo
            allowMonthlyPayment
            monthlySchedule
            isActive
            showInWeb
        }
    }
`;

export const GET_CITIES = gql`
    query GetCities {
        cities {
            city
        }
    }
`;

export const GET_LOCATIONS_SORTED_BY_CITY = gql`
    query GetLocationsSortedByCity {
        locationsSortedByCity {
            city
            locations {
                id
                city
                name
                venues {
                    id
                    name
                }
            }
        }
    }
`;

export const GET_LOCATIONS_BY_CITY = gql`
    query GetLocationsByCity($city: String!) {
        locationsByCity(city: $city) {
            id
            name
            venues {
                id
                name
            }
        }
    }
`;

export const GET_INSTRUCTORS = gql`
    query GetInstructors {
        instructors {
            id
            firstname
            lastname
            level
            leaveDate
            active
        }
    }
`;

export const CREATE_COURSE = gql`
    mutation CreateCourse($newCourseData: NewCourseInput!) {
        createCourse(newCourseData: $newCourseData) {
            id
        }
    }
`;

export const GET_COURSE_TYPE_STATE = gql`
    query GetCourseTypeState($id: String!) {
        courseTypeStateById(id: $id) {
            id
            isActive
            showInWeb
            name
        }
    }
`;

export const REMOVE_COURSETYPE_BY_ID = gql`
    mutation RemoveCourseType($id: ID!) {
        removeCourseType(id: $id)
    }
`;

export const CREATE_COURSE_TYPE = gql`
    mutation CreateCourseType($newCourseTypeData: NewCourseTypeInput!) {
        createCourseType(newCourseTypeData: $newCourseTypeData) {
            id
            isActive
            showInWeb
            name
            shortHandId
            category {
                id
            }
            description
            minAge
            maxAge
            paymentInterval
            minAttendees
            maxAttendees
            annotation
            websiteInfo
            inWeekPlanner
            bold
            color
            participationPrerequisites {
                id
                groupLabel
                groupDescription
                optionalInputFields
                minCheckCount
                courseTypeId
            }
        }
    }
`;

export const EDIT_COURSE_TYPE = gql`
    mutation UpdateCourseType(
        $id: String!
        $newCourseTypeData: NewCourseTypeInput!
    ) {
        updateCourseType(id: $id, newCourseTypeData: $newCourseTypeData) {
            id
            isActive
            showInWeb
            name
            shortHandId
            category {
                id
            }
            description
            minAge
            maxAge
            paymentInterval
            minAttendees
            maxAttendees
            annotation
            websiteInfo
            inWeekPlanner
            bold
            color
        }
    }
`;

export const COURSE_TYPE_GET_COURSE_CATEGORIES = gql`
    query CourseTypeGetCourseCategories {
        courseCategories {
            id
            name
        }
    }
`;

export const GET_COURSE_CATEGORIES = gql`
    query GetCourseCategories {
        courseCategories {
            id
            isActive
            name
            description
            linkedCourseTypes {
                id
                name
                color
            }
        }
    }
`;

export const GET_COURSE_CATEGORY = gql`
    query GetCourseCategory($id: String!) {
        courseCategoryById(id: $id) {
            id
            isActive
            name
            description
            linkedCourseTypes {
                id
                name
            }
        }
    }
`;

export const CREATE_COURSE_CATEGORY = gql`
    mutation CreateCourseCategory(
        $newCourseCategoryData: NewCourseCategoryInput!
    ) {
        createCourseCategory(newCourseCategoryData: $newCourseCategoryData) {
            id
            name
            isActive
            description
            linkedCourseTypes {
                id
            }
        }
    }
`;

export const EDIT_COURSE_CATEGORY = gql`
    mutation UpdateCourseCategory(
        $id: String!
        $newCourseCategoryData: NewCourseCategoryInput!
    ) {
        updateCourseCategory(
            id: $id
            newCourseCategoryData: $newCourseCategoryData
        ) {
            id
            name
            isActive
            description
            linkedCourseTypes {
                id
            }
        }
    }
`;

export const COURSE_GET_INSTRUCTORS = gql`
    query CourseGetInstructors {
        instructors {
            id
            firstname
            lastname
            level
        }
    }
`;

export const COURSE_GET_INSTRUCTORS_FOR_LOCATION = gql`
    query CourseGetInstructorsByLocation($locationId: String!) {
        findInstructorsByLocation(locationId: $locationId) {
            id
            firstname
            lastname
            level
            active
            leaveDate
        }
    }
`;

export const GET_COURSE_LESSONS_BY_COURSE_ID = gql`
    query GetCourseLessonsByCourseId($courseId: ID!) {
        courseLessonsByCourseId(courseId: $courseId) {
            id
            startDateTime
            endDateTime
        }
    }
`;

export const GET_COURSE_LESSONS_BY_COURSE_ID_WITH_COURSE_LESSON_BOOKINGS = gql`
    query GetCourseLessonsByCourseIdWithCourseLessonBookings($courseId: ID!) {
        courseLessonsByCourseId(courseId: $courseId) {
            id
            startDateTime
            endDateTime
            bookings {
                id
                attendee {
                    id
                    firstname
                }
            }
            courseLessonBookings {
                id
                courseLessonId
                attendance
                bookingId
            }
        }
    }
`;

export const GET_COURSE_LESSONS_BY_COURSE_ID_WITH_LOCATION = gql`
    query GetCourseLessonsByCourseIdWithLocation($courseId: ID!) {
        courseLessonsByCourseId(courseId: $courseId) {
            id
            startDateTime
            endDateTime
            location {
                id
                city
                name
            }
        }
    }
`;

export const GET_COURSE_VARIABLES_FOR_TEMPLATE = gql`
    query GetCourseVariablesForTemplate($courseId: ID!) {
        courseLessonsByCourseId(courseId: $courseId) {
            id
            startDateTime
            location {
                id
                city
                name
            }
            course {
                id
                courseNumber
                prefixedCourseNumber
                courseType {
                    id
                    name
                    description
                }
            }
            endDateTime
        }
    }
`;

export const IS_COURSE_NUMBER_AVAILABLE = gql`
    query IsCourseNumberAvailable(
        $customCourseNumberPrefix: String!
        $courseId: String!
    ) {
        isCourseNameUnique(
            customCourseNumberPrefix: $customCourseNumberPrefix
            courseId: $courseId
        )
    }
`;

export const GET_FREE_PLACES_BY_COURSE_ID = gql`
    query getFreePlacesByCourseId($id: String!) {
        courseById(id: $id) {
            id
            freePlaces
            bookingCount
        }
    }
`;

export const GET_MAX_ATTENDEES_BY_COURSE_ID = gql`
    query getMaxAttendeesByCourseId($id: String!) {
        courseById(id: $id) {
            id
            maxAttendees
        }
    }
`;

export const GET_ALL_COURSES_WITH_ALLOWMONTHLYPAYMENT = gql`
    query GetAllCoursesWithAllowMonthlyPayment {
        getAllCoursesWithAllowMonthlyPayment {
            id
            prefixedCourseNumber
            allowMonthlyPayment
        }
    }
`;

export const GET_COURSE_ANNOTATION = gql`
    query getCourseAnnotation($id: String!) {
        courseById(id: $id) {
            id
            annotation
        }
    }
`;

export const UPDATE_COURSE_ANNOTATION = gql`
    mutation updateCourseAnnotation($id: String!, $annotation: String) {
        updateCourseAnnotation(id: $id, annotation: $annotation) {
            id
            annotation
        }
    }
`;

export const GET_COURSE_MEMBER_DISCOUNT = gql`
    query getCourseMemberDiscount($id: String!) {
        courseById(id: $id) {
            id
            coursePrice {
                id
                discount
                discountUnit
            }
        }
    }
`;

export const COURSES_BY_IDS = gql`
    query getCoursesByIds($ids: [String!]!) {
        coursesByIds(ids: $ids) {
            id
            courseNumber
            prefixedCourseNumber
            bookingCount
            billedBookingCount
            freePlaces
            settledBookingCount
            courseType {
                id
                name
                description
                color
            }
            startDateTime
            endDateTime
            endDateTimeFirstCourseLesson
            location {
                id
                city
                name
            }
            venue {
                id
                name
            }
            annotation
            instructors {
                id
                firstname
                lastname
            }
            maxAttendees
            coursePrice {
                id
                grossPrice
                netPrice
                vat
                discount
                discountUnit
                isSpecial
            }
            paymentInterval
            allowMonthlyPayment
            monthlySchedule
            isActive
            showInWeb
        }
    }
`;

export const COURSES_BY_IDS_MINIMAL = gql`
    query getCoursesByIdsMinimal($ids: [String!]!) {
        coursesByIds(ids: $ids) {
            id
            prefixedCourseNumber
            courseType {
                id
                name
                color
            }
            startDateTime
            endDateTime
            endDateTimeFirstCourseLesson
        }
    }
`;

export const UPDATE_MULTIPLE_COURSES_SHOWINWEB = gql`
    mutation updateMultipleCoursesShowInWeb(
        $courseIds: [ID!]!
        $showInWeb: Boolean!
    ) {
        updateMultipleCoursesShowInWeb(
            courseIds: $courseIds
            showInWeb: $showInWeb
        ) {
            id
            showInWeb
        }
    }
`;
