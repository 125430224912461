/* eslint-disable no-negated-condition */
import {Alert, Button, message, Modal, Divider, Checkbox} from "antd";
import React, {FC, memo, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {
    CancelBookingAndGenerateReversalInvoiceComponentProps,
    GetBookingsByCourseDocument,
    GetBookinsDocument,
    GetCourseByIdDocument,
    GetSettledBookingsDocument,
    GetUnsettledBookingsDocument,
    useCancelBookingAndGenerateReversalInvoiceMutation,
    useGetAppSettingsQuery,
} from "../../../generated/graphql";
import i18n from "../../../services/i18n";
import {TableType} from "./BookingListColumns";
import css from "./ReverseBookingModal.less";
import {GET_BOOKINS} from "../bookings.graphql";
import {
    GET_SETTLED_BOOKINGS,
    GET_UNSETTLED_BOOKINGS,
} from "../../invoice/invoice.graphql";
import {URL_SETTINGS_APP} from "../../../models/url";
import {colorGrey} from "../../../styles/colors";

type ReverseBookingModalProps = {
    visible: boolean;
    closeModal: () => void;
    record: TableType;
    confirmAction?: (e: boolean) => void;
    refetch?: () => void;
};

const ReverseBookingModal: React.FC<ReverseBookingModalProps> = ({
    visible = false,
    closeModal,
    record,
    confirmAction = (e) => console.log(e),
    refetch,
}) => {
    const [sendReversalInvoice, setSendReversalInvoice] = useState(false);
    const {data: appSettingsData} = useGetAppSettingsQuery();

    useEffect(() => {
        if (appSettingsData) {
            setSendReversalInvoice(
                appSettingsData.settings?.sendReversalInvoice || false,
            );
        }
    }, [appSettingsData]);

    const invoiceId = record.invoice?.id;

    const confirmDeletion = (confirmed: boolean) => {
        confirmAction(confirmed);
    };

    const refetchQueries: CancelBookingAndGenerateReversalInvoiceComponentProps["refetchQueries"] = [
        "GetSettledBookingsDocument",
        {query: GetSettledBookingsDocument},
        "GetUnsettledBookingsDocument",
        {query: GetUnsettledBookingsDocument},
        "GetBookins",
        {query: GetBookinsDocument},
        "GetBookinsQuery",
        {query: GET_BOOKINS},
        "GetUnsettledBookings",
        {query: GET_UNSETTLED_BOOKINGS},
        "GetSettledBookings",
        {query: GET_SETTLED_BOOKINGS},
    ];

    // Only pushing this for CourseDetails, because we need to refetch the bookings for the specific course
    // If we are on the course details page otherwise there is no need to refetch the bookings
    if (record.courseId) {
        refetchQueries.push({
            query: GetBookingsByCourseDocument,
            variables: {id: record.courseId},
        });
        refetchQueries.push({
            query: GetCourseByIdDocument,
            variables: {id: record.courseId},
        });
    }

    const [
        cancelBookingAndCreateReversal,
        {data, loading, error: cancelBookingAndCreateReversalError},
    ] = useCancelBookingAndGenerateReversalInvoiceMutation({
        awaitRefetchQueries: true,
        refetchQueries,
    });
    const email = record.attendee?.booker?.email;
    const handleOk = async () => {
        if (invoiceId) {
            const sendMail =
                email !== "" &&
                email !== null &&
                email !== undefined &&
                sendReversalInvoice;

            try {
                await cancelBookingAndCreateReversal({
                    variables: {id: invoiceId, sendMail},
                })
                    .then(() => confirmDeletion(true))
                    .then(() => refetch?.())
                    .then(() => closeModal())
                    .then(() =>
                        message.success(
                            `${i18n.containers.bookings.BookingList.bookingReversal.messageSuccess()}`,
                            3,
                        ),
                    );
            } catch (error) {
                console.log(
                    "cancelBookingAndCreateReversal error: ",
                    cancelBookingAndCreateReversalError,
                );
                throw new Error(`Error on cancel booking: ${error}`);
            }
        }
    };

    const handleCancel = () => {
        refetch?.();
        confirmDeletion(false);
        closeModal();
    };

    const NotificationSelection = ({style}: {style?: React.CSSProperties}) => {
        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const checked = e.target.checked;
            const appSettings = appSettingsData?.settings.sendReversalInvoice;

            if (appSettings === true && checked === true) {
                setSendReversalInvoice(false);
            } else if (appSettings === true && checked === false) {
                setSendReversalInvoice(true);
            } else if (appSettings === false && checked === true) {
                setSendReversalInvoice(true);
            } else {
                setSendReversalInvoice(false);
            }
        };

        const checked =
            sendReversalInvoice !==
            appSettingsData?.settings?.sendReversalInvoice;

        const actualStyle = {
            width: "100%",
            display: "flex",
            justifyContent: "center",
            ...style,
        };

        return (
            <div style={actualStyle}>
                <Checkbox checked={checked} onChange={handleChange}>
                    {appSettingsData?.settings?.sendReversalInvoice === true
                        ? i18n.containers.bookings.BookingList.bookingReversal.sendReversalInvoice.overrideDoNotSendReversalInvoice()
                        : i18n.containers.bookings.BookingList.bookingReversal.sendReversalInvoice.overrideSendReversalInvoice()}
                </Checkbox>
            </div>
        );
    };

    return (
        <Modal
            title={i18n.containers.bookings.BookingList.bookingReversal.modalTitle()}
            open={visible}
            onCancel={() => handleCancel()}
            destroyOnClose
            onOk={handleOk}
            footer={[
                <Button
                    key="cancelReversalInvoice"
                    onClick={() => handleCancel()}
                >
                    {i18n.containers.bookings.BookingList.bookingReversal.cancel()}
                </Button>,
                <Button
                    key="okReversalInvoice"
                    type="danger"
                    onClick={handleOk}
                    // loading={cancelBookingAndCreateQueryState.loading}
                    loading={loading}
                >
                    {!email
                        ? i18n.containers.bookings.BookingList.bookingReversal.cancelNow()
                        : sendReversalInvoice
                        ? i18n.containers.bookings.BookingList.bookingReversal.cancelWithNotification()
                        : i18n.containers.bookings.BookingList.bookingReversal.cancelWithoutNotification()}
                </Button>,
            ]}
        >
            <Alert
                type="warning"
                message={
                    <div className={css.header}>
                        {i18n.containers.bookings.BookingList.bookingReversal.messageHeader()}
                    </div>
                }
                description={
                    <div className={css.content}>
                        <p>
                            {i18n.containers.bookings.BookingList.bookingReversal.messageP1()}
                        </p>
                        <p>
                            {i18n.containers.bookings.BookingList.bookingReversal.messageP2()}
                        </p>
                    </div>
                }
            />
            {email && (
                <>
                    <Divider />
                    <Alert
                        type="warning"
                        message={
                            <div className={css.header}>
                                {i18n.containers.bookings.BookingList.bookingReversal.sendReversalInvoice.header()}
                            </div>
                        }
                        description={
                            <div className={css.content}>
                                {email &&
                                    (appSettingsData?.settings
                                        ?.sendReversalInvoice === true ? (
                                        <p>
                                            {i18n.containers.bookings.BookingList.bookingReversal.sendReversalInvoice.messageP2Active()}
                                        </p>
                                    ) : (
                                        <p>
                                            {i18n.containers.bookings.BookingList.bookingReversal.sendReversalInvoice.messageP2InActive()}
                                        </p>
                                    ))}
                            </div>
                        }
                    />

                    <div
                        style={{
                            textAlign: "center",
                            fontSize: "0.9em",
                            color: colorGrey,
                            marginTop: 20,
                        }}
                    >
                        <p>
                            {i18n.containers.bookings.BookingList.bookingReversal.sendReversalInvoice.messageP3()}
                        </p>
                        <Link
                            to={URL_SETTINGS_APP}
                            style={{padding: "5px 12px", fontSize: "1em"}}
                            onMouseDown={(e) => e.preventDefault()}
                        >
                            {i18n.containers.bookings.BookingList.bookingReversal.sendReversalInvoice.toAppSettings()}
                        </Link>
                    </div>
                    <NotificationSelection style={{marginTop: 20}} />
                </>
            )}
        </Modal>
    );
};

export default memo(ReverseBookingModal);
