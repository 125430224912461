import React, {FC, useRef, useState} from "react";
import {ColumnProps} from "antd/lib/table";
import {useHistory} from "react-router-dom";
import {Badge, InputRef, Space} from "antd";
import {blue, red, yellow} from "@ant-design/colors";
import css from "../../../views/TableColumnRenderers/TableColumnRenderers.less";
import {
    GetCoursesQuery,
    useRemoveCourseMutation,
    GetCoursesWithPaginationDocument,
    ArrayDataOptions,
    GetCoursesWithPaginationQuery,
    Maybe,
    CourseVisibilityResult,
    useGetCourseTypesLazyQuery,
    Course,
} from "../../../generated/graphql";
import {
    renderUnknown,
    renderCourseType,
    renderCourseNumber,
    renderTextStandard,
    renderFreePlaces,
    renderInstructors,
    renderBooleanAsCheckmark,
    renderCourseLessons,
    renderCourseDetails,
    renderSettled,
    renderFirstCourseLesson,
    RenderPricePer,
    renderLastCourseLesson,
    renderContinuousText,
    renderEnabledCourses,
} from "../../../views/TableColumnRenderers/TableColumnRenderers";
import i18n from "../../../services/i18n";
import ActionButtonGroup, {
    deleteMessageStateFromApolloQueryResult,
    useDeleteMessage,
} from "../../../views/ActionButtonGroup/ActionButtonGroup";
import {isDefined} from "../../../helpers/typeScriptHelpers";
import {QueryItem} from "../../../types";
import columnSortFilterTest, {
    AutoCompleteOptions,
    InputType,
} from "../../../helpers/columnSortFilterTest";
import useHighestRole from "../../../helpers/useHighestRole";
import Roles from "../../../../shared/models/roles";
import RenderVisibility from "../../../views/TableColumnRenderers/RenderVisibility";

type ExtraColumns = {
    city: "city";
    firstCourseLesson: "firstCourseLesson";
    lastCourseLesson: "lastCourseLesson";
    actions: "actions";
    courseDetails: "courseDetails";
    lessons: "lessons";
    waitlistCount: "waitlistCount";
    bookingsCount: "bookingsCount";
    // placeReservationsCount: "placeReservationsCount";
    bookingOverview: "bookingOverview";
    // enabledCourses: "enabledCourses";
    enabledCourses: Course["enabledCourses"];
};

export type TableType = GetCoursesWithPaginationQuery["coursesWithPagination"]["items"][number] &
    ExtraColumns;

export type GetCoursesQueryItem = QueryItem<GetCoursesQuery["courses"]>;

export const rowKey: (course: TableType) => TableType["id"] = (course) =>
    course.id;

export const useHandleColumns = (
    columnsToShow: Array<Extract<keyof TableType, string>>,
    editLink: string,
    detailsLink: string,
    options: Maybe<ArrayDataOptions> | undefined,
    setOptions: (options: ArrayDataOptions) => void,
    actionColumn?: Function,
    activeFilters?: ArrayDataOptions["filter"],
    hideFiltersAndSorters?: boolean,
    // eslint-disable-next-line max-params
) => {
    const [dropdownOpen, setDropdownOpen] = useState<string | null>(null);
    const shownColumns: Array<ColumnProps<TableType>> = [];

    const [
        courseTypeAutoCompleteOptions,
        setCourseTypeAutoCompleteOptions,
    ] = useState<AutoCompleteOptions>([]);

    const [
        getCourseTypes,
        {data: courseTypesData, loading: loadingCourseTypes},
    ] = useGetCourseTypesLazyQuery();

    React.useEffect(() => {
        if (
            !loadingCourseTypes &&
            courseTypeAutoCompleteOptions.length === 0 &&
            dropdownOpen
        ) {
            const courseTypeAutoCompleteOptions = courseTypesData?.courseTypes?.map(
                (courseType) => ({
                    label: courseType.name,
                    value: courseType.name,
                }),
            );

            if (courseTypeAutoCompleteOptions) {
                setCourseTypeAutoCompleteOptions(courseTypeAutoCompleteOptions);
            }
            getCourseTypes();
        }
    }, [
        courseTypeAutoCompleteOptions,
        courseTypesData,
        dropdownOpen,
        getCourseTypes,
        loadingCourseTypes,
    ]);

    const history = useHistory();
    const highestRole = useHighestRole();

    const getColumnsToShow = (
        columns: Array<{dataIndex: string} & TableType>,
    ) => {
        columns.forEach((col) => {
            if (columnsToShow.some((value) => value === col.dataIndex)) {
                shownColumns.push(col);
            }
        });
        moveActionColumnToLastPosition(shownColumns);
    };

    const moveActionColumnToLastPosition = (
        shownColumns: Array<ColumnProps<TableType>>,
    ) => {
        shownColumns.forEach((col) => {
            if (col.fixed === "right") {
                shownColumns.push(
                    shownColumns.splice(shownColumns.indexOf(col), 1)[0],
                );
            }
        });
    };

    const columns: Array<{dataIndex: string} & TableType> = [];

    const column: ColumnProps<TableType> = {
        title: "",
        fixed: false,
    };

    const inputRef = useRef<InputRef>(null);

    const ref = inputRef.current?.focus({
        cursor: "all",
    });

    columnsToShow.forEach((col: Extract<keyof TableType, string>) => {
        const c = {...column};

        const colTitle = i18n.views.TableColumnTitles[col]();

        const rawTitle = colTitle;
        const title =
            rawTitle.length <= 12 ? (
                rawTitle
            ) : (
                <span style={{whiteSpace: "pre-line"}}>
                    {rawTitle.replace(" ", "\n")}
                </span>
            );

        const standardEllipsis = false;

        c.ellipsis = standardEllipsis;
        c.title = title;
        c.dataIndex = col;
        c.ellipsis = false;
        c.width = 100 + colTitle.length * 2;

        let path = c.dataIndex;

        const sortFilter = ({
            dataIndex,
            disabled,
            type,
            selectOptions,
            activeFilters,
            autoCompleteOptions,
        }: {
            dataIndex: string;
            disabled?: boolean;
            type: InputType;
            selectOptions?: Array<{
                label: string;
                value: string;
            }>;
            activeFilters?: ArrayDataOptions["filter"] | undefined;
            autoCompleteOptions?: AutoCompleteOptions;
        }) =>
            // eslint-disable-next-line max-params
            {
                // if (hideFiltersAndSorters) {
                //     return null;
                // }

                Object.entries(
                    columnSortFilterTest({
                        dataIndex,
                        type,
                        selectOptions,
                        options,
                        setOptions,
                        dropdownOpen,
                        setDropdownOpen,
                        autoCompleteOptions,
                    }),
                ).map(([key, value]) => {
                    if (key !== "filteredValue") {
                        // @ts-ignore
                        return (c[key] = disabled ? null : value);
                    }

                    return null;
                });
            };

        const stdWithSetting: string | number | undefined = undefined;
        const stdColSpan: number | undefined = undefined;

        switch (col) {
            case "actions":
                c.fixed = "right";
                c.width = 10;
                c.align = "center";
                if (actionColumn) {
                    c.render = (text, record: TableType) =>
                        renderActions(
                            record,
                            editLink,
                            detailsLink,
                            actionColumn,
                            highestRole,
                        );
                } else {
                    c.render = (text, record: TableType) =>
                        renderActions(
                            record,
                            editLink,
                            detailsLink,
                            undefined,
                            highestRole,
                        );
                }
                break;
            case "courseDetails":
                c.fixed = "right";
                c.width = 10;
                c.align = "center";
                c.title = null;
                c.render = (text, record: TableType) =>
                    renderCourseDetails(detailsLink.replace(":id", record.id), {
                        ...record,
                        from: {pathname: history.location.pathname},
                    });
                break;
            case "prefixedCourseNumber":
                path = "prefixedCourseNumber";
                c.fixed = "left";
                c.render = (text, record: TableType) =>
                    renderCourseNumber(
                        {
                            courseNumber:
                                record.prefixedCourseNumber ?? undefined,
                            courseId: record.id,
                            // editLink: detailsLink.replace(":id", record.id),
                            detailsLink,
                        },
                        css.primary,
                    );
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    type: "search",
                });
                c.sorter = false;
                break;
            case "courseType":
                path = "courseType.name";
                c.render = (text, record: TableType) =>
                    renderCourseType({
                        name: record.courseType.name,
                        color: record.courseType.color,
                    });
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    type: "autoComplete",
                    autoCompleteOptions: courseTypeAutoCompleteOptions,
                });
                break;
            case "firstCourseLesson":
                path = "startDateTime";
                c.render = (text, record: TableType) =>
                    renderFirstCourseLesson({
                        startEnd: {
                            startDateTime: record.startDateTime,
                            endDateTime: record.endDateTimeFirstCourseLesson,
                        },
                    });
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    type: "date",
                });
                break;
            case "lastCourseLesson":
                c.render = (text, record: TableType) =>
                    renderLastCourseLesson({
                        courseLessons: record.lessons,
                    });
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: true,
                    type: "date",
                });
                break;
            case "location":
                path = "location.name";
                c.render = (text, record: TableType) =>
                    renderTextStandard(record.location.name);
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    type: "search",
                });
                // c.sorter = false;
                break;
            case "venue":
                path = "venue.name";
                c.render = (text, record: TableType) =>
                    renderTextStandard(record.venue.name);
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: true,
                    type: "search",
                });
                c.sorter = false;
                break;
            case "freePlaces":
                c.render = (text, record: TableType) =>
                    renderFreePlaces(record.freePlaces);
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: true,
                    type: "search",
                });
                c.sorter = false;
                break;
            case "maxAttendees":
                c.align = "center";
                c.render = (text, record: TableType) =>
                    renderTextStandard(record.maxAttendees);
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: true,
                    type: "search",
                });
                c.sorter = false;
                break;
            case "instructors":
                path = "instructors.0.firstname";
                c.render = (text, record: TableType) =>
                    renderInstructors(record.instructors);
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    type: "search",
                });
                c.sorter = false;
                break;
            case "coursePrice":
                path = "coursePrice.0.grossPrice";
                c.align = "right";
                c.render = (text, record: TableType) => ({
                    displayName: "Price per",
                    children: (
                        <RenderPricePer
                            feeAmount={record.coursePrice[0].grossPrice}
                            paymentInterval={record.paymentInterval}
                        />
                    ),
                });
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: true,
                    type: "search",
                });
                c.sorter = false;
                break;
            case "allowMonthlyPayment":
                c.align = "center";
                c.render = (_, record: TableType) =>
                    renderBooleanAsCheckmark(
                        isDefined(record.allowMonthlyPayment)
                            ? record.allowMonthlyPayment
                            : false,
                    );
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: true,
                    type: "search",
                });
                c.sorter = false;
                break;
            case "lessons":
                c.width = stdWithSetting;
                c.align = "center";
                c.render = (_, record: TableType) =>
                    renderCourseLessons(record.id);
                break;
            case "lessonCount":
                // c.width = stdWithSetting;
                c.ellipsis = !standardEllipsis;
                c.align = "center";
                c.render = (_, record: TableType) =>
                    renderTextStandard(record.lessonCount);
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: true,
                    type: "search",
                });
                c.sorter = false;
                break;
            case "city":
                c.width = stdWithSetting;
                path = "location.city";
                c.align = "center";
                c.render = (_, record: TableType) =>
                    renderTextStandard(`${record.location.city}`);
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: true,
                    type: "search",
                });
                // c.sorter = false;
                c.sorter = true;
                break;

            case "settledBookingCount":
                path = "location.city";
                c.render = (_, record: TableType) =>
                    renderSettled(
                        record.settledBookingCount,
                        record.bookingCountWithoutWaitlist,
                    );
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: true,
                    type: "search",
                });
                c.sorter = false;
                break;
            case "billedBookingCount":
                path = "location.city";
                c.render = (_, record: TableType) => record.settledBookingCount;
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: true,
                    type: "search",
                });
                c.sorter = false;
                break;
            case "showInWeb":
                c.align = "center";
                c.width = 100;
                c.render = (text, record: TableType) => ({
                    displayName: "Visibility",
                    children: (
                        <RenderVisibility
                            courseVisibilityResult={
                                record.visibility as CourseVisibilityResult
                            }
                        />
                    ),
                });
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: false,
                    // type: "boolean",
                    type: "select",
                    selectOptions: [
                        {
                            label: "Sichtbar für alle",
                            value: "visibleForAll",
                        },
                        {
                            label: "Nicht sichtbar",
                            value: "notVisible",
                        },
                        {
                            label: "Für Mitglieder",
                            value: "membersOnly",
                        },
                        {
                            label: "Voraussetzungskurse",
                            value: "prerequisiteCourses",
                        },
                    ],
                });
                c.sorter = false;
                break;
            // path = "showInWeb";
            // c.align = "center";
            // c.render = (_, record: TableType) =>
            //     renderBooleanAsCheckmark(
            //         isDefined(record.showInWeb) ? record.showInWeb : false,
            //     );
            // sortFilter({
            //     dataIndex: c.dataIndex,
            //     disabled: true,
            //     type: "search",
            // });
            // c.sorter = false;
            // break;
            case "bookingCount":
                // c.ellipsis = !standardEllipsis;
                c.render = (_, record: TableType) => "test";
                // renderTextStandard(record.bookingCount);
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: true,
                    type: "search",
                });
                c.sorter = false;
                break;
            case "waitlistCount":
                c.align = "center";
                c.render = (_, record: TableType) => record.waitListCount;
                // c.render = (_, record: TableType) =>
                //     renderWaitlistCount({
                //         courseId: record.id,
                //         kind: "waitlist",
                //         freePlaces: record.freePlaces,
                //     });
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: true,
                    type: "search",
                });
                c.sorter = false;
                break;
            case "bookingsCount":
                c.align = "center";
                c.render = (_, record: TableType) => record.bookingCount;
                // c.render = (_, record: TableType) =>
                //     renderWaitlistCount({
                //         courseId: record.id,
                //         kind: "bookings",
                //         freePlaces: record.freePlaces,
                //     });
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: true,
                    type: "search",
                });
                c.sorter = false;
                break;
            case "placeReservationsCount":
                c.align = "center";
                c.render = (_, record: TableType) =>
                    record.placeReservationsCount;
                // c.render = (_, record: TableType) => "test";
                // c.render = (_, record: TableType) =>
                //     renderWaitlistCount({
                //         courseId: record.id,
                //         kind: "placereservations",
                //         freePlaces: record.freePlaces,
                //     });
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: true,
                    type: "search",
                });
                c.sorter = false;
                break;

            case "bookingOverview":
                // c.width = 200;
                c.render = (_, record: TableType) => ({
                    displayName: "Booking Overview",
                    children: (
                        <Space direction="vertical" size={0}>
                            <div style={{whiteSpace: "nowrap"}}>
                                <Badge color={blue[5]} />
                                <Space
                                    size={3}
                                    style={{
                                        fontSize: "0.8rem",
                                        paddingLeft: "0.5em",
                                    }}
                                >
                                    <strong>{record.bookingCount}</strong>
                                    {"Buchungen"}
                                </Space>
                            </div>
                            <div style={{whiteSpace: "nowrap"}}>
                                <Badge color={yellow[5]} />
                                <Space
                                    size={3}
                                    style={{
                                        fontSize: "0.8rem",
                                        paddingLeft: "0.5em",
                                    }}
                                >
                                    <strong>{record.waitListCount}</strong>
                                    {"Wartelisteeinträge"}
                                </Space>
                            </div>
                            <div style={{whiteSpace: "nowrap"}}>
                                <Badge color={yellow[5]} />
                                <Space
                                    color={red[5]}
                                    style={{
                                        fontSize: "0.8rem",
                                        paddingLeft: "0.5em",
                                    }}
                                >
                                    <strong>
                                        {record.placeReservationsCount}
                                    </strong>
                                    {"Platzreservierungen"}
                                </Space>
                            </div>
                        </Space>
                    ),
                });

                // renderWaitlistCount({
                //     courseId: record.id,
                //     kind: "all",
                //     freePlaces: record.freePlaces,
                // });
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: true,
                    type: "search",
                });
                c.sorter = false;
                break;
            case "isActive":
                c.width = 50;
                c.align = "center";
                c.render = (_, record: TableType) =>
                    renderBooleanAsCheckmark(record.isActive);
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: true,
                    type: "boolean",
                });
                c.sorter = false;
                break;
            case "annotation":
                c.render = (_, record: TableType) =>
                    renderContinuousText(record.annotation);
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: true,
                    type: "search",
                });
                c.sorter = false;
                break;
            case "enabledCourses":
                c.render = (_, record: TableType) =>
                    renderEnabledCourses({
                        enabledCourses: record.enabledCourses ?? [],
                    });
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: true,
                    type: "search",
                });
                c.sorter = false;
                break;
            // case "prerequisiteCourses":
            //     c.render = (_, record: TableType) =>
            //         record.prerequisiteCourses
            //             .map((pc) => pc.prefixedCourseNumber)
            //             .join(", ");
            //     sortFilter({
            //         dataIndex: c.dataIndex,
            //         disabled: true,
            //         type: "search",
            //     });
            //     c.sorter = false;
            //     break;
            // case "visibility":
            //     c.align = "center";
            //     c.width = 90;
            //     c.render = (text, record: TableType) => ({
            //         displayName: "Visibility",
            //         children: (
            //             <RenderVisibility
            //                 courseVisibilityResult={
            //                     record.visibility as CourseVisibilityResult
            //                 }
            //             />
            //         ),
            //     });
            //     sortFilter({
            //         dataIndex: c.dataIndex,
            //         disabled: true,
            //         type: "boolean",
            //     });
            //     c.sorter = false;
            //     break;
            default:
                c.fixed = false;
                // c.render = (text) => `!${renderUnknown(text)}`;
                c.render = (text) => renderUnknown(text);
                sortFilter({
                    dataIndex: c.dataIndex,
                    disabled: true,
                    type: "search",
                });
                c.sorter = false;
                break;
        }
        // @ts-ignore
        columns.push(c);
    });

    getColumnsToShow(columns);

    return shownColumns;
};

// ACTION BUTTONS---------------------------------------------------------------------------

const renderActions = (
    record: TableType,
    editLink: string,
    detailsLink: string,
    actionColumn?: Function,
    highestRole?: Roles,
) => {
    return (
        <RenderActions
            record={record}
            editLink={editLink}
            detailsLink={detailsLink}
            actionColumn={actionColumn}
            highestRole={highestRole}
        />
    );
};

type RenderActionsPros = {
    record: TableType;
    editLink: string;
    detailsLink: string;
    actionColumn?: Function;
    highestRole?: Roles;
};

const RenderActions: FC<RenderActionsPros> = ({
    record,
    editLink,
    detailsLink,
    actionColumn,
    highestRole,
}) => {
    const {
        id,
        bookingCount,
        waitListCount,
        placeReservationsCount,
        canceledBookingsCount,
        prefixedCourseNumber,
        courseType,
    } = record;

    const [
        removeCourse,
        {
            data: removeCourseData,
            loading: removeCourseLoading,
            error: removeCourseError,
        },
    ] = useRemoveCourseMutation({
        refetchQueries: [
            "GetCoursesWithPagination",
            {query: GetCoursesWithPaginationDocument},
        ],
    });

    const deleteMessageState = deleteMessageStateFromApolloQueryResult({
        data: removeCourseData,
        loading: removeCourseLoading,
        error: removeCourseError,
    });

    useDeleteMessage({
        state: deleteMessageState,
        loadingMessage: i18n.containers.courses.CourseEdit.delete.loadingMessage(),
        successMessage: i18n.containers.courses.CourseEdit.delete.successMessage(),
        errorMessage: removeCourseError?.message ?? "",
    });

    const count =
        bookingCount > 0
            ? Number(bookingCount).toString()
            : waitListCount > 0
            ? Number(waitListCount).toString()
            : placeReservationsCount > 0
            ? Number(placeReservationsCount).toString()
            : Number(canceledBookingsCount).toString();

    const deleteDisabledText =
        bookingCount > 0
            ? i18n.containers.courses.CourseEdit.delete.disabledText({count})
            : waitListCount > 0
            ? i18n.containers.courses.CourseEdit.delete.disabledTextWaitlist({
                  count,
              })
            : placeReservationsCount > 0
            ? i18n.containers.courses.CourseEdit.delete.disabledTextPlaceReservations(
                  {count},
              )
            : i18n.containers.courses.CourseEdit.delete.disabledTextCancelations(
                  {count},
              );

    return (
        <ActionButtonGroup
            detailsUrl={detailsLink.replace(":id", id)}
            detailsState={record}
            editUrl={editLink.replace(":id", id)}
            deleteDisabled={bookingCount !== 0 || canceledBookingsCount !== 0}
            deleteDisabledText={deleteDisabledText}
            deleteTestId="CourseDeleteButton"
            editTestId="CourseEditButton"
            deleteConfirmText={i18n.containers.courses.CourseEdit.delete.confirmText(
                {
                    courseNumber: prefixedCourseNumber,
                    courseType: courseType.name,
                },
            )}
            deleteOnConfirm={async () => {
                await removeCourse({
                    variables: {id},
                });
            }}
            highestRole={highestRole}
        />
    );
};
