import React, {FC, memo} from "react";
import Icon from "@ant-design/icons";
import {NavLink, Redirect, Route, Switch} from "react-router-dom";
import LayoutStandard from "../../views/LayoutStandard/LayoutStandard";
import {DashboardSvg} from "../../assets/SVG-Icons";
import i18n from "../../services/i18n";
import {URL_DASHBOARD} from "../../models/url";
import DashboardOverview from "./DashboardOverview/DashboardOverview";
import {getMainMenuItems} from "../common/MainMenuRoutes";
import useHighestRole from "../../helpers/useHighestRole";
import {SecondLevelNavigationItem} from "../../views/LayoutStandard/types";

type DashboardProps = {};

const Dashboard: FC<DashboardProps> = () => {
    const highestRole = useHighestRole();

    const menuItems = getMainMenuItems({highestRole});
    const secondLevelNavigationItems = menuItems.dashboard?.children;

    return (
        <LayoutStandard
            pageTitle={(navOpen) =>
                navOpen ? (
                    <Icon component={DashboardSvg} />
                ) : (
                    <span>{i18n.containers.dashboard.Dashboard.title()}</span>
                )
            }
            secondLevelNavigation={
                secondLevelNavigationItems && (
                    <SecondLevelNavigation
                        secondLevelNavigationItems={secondLevelNavigationItems}
                    />
                )
            }
            main={main}
        />
    );
};

const main = (
    <Switch>
        <Route exact path={URL_DASHBOARD} component={DashboardOverview} />
        <Redirect to={URL_DASHBOARD} />
    </Switch>
);

const SecondLevelNavigation = ({
    secondLevelNavigationItems,
}: {
    secondLevelNavigationItems: Array<SecondLevelNavigationItem>;
}) => {
    return (
        <>
            {secondLevelNavigationItems.map((item) => (
                <NavLink key={item.key} to={item.link}>
                    {item.label}
                </NavLink>
            ))}
        </>
    );
};

export default memo(Dashboard);
