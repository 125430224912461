import React, {FC} from "react";
import {Menu, MenuProps} from "antd";
import {useHistory, useLocation} from "react-router-dom";
import classNames from "classnames";
import {MainMenuProps, MenuNavigationItem} from "../types";
import css from "../LayoutStandard.less";
import Logout from "../../../containers/logout/Logout";
import {getMainMenuItems} from "../../../containers/common/MainMenuRoutes";
import {useGetCompanyInfoQuery} from "../../../generated/graphql";

const MainMenu: FC<MainMenuProps> = ({
    className,
    highestRole,
    rolesOfCurrent,
    isInstructor = false,
    menuTextClasses,
    closed,
    companyInfo,
    secondLevelNavigationItems,
    navigationIsOpen,
    isStage,
}) => {
    const history = useHistory();
    const location = useLocation();
    const mainMenuClasses = [css.mainMenu, className];
    const [openKeys, setOpenKeys] = React.useState<Array<string>>([]);
    const {data: companyInfoData} = useGetCompanyInfoQuery({
        // fetchPolicy: "network-only",
        fetchPolicy: "cache-first",
    });

    const featureWeekPlaner =
        companyInfoData?.companyInfo?.subscription?.featureWeekPlaner;

    const isMenuItemActive = (itemPath: string): boolean => {
        return (
            location.pathname.startsWith(`${itemPath}/`) ||
            location.pathname === itemPath
        );
    };

    // const mainMenuItems = (): Array<MenuNavigationItem> => {
    const mainMenuItems = (): MenuProps["items"] => {
        const items = Object.values(
            getMainMenuItems({highestRole, featureWeekPlaner}),
        ).map((item) => ({
            ...item,
            onTitleClick: () => {
                history.push(item.link);
            },
            className: isMenuItemActive(item.link)
                ? isStage
                    ? classNames("ant-menu-item-selected", css.stage)
                    : "ant-menu-item-selected"
                : "",
        }));

        const itemsWithChildren = items.map((item) => {
            if (!item.children || item.children.length <= 1) {
                return {
                    ...item,
                    children: undefined,
                    onClick: () => {
                        if (item.children?.length === 1) {
                            history.push(item.children[0].link);
                        } else {
                            history.push(item.link);
                        }
                    },
                };
            }

            return {
                ...item,
                popupClassName: isStage
                    ? classNames(css.mainMenuAntPopup, css.stage)
                    : css.mainMenuAntPopup,

                children: item.children.map((child: MenuNavigationItem) => ({
                    ...child,
                    expandIcon: child.children?.length < 2 ? <></> : undefined,
                    onTitleClick: () => {
                        history.push(child.link);
                    },
                    className: isMenuItemActive(child.link)
                        ? isStage
                            ? classNames("ant-menu-item-selected", css.stage)
                            : "ant-menu-item-selected"
                        : "",
                })),
            };
        });

        return itemsWithChildren;
    };

    return (
        <nav className={classNames(mainMenuClasses)}>
            <Menu
                items={mainMenuItems()}
                className={
                    navigationIsOpen
                        ? isStage
                            ? classNames(css.mainMenuAnt, css.stage)
                            : classNames(css.mainMenuAnt)
                        : isStage
                        ? classNames(
                              css.mainMenuAnt,
                              css.mainMenuAntClosed,
                              css.stage,
                          )
                        : classNames(css.mainMenuAnt, css.mainMenuAntClosed)
                }
                theme="dark"
                forceSubMenuRender
                subMenuCloseDelay={0.2}
                openKeys={openKeys}
                selectedKeys={[location.pathname]}
                onOpenChange={(openKeys) => {
                    setOpenKeys(openKeys);
                }}
            />
            <div className={css.logout}>
                <Logout highestRole={highestRole} menuClosed={closed} />
            </div>
        </nav>
    );
};

export default MainMenu;
