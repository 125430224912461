import {ORGANIZATION_ID} from "../const";

export const URL_HOME = "/";
export const URL_LEGAL_NOTICE = "/impressum";
export const URL_PRIVACY_POLICY = "/datenschutz";
// other
export const URL_BASE = "/" + ORGANIZATION_ID;
// attendees
export const URL_CUSTOMERS = URL_BASE + "/customers";
export const URL_ATTENDEES = URL_CUSTOMERS + "/attendees";
export const URL_BOOKERS = URL_CUSTOMERS + "/bookers";
export const URL_CUSTOMERS_DUPLICATES = URL_CUSTOMERS + "/duplicates";
export const URL_ATTENDEES_CREATE = URL_ATTENDEES + "/create";
export const URL_ATTENDEES_EDIT = URL_ATTENDEES + "/edit";
export const URL_ATTENDEES_EDIT_ID = URL_ATTENDEES + "/edit/:id";
export const URL_BOOKERS_CREATE = URL_BOOKERS + "/create";
export const URL_BOOKERS_EDIT = URL_BOOKERS + "/edit";
export const URL_BOOKERS_EDIT_ID = URL_BOOKERS + "/edit/:id";
export const URL_BOOKERS_DETAILS_ID = URL_BOOKERS + "/details/:id";
export const URL_BOOKERS_DETAILS_ATTENDEES_ID =
    URL_BOOKERS + "/details/attendee/:id";
// courses
export const URL_COURSES = URL_BASE + "/courses";
export const URL_COURSES_CREATE = URL_COURSES + "/create"; // default page
export const URL_COURSES_EDIT = URL_COURSES + "/edit";
export const URL_COURSES_EDIT_ID = URL_COURSES + "/edit/:id";
export const URL_COURSES_DETAILS_ID = URL_COURSES + "/coursedetails/:id";
export const URL_COURSES_DETAILS_ID_REFACTORED =
    URL_COURSES + "/coursedetailsrefactored/:id";
export const URL_COURSES_PREREQUISITE = URL_COURSES + "/prerequisite";

// courseTypes
export const URL_COURSES_TYPES = URL_COURSES + "/types";
export const URL_COURSES_TYPES_CREATE = URL_COURSES + "/types/create";
export const URL_COURSES_TYPES_EDIT = URL_COURSES + "/types/edit";
export const URL_COURSES_TYPES_EDIT_ID = URL_COURSES + "/types/edit/:id";
// courseCategories
export const URL_COURSES_CATEGORIES = URL_COURSES + "/categories";
export const URL_COURSES_CATEGORIES_CREATE = URL_COURSES + "/categories/create";
export const URL_COURSES_CATEGORIES_EDIT = URL_COURSES + "/categories/edit";
export const URL_COURSES_CATEGORIES_EDIT_ID =
    URL_COURSES + "/categories/edit/:id";

// locations
export const URL_LOCATIONS = URL_BASE + "/locations";
export const URL_LOCATIONS_CREATE = URL_LOCATIONS + "/create";
export const URL_LOCATIONS_OVERVIEW = URL_LOCATIONS + "/overview";
export const URL_LOCATIONS_EDIT = URL_LOCATIONS + "/edit/:id";
export const URL_LOCATIONS_CONTACTS = URL_LOCATIONS + "/contacts";
// bookings
export const URL_BOOKINGS = URL_BASE + "/bookings";
export const URL_BOOKING_CREATE = URL_BOOKINGS + "/create";
export const URL_BOOKING_EDIT_ID = URL_BOOKINGS + "/edit/:id";
export const URL_BOOKINGS_COURSE_LIST = URL_BOOKINGS + "/courseList";
export const URL_BOOKINGS_LIST = URL_BOOKINGS + "/bookings/list";
export const URL_BOOKINGS_SETTLEMENT_DETAILS =
    URL_BOOKINGS + "/settlementDetails/:id";
// MyBookings
export const URL_MY_BOOKINGS = URL_BASE + "/mybookings";
export const URL_MY_BOOKINGS_LIST = URL_MY_BOOKINGS + "/list";
export const URL_MY_BOOKINGS_CREATE = URL_MY_BOOKINGS + "/create";
export const URL_MY_BOOKINGS_EDIT = URL_MY_BOOKINGS + "/edit";
export const URL_MY_BOOKINGS_EDIT_ID = URL_MY_BOOKINGS + "/edit/:id";
export const URL_MY_BOOKINGS_COURSE_LIST = URL_MY_BOOKINGS + "/courseList";
// Instuctor specific
export const URL_INSTRUCTOR = URL_BASE + "/instructor";
export const URL_INSTRUCTOR_BOOKING_EDIT_ID = URL_INSTRUCTOR + "/edit/:id";
export const URL_INSTRUCTOR_INVOICE_SETTLEMENT_DETAILS =
    URL_INSTRUCTOR + "/settlementDetails/:id";
// staff
export const URL_STAFF = URL_BASE + "/staff";
export const URL_STAFF_CREATE = URL_STAFF + "/create";
export const URL_STAFF_OVERVIEW = URL_STAFF + "/edit";
export const URL_STAFF_EDIT = URL_STAFF + "/edit/:id";
// staffLogin
export const URL_STAFF_LOGIN = URL_BASE + "/staffLogin";
export const URL_STAFF_LOGIN_START = URL_STAFF_LOGIN + "/start";
export const URL_STAFF_LOGIN_FILES = URL_STAFF_LOGIN + "/files";
// settings
export const URL_SETTINGS = URL_BASE + "/settings";
export const URL_SETTINGS_APP = URL_SETTINGS + "/app";
export const URL_SETTINGS_COMPANY = URL_SETTINGS + "/company";
export const URL_SETTINGS_ENDUSERAPP = URL_SETTINGS + "/enduserapp";
export const URL_SETTINGS_ADMIN = URL_SETTINGS + "/admin";
export const URL_SETTINGS_IMPORT_BOOKERS = URL_SETTINGS + "/importBookers";
// login
export const URL_LOGIN = URL_BASE + "/login";
export const URL_LOGIN_PARAMS = URL_LOGIN + "/:code?";
// welcome page
export const URL_WELCOME_PAGE = URL_BASE + "/welcome";
// initial setup creation
export const URL_SETUP = URL_BASE + "/setup";
export const URL_SETUP_CREATE_PROFILE = URL_SETUP + "/create-profile";
// invoice
export const URL_INVOICE = URL_BASE + "/invoice";
export const URL_INVOICE_LIST_BY_COURSE =
    URL_INVOICE + "/list/bybooking/unsettled";
export const URL_INVOICE_LIST_BY_BOOKING_UNSETTLED =
    URL_INVOICE + "/list/bycourse";
export const URL_INVOICE_COURSE_DETAILS =
    URL_INVOICE + "/list/coursedetails/:id";
export const URL_INVOICE_SETTLEMENT_DETAILS =
    URL_INVOICE + "/list/settlementDetails/:id";
export const URL_INVOICE_LIST_BY_BOOKING_SETTLED =
    URL_INVOICE + "/list/bybooking/settled";
export const URL_INVOICE_LIST_BY_BOOKING_CANCELED =
    URL_INVOICE + "/list/bybooking/reversals";
// templates
export const URL_TEMPLATES = URL_BASE + "/templates";
export const URL_TEMPLATES_EMAIL = URL_TEMPLATES + "/email";
export const URL_TEMPLATES_INVOICES = URL_TEMPLATES + "/invoices";
export const URL_TEXT_MODULES = URL_TEMPLATES + "/textModules";
export const URL_TEMPLATES_CREATE = URL_TEMPLATES + "/create";
export const URL_TEMPLATES_EDIT = URL_TEMPLATES + "/edit";
// payments
export const URL_PAYMENTS = URL_BASE + "/payments";
export const URL_OPEN = URL_PAYMENTS + "/open";
export const URL_PAID = URL_PAYMENTS + "/paid";
// notifications
export const URL_NOTIFICATIONS = URL_BASE + "/notifications";
export const URL_NOTIFICATIONS_SENT_MAILS = URL_NOTIFICATIONS + "/sent";
export const URL_NOTIFICATIONS_FIXER = URL_NOTIFICATIONS + "/fixer";
// end-user-app
export const URL_ACCOUNT = URL_BASE + "/account";
export const URL_ENDUSER = URL_ACCOUNT + "/enduser";
export const URL_FORGOT_PASSWORD =
    URL_ENDUSER + "/forgot-password/:changePasswordId/:resetPasswordUrl";
export const URL_RESET_PASSWORD_SUCCESS =
    URL_ENDUSER + "/forgot-password/success/:resetPasswordUrl";
// changeLog
export const URL_CHANGELOG = URL_BASE + "/changelog/:highestRole";
// badges
export const URL_BADGES = URL_BASE + "/badges";
export const URL_BADGES_CREATE = URL_BADGES + "/create";
// permissions
export const URL_PERMISSIONS = URL_BASE + "/permissions";
export const URL_PERMISSION_DENIED = URL_PERMISSIONS + "/denied";
// lessonsToday
export const URL_LESSONS_TODAY = URL_BASE + "/lessonsToday";
export const URL_LESSONS_TODAY_LIST = URL_LESSONS_TODAY + "/list";
export const URL_LESSONS_TODAY_CALENDAR = URL_LESSONS_TODAY + "/calendar";
export const URL_LESSONS_TODAY_CALENDAR_WEEKVIEW =
    URL_LESSONS_TODAY + "/weekview";
export const URL_LESSONS_TODAY_MAIN = URL_LESSONS_TODAY + "/main";
// dashboard
export const URL_DASHBOARD = URL_BASE + "/dashboard";
export const URL_DASHBOARD_OVERVIEW = URL_DASHBOARD + "/overview";
