import React, {FC, memo} from "react";
import {Tag as AntTag} from "antd";
import {TagProps as AntTagProps} from "antd/lib/tag";
import classnames from "classnames";
import css from "./Tag.less";
import {colorBlue} from "../../styles/colors";

type TagProps = AntTagProps & {
    size?: "default" | "small";
};

const Tag: FC<TagProps> = (props) => {
    return (
        <AntTag
            color={colorBlue}
            {...props}
            className={classnames(css.root, props.className, {
                [css.small]: props.size === "small",
            })}
        />
    );
};

export default memo(Tag);
