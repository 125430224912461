import React, {FC, memo, useCallback} from "react";
import {FormikProps} from "formik";
import moment from "moment";
import {Modal} from "antd";
import {PosibleFormikProps} from "./EditRestrictiveBooking";
import LayoutForm from "../../../views/LayoutForm/LayoutForm";
import css from "./EditRestrictiveBookings.less";
import Button from "../../../views/Button/Button";
import i18n from "../../../services/i18n";
import {
    GeneralSettingsCard,
    MemberRestrictionCard,
    PrerequisiteCoursesCard,
} from "./RestrictiveBookingInputs";
import {ExpireAction} from "../../../generated/graphql";

type RestrictiveBookingFormProps = {
    formikProps: FormikProps<PosibleFormikProps>;
    submit: (values: PosibleFormikProps) => void;
    submitting: boolean;
    setActive: (active: boolean) => void;
    onDelete?: () => void;
    existingBookingRestriction?: boolean;
};

const RestrictiveBookingForm: FC<RestrictiveBookingFormProps> = ({
    formikProps,
    submit,
    submitting,
    setActive,
    onDelete,
    existingBookingRestriction,
}) => {
    const startAndEndAreInThePast = useCallback(() => {
        const {startDateTime, endDateTime} = formikProps.values;

        // Wenn nur endDateTime gesetzt ist und in der Vergangenheit liegt
        if (!startDateTime && endDateTime && moment().isAfter(endDateTime)) {
            return true;
        }

        // Wenn nur startDateTime gesetzt ist und in der Vergangenheit liegt
        if (startDateTime && !endDateTime && moment().isAfter(startDateTime)) {
            return true;
        }

        // Wenn beide Daten gesetzt sind und in der Vergangenheit liegen
        if (startDateTime && endDateTime) {
            return (
                moment().isAfter(startDateTime) && moment().isAfter(endDateTime)
            );
        }

        return false;
    }, [formikProps]);

    React.useEffect(() => {
        const isSomethingActive = (values: PosibleFormikProps) => {
            if (values.membersOnly) {
                return true;
            }
            if (
                values.prerequisiteCourses &&
                values.prerequisiteCourses.length > 0
            ) {
                return true;
            }

            return false;
        };
        const expireAction = formikProps.values.expireAction;
        const somethingActiveAndNotInThePast =
            isSomethingActive(formikProps.values) && !startAndEndAreInThePast();
        const inPastButHideFromAll =
            startAndEndAreInThePast() &&
            expireAction === ExpireAction.HideFromAll;

        const active = somethingActiveAndNotInThePast || inPastButHideFromAll;

        if (active !== formikProps.values.active) {
            formikProps.setFieldValue("active", active);
            // setActive(active);
        }
        setActive(active);
    }, [formikProps, formikProps.values, setActive, startAndEndAreInThePast]);

    const SettingsInThePast = () => {
        if (startAndEndAreInThePast()) {
            return (
                <div className={css.settingsInThePast}>
                    {
                        "Die Einstellungen liegen in der Vergangenheit und sind deshalb nicht mehr aktiv."
                    }
                </div>
            );
        }

        return null;
    };

    const handleDelete = () => {
        Modal.confirm({
            title: "Buchungsbeschränkung löschen",
            content: "Möchten Sie diese Buchungsbeschränkung wirklich löschen?",
            okText: "Ja",
            cancelText: "Nein",
            onOk: onDelete,
        });
    };

    return (
        <div>
            <LayoutForm columns={1} className={css.root}>
                <SettingsInThePast />
                <GeneralSettingsCard formikProps={formikProps} />
                {/* <VisibilityCard formikProps={formikProps} /> */}
                <MemberRestrictionCard formikProps={formikProps} />
                <PrerequisiteCoursesCard formikProps={formikProps} />
                <div style={{display: "flex", margin: "20px", gap: "10px"}}>
                    <Button
                        block
                        type="primary"
                        htmlType="submit"
                        onClick={async () => submit(formikProps.values)}
                        loading={submitting}
                    >
                        {i18n.containers.restrictiveBooking.RestrictiveBookingCreateEdit.save()}
                    </Button>
                    {existingBookingRestriction && (
                        <Button
                            block
                            type="danger"
                            onClick={handleDelete}
                            disabled={submitting}
                        >
                            {"Löschen"}
                        </Button>
                    )}
                </div>
            </LayoutForm>
        </div>
    );
};

export default memo(RestrictiveBookingForm);
