import {
    FilterValue,
    RowSelectionType,
    SorterResult,
    TableCurrentDataSource,
    TablePaginationConfig,
} from "antd/lib/table/interface";
import {
    ArrayDataOptions,
    GetCoursesWithPaginationQuery,
    Maybe,
    SortOrder,
} from "../../../../generated/graphql";
import {
    URL_COURSES_CREATE,
    URL_COURSES_DETAILS_ID,
    URL_COURSES_EDIT_ID,
} from "../../../../models/url";
import {TableType} from "../CourseListColumns";
import {updateFilters} from "../../../../helpers/updateFilters";
import {getPagination} from "../../../../helpers/getPagination";

export type CourseTableData = {
    dataSource: GetCoursesWithPaginationQuery["coursesWithPagination"]["items"];
    existMore?: GetCoursesWithPaginationQuery["coursesWithPagination"]["existsMore"];
    total?: GetCoursesWithPaginationQuery["coursesWithPagination"]["total"];
    loading?: boolean;
};

type BaseSetupVariablesType = {
    standardHiddenColumns: Array<keyof TableType>;
    mandatoryColumns: Array<keyof TableType>;
    standardColumns: Array<keyof TableType>;
    createLink: string;
    editLink: string;
    detailsLink: string;
    checkbox: RowSelectionType;
    radio: RowSelectionType;
    defaultToolBarButtons: Array<
        "columnSelector" | "pdf" | "eMail" | "bulkDelete" | "checkList"
    >;
};

export const baseSetupVariables: BaseSetupVariablesType = {
    standardHiddenColumns: [
        "id",
        "actions",
        "courseNumber",
        "paymentInterval",
        "actions",
    ],
    mandatoryColumns: ["prefixedCourseNumber"],
    standardColumns: [
        "actions",
        "prefixedCourseNumber",
        "courseType",
        "firstCourseLesson",
        "location",
        "venue",
        "freePlaces",
        "maxAttendees",
        "instructors",
        "coursePrice",
    ],
    createLink: URL_COURSES_CREATE,
    editLink: URL_COURSES_EDIT_ID,
    detailsLink: URL_COURSES_DETAILS_ID,
    checkbox: "checkbox",
    radio: "radio",
    defaultToolBarButtons: ["columnSelector", "pdf", "eMail", "bulkDelete"],
};

export const onChange = ({
    pagination,
    filters,
    sorter,
    extra,
    defaultPageSize,
    options,
    setOptions,
}: {
    pagination: TablePaginationConfig;
    filters: Record<string, FilterValue | null>;
    sorter: SorterResult<TableType> | Array<SorterResult<TableType>>;
    extra: TableCurrentDataSource<TableType>;
    defaultPageSize: number;
    options: Maybe<ArrayDataOptions | undefined>;
    setOptions: (options: ArrayDataOptions) => void;
}) => {
    console.log("options", options);
    console.log("pagination", pagination);

    const updatedFilters = updateFilters({
        filters,
        options,
        table: "course",
    });

    const sortColumn = sorter.field;
    const sortOrder: Maybe<SortOrder> =
        sorter.order === "ascend"
            ? SortOrder.Ascending
            : sorter.order === "descend"
            ? SortOrder.Descending
            : undefined;

    const updatedOptions = {
        ...options,
        filter: updatedFilters,
        limit: getPagination({
            pagination,
            defaultSize: defaultPageSize,
        }).limit,
        offset: getPagination({
            pagination,
            defaultSize: defaultPageSize,
        }).offset,
        sortColumn,
        sortOrder,
    };

    setOptions(updatedOptions);
};
