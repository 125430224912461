import Markdown from "react-markdown";
import React, {memo} from "react";

const RestrictiveBookingsWiki = () => {
    return (
        <Markdown>
            {`

## **Start- und Enddatum**

Das Start- und Enddatum definieren den Zeitraum, in dem die Buchungsbeschränkung aktiv ist. Das Startdatum muss vor dem Enddatum liegen.

#### **Aktion nach Ablauf des Zeitraums**

Wenn die Beschränkung ein Enddatum hat, kann hier definiert werden, was passieren soll, wenn der Zeitraum abgelaufen ist. Es gibt folgende Optionen:
- **Online nicht mehr buchbar:** Der Kurs wird nicht mehr auf der Website angezeigt und kann nicht mehr gebucht werden.
- **Buchbar für alle machen:** Der Kurs wird für alle Kunden sichtbar und buchbar. (Die Beschränkung wird aufgehoben)



---

## **Buchbarkeit auf Mitglieder begrenzen**
Wenn diese Option aktiviert ist, können nur Mitglieder diesen Kurs buchen.

---

## **Voraussetzungskurse**
Wenn Voraussetzungskurse definiert sind, können sich nur Kunden anmelden, die mindestens in einen dieser Kurse gebucht sind oder gebucht waren.

##### Warteliste berücksichtigen
Wenn diese Option aktiviert ist, können sich Kunden die auf der Warteliste einer der Voraussetzungskurse stehen anmelden.

##### Buchungsliste berücksichtigen
Wenn diese Option aktiviert ist, können sich alle Kunden die einen Platz in einem der Voraussetzungskurse gebucht haben anmelden.

---
            `}
        </Markdown>
    );
};

export default memo(RestrictiveBookingsWiki);
