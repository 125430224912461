import React from "react";
import {Icon} from "@ant-design/compatible";
import {
    AbzeichenSvg,
    AttendeesSvg,
    CoursesSvg,
    DashboardSvg,
    InvoicesSvg,
    LeftMenuBookingIcon,
    LocationsSvg,
    NotificationsSvg,
    SettingsSvg,
    StaffLoginSvg,
    StaffSvg,
    TransactionsSvg,
    VorlagenSvg,
    WeekPlannerSvg,
} from "../../assets/SVG-Icons";
import {
    MenuNavigationItem,
    URL_TO_KEY_MAP,
} from "../../views/LayoutStandard/types";
import i18n from "../../services/i18n";
import {
    URL_ATTENDEES,
    URL_BADGES,
    URL_BOOKERS,
    URL_BOOKINGS,
    URL_BOOKINGS_COURSE_LIST,
    URL_BOOKINGS_LIST,
    URL_COURSES,
    URL_COURSES_CATEGORIES,
    URL_COURSES_EDIT,
    URL_COURSES_PREREQUISITE,
    URL_COURSES_TYPES,
    URL_CUSTOMERS,
    URL_CUSTOMERS_DUPLICATES,
    URL_DASHBOARD,
    URL_INVOICE,
    URL_INVOICE_LIST_BY_BOOKING_CANCELED,
    URL_INVOICE_LIST_BY_BOOKING_SETTLED,
    URL_INVOICE_LIST_BY_BOOKING_UNSETTLED,
    URL_INVOICE_LIST_BY_COURSE,
    URL_LESSONS_TODAY,
    URL_LESSONS_TODAY_CALENDAR,
    URL_LESSONS_TODAY_CALENDAR_WEEKVIEW,
    URL_LOCATIONS,
    URL_LOCATIONS_CONTACTS,
    URL_LOCATIONS_OVERVIEW,
    URL_MY_BOOKINGS,
    URL_MY_BOOKINGS_COURSE_LIST,
    URL_MY_BOOKINGS_LIST,
    URL_NOTIFICATIONS,
    URL_NOTIFICATIONS_FIXER,
    URL_NOTIFICATIONS_SENT_MAILS,
    URL_OPEN,
    URL_PAID,
    URL_PAYMENTS,
    URL_SETTINGS,
    URL_SETTINGS_ADMIN,
    URL_SETTINGS_APP,
    URL_SETTINGS_COMPANY,
    URL_SETTINGS_ENDUSERAPP,
    URL_SETTINGS_IMPORT_BOOKERS,
    URL_STAFF,
    URL_STAFF_LOGIN,
    URL_STAFF_LOGIN_FILES,
    URL_STAFF_LOGIN_START,
    URL_STAFF_OVERVIEW,
    URL_TEMPLATES,
    URL_TEMPLATES_EMAIL,
    URL_TEMPLATES_INVOICES,
} from "../../models/url";
import Roles from "../../../shared/models/roles";
import {routePermissions} from "./AuthenticateSetup";

export const getMainMenuItems = ({
    highestRole,
    rolesWithAccess,
    featureWeekPlaner,
}: {
    highestRole: Roles;
    rolesWithAccess?: Array<Roles>;
    featureWeekPlaner?: boolean;
}): {[key: string]: MenuNavigationItem} => {
    const mainMenuItems = {
        ...(routePermissions[URL_DASHBOARD].includes(highestRole)
            ? {
                  dashboard: {
                      label: i18n.views.LayoutStandard.dashboard(),
                      key: URL_TO_KEY_MAP.URL_DASHBOARD as keyof typeof URL_TO_KEY_MAP,
                      icon: <Icon component={DashboardSvg} />,
                      link: URL_DASHBOARD,
                      children: [
                          {
                              topMenuKey: URL_TO_KEY_MAP.URL_DASHBOARD as keyof typeof URL_TO_KEY_MAP,
                              key: "1 dashboard",
                              label: i18n.containers.dashboard.DashboardOverview.title(),
                              link: URL_DASHBOARD,
                              children: [],
                          },
                      ],
                  },
              }
            : {}),
        ...(routePermissions[URL_CUSTOMERS].includes(highestRole)
            ? {
                  customers: {
                      label: i18n.views.LayoutStandard.customers(),
                      key: URL_TO_KEY_MAP.URL_CUSTOMERS as keyof typeof URL_TO_KEY_MAP,
                      icon: <Icon component={AttendeesSvg} />,
                      link: URL_CUSTOMERS,
                      children: [
                          {
                              topMenuKey: URL_TO_KEY_MAP.URL_CUSTOMERS as keyof typeof URL_TO_KEY_MAP,
                              key: "1bookers",
                              label: i18n.containers.customers.Customers.bookers(),
                              link: URL_BOOKERS,
                              children: [],
                          },
                          {
                              topMenuKey: URL_TO_KEY_MAP.URL_CUSTOMERS as keyof typeof URL_TO_KEY_MAP,
                              key: "2 attendees",
                              label: i18n.containers.customers.Customers.attendees(),
                              link: URL_ATTENDEES,
                              children: [],
                          },
                          ...(routePermissions[
                              URL_CUSTOMERS_DUPLICATES
                          ].includes(highestRole)
                              ? [
                                    {
                                        topMenuKey: URL_TO_KEY_MAP.URL_CUSTOMERS as keyof typeof URL_TO_KEY_MAP,
                                        key: "3duplicates",
                                        label: i18n.containers.customers.Customers.duplicates(),
                                        link: URL_CUSTOMERS_DUPLICATES,
                                        children: [],
                                    },
                                ]
                              : []),
                      ],
                  },
              }
            : {}),
        ...(routePermissions[URL_COURSES].includes(highestRole)
            ? {
                  courses: {
                      label: i18n.views.LayoutStandard.courses(),
                      key: URL_TO_KEY_MAP.URL_COURSES as keyof typeof URL_TO_KEY_MAP,
                      icon: <Icon component={CoursesSvg} />,
                      link: URL_COURSES,
                      children: [
                          {
                              topMenuKey: URL_TO_KEY_MAP.URL_COURSES as keyof typeof URL_TO_KEY_MAP,
                              key: "1 courses",
                              label: i18n.containers.courses.Courses.courses(),
                              link: URL_COURSES_EDIT,
                              children: [
                                  //   {
                                  //       topMenuKey: URL_TO_KEY_MAP.URL_COURSES as keyof typeof URL_TO_KEY_MAP,
                                  //       key: "4 prerequisite-courses",
                                  //       label: i18n.containers.courses.Courses.prerequisiteCourses(),
                                  //       link: URL_COURSES_PREREQUISITE,
                                  //       children: [],
                                  //   },
                              ],
                          },
                          ...(routePermissions[URL_COURSES_TYPES].includes(
                              highestRole,
                          )
                              ? [
                                    {
                                        topMenuKey: URL_TO_KEY_MAP.URL_COURSES as keyof typeof URL_TO_KEY_MAP,
                                        key: "2 course-types",
                                        label: i18n.containers.courses.Courses.courseTypes(),
                                        link: URL_COURSES_TYPES,
                                        children: [],
                                    },
                                ]
                              : []),
                          ...(routePermissions[URL_COURSES_CATEGORIES].includes(
                              highestRole,
                          )
                              ? [
                                    {
                                        topMenuKey: URL_TO_KEY_MAP.URL_COURSES as keyof typeof URL_TO_KEY_MAP,
                                        key: "3 course-categories",
                                        label: i18n.containers.courses.Courses.courseCategories(),
                                        link: URL_COURSES_CATEGORIES,
                                        children: [],
                                    },
                                ]
                              : []),
                          ...(routePermissions[
                              URL_COURSES_PREREQUISITE
                          ].includes(highestRole)
                              ? [
                                    {
                                        topMenuKey: URL_TO_KEY_MAP.URL_COURSES as keyof typeof URL_TO_KEY_MAP,
                                        key: "4 prerequisite-courses",
                                        label: i18n.containers.courses.Courses.prerequisiteCourses(),
                                        link: URL_COURSES_PREREQUISITE,
                                        children: [],
                                    },
                                ]
                              : []),
                      ],
                  },
              }
            : {}),
        ...(routePermissions[URL_LOCATIONS].includes(highestRole)
            ? {
                  locations: {
                      label: i18n.views.LayoutStandard.locations(),
                      key: URL_TO_KEY_MAP.URL_LOCATIONS as keyof typeof URL_TO_KEY_MAP,
                      icon: <Icon component={LocationsSvg} />,
                      link: URL_LOCATIONS,
                      children: [
                          {
                              topMenuKey: URL_TO_KEY_MAP.URL_LOCATIONS as keyof typeof URL_TO_KEY_MAP,
                              key: "1 locations",
                              label: i18n.containers.locations.Locations.locations(),
                              link: URL_LOCATIONS_OVERVIEW,
                              children: [],
                          },
                          {
                              topMenuKey: URL_TO_KEY_MAP.URL_LOCATIONS as keyof typeof URL_TO_KEY_MAP,
                              key: "2 contacts",
                              label: i18n.containers.locations.Locations.contacts(),
                              link: URL_LOCATIONS_CONTACTS,
                              children: [],
                          },
                      ],
                  },
              }
            : {}),
        ...(routePermissions[URL_BOOKINGS].includes(highestRole)
            ? {
                  bookings: {
                      label: i18n.views.LayoutStandard.bookings(),
                      key: URL_TO_KEY_MAP.URL_BOOKINGS as keyof typeof URL_TO_KEY_MAP,
                      icon: <Icon component={LeftMenuBookingIcon} />,
                      link: URL_BOOKINGS,
                      children: [
                          {
                              topMenuKey: URL_TO_KEY_MAP.URL_BOOKINGS as keyof typeof URL_TO_KEY_MAP,
                              key: "1 bookings",
                              label: i18n.containers.bookings.Bookings.bookingsCourseList(),
                              link: URL_BOOKINGS_COURSE_LIST,
                              children: [],
                          },
                          {
                              topMenuKey: URL_TO_KEY_MAP.URL_BOOKINGS as keyof typeof URL_TO_KEY_MAP,
                              key: "2 booking-list",
                              label: i18n.containers.bookings.Bookings.bookingList(),
                              link: URL_BOOKINGS_LIST,
                              children: [],
                          },
                      ],
                  },
              }
            : {}),
        ...(routePermissions[URL_MY_BOOKINGS].includes(highestRole)
            ? {
                  myBookings: {
                      label: i18n.views.LayoutStandard.myBookings(),
                      key: URL_TO_KEY_MAP.URL_MY_BOOKINGS as keyof typeof URL_TO_KEY_MAP,
                      icon: <Icon component={LeftMenuBookingIcon} />,
                      link: URL_MY_BOOKINGS,
                      children: [
                          {
                              topMenuKey: URL_TO_KEY_MAP.URL_MY_BOOKINGS as keyof typeof URL_TO_KEY_MAP,
                              key: "1 myBookings",
                              label: i18n.containers.bookings.MyBookings.bookingsCourseList(),
                              link: URL_MY_BOOKINGS_COURSE_LIST,
                              children: [],
                          },
                          {
                              topMenuKey: URL_TO_KEY_MAP.URL_MY_BOOKINGS as keyof typeof URL_TO_KEY_MAP,
                              key: "2 myBooking-list",
                              label: i18n.containers.bookings.MyBookings.bookingList(),
                              link: URL_MY_BOOKINGS_LIST,
                              children: [],
                          },
                      ],
                  },
              }
            : {}),
        ...(featureWeekPlaner &&
        routePermissions[URL_LESSONS_TODAY].includes(highestRole)
            ? {
                  lessonsToday: {
                      label: i18n.views.LayoutStandard.lessonsToday(),
                      key: URL_TO_KEY_MAP.URL_LESSONS_TODAY as keyof typeof URL_TO_KEY_MAP,
                      icon: <Icon component={WeekPlannerSvg} />,
                      link: URL_LESSONS_TODAY,
                      children: [
                          {
                              topMenuKey: URL_TO_KEY_MAP.URL_LESSONS_TODAY as keyof typeof URL_TO_KEY_MAP,
                              key: "1 calendar",
                              label: i18n.containers.lessonsToday.LessonsToday.weekView(),
                              link: URL_LESSONS_TODAY_CALENDAR_WEEKVIEW,
                              children: [],
                          },
                          {
                              topMenuKey: URL_TO_KEY_MAP.URL_LESSONS_TODAY as keyof typeof URL_TO_KEY_MAP,
                              key: "2 calendar-dayview",
                              label: i18n.containers.lessonsToday.LessonsToday.lessonsToday(),
                              link: URL_LESSONS_TODAY_CALENDAR,
                              children: [],
                          },
                      ],
                  },
              }
            : {}),
        ...(routePermissions[URL_INVOICE].includes(highestRole)
            ? {
                  invoice: {
                      label: i18n.views.LayoutStandard.invoice(),
                      key: URL_TO_KEY_MAP.URL_INVOICE as keyof typeof URL_TO_KEY_MAP,
                      icon: <Icon component={InvoicesSvg} />,
                      link: URL_INVOICE,
                      children: [
                          {
                              topMenuKey: URL_TO_KEY_MAP.URL_INVOICE as keyof typeof URL_TO_KEY_MAP,
                              key: "1 cancel-courses",
                              label: i18n.containers.invoice.Invoice.cancelCourses(),
                              link: URL_INVOICE_LIST_BY_BOOKING_UNSETTLED,
                              children: [],
                          },
                          {
                              topMenuKey: URL_TO_KEY_MAP.URL_INVOICE as keyof typeof URL_TO_KEY_MAP,
                              key: "2 cancel-bookings",
                              label: i18n.containers.invoice.Invoice.cancelBookings(),
                              link: URL_INVOICE_LIST_BY_COURSE,
                              children: [],
                          },
                          {
                              topMenuKey: URL_TO_KEY_MAP.URL_INVOICE as keyof typeof URL_TO_KEY_MAP,
                              key: "3 billed",
                              label: i18n.containers.invoice.Invoice.billed(),
                              link: URL_INVOICE_LIST_BY_BOOKING_SETTLED,
                              children: [],
                          },
                          {
                              topMenuKey: URL_TO_KEY_MAP.URL_INVOICE as keyof typeof URL_TO_KEY_MAP,
                              key: "4 canceled",
                              label: i18n.containers.invoice.Invoice.canceled(),
                              link: URL_INVOICE_LIST_BY_BOOKING_CANCELED,
                              children: [],
                          },
                      ],
                  },
              }
            : {}),
        ...(routePermissions[URL_PAYMENTS].includes(highestRole)
            ? {
                  payments: {
                      label: i18n.views.LayoutStandard.payments(),
                      key: URL_TO_KEY_MAP.URL_PAYMENTS as keyof typeof URL_TO_KEY_MAP,
                      icon: <Icon component={TransactionsSvg} />,
                      link: URL_PAYMENTS,
                      children: [
                          {
                              topMenuKey: URL_TO_KEY_MAP.URL_PAYMENTS as keyof typeof URL_TO_KEY_MAP,
                              key: "1 unbilled",
                              label: i18n.containers.payments.Payments.unBilledBookings(),
                              link: URL_OPEN,
                              children: [],
                          },
                          {
                              topMenuKey: URL_TO_KEY_MAP.URL_PAYMENTS as keyof typeof URL_TO_KEY_MAP,
                              key: "2 billed",
                              label: i18n.containers.payments.Payments.billedBookings(),
                              link: URL_PAID,
                              children: [],
                          },
                      ],
                  },
              }
            : {}),
        ...(routePermissions[URL_STAFF].includes(highestRole)
            ? {
                  staff: {
                      label: i18n.views.LayoutStandard.staff(),
                      key: URL_TO_KEY_MAP.URL_STAFF as keyof typeof URL_TO_KEY_MAP,
                      icon: <Icon component={StaffSvg} />,
                      link: URL_STAFF,
                      children: [
                          {
                              topMenuKey: URL_TO_KEY_MAP.URL_STAFF as keyof typeof URL_TO_KEY_MAP,
                              key: "1 staff-overview",
                              label: i18n.containers.staff.Staff.allStaff(),
                              link: URL_STAFF_OVERVIEW,
                          },
                      ],
                  },
              }
            : {}),
        ...(routePermissions[URL_STAFF_LOGIN].includes(highestRole)
            ? {
                  staffLogin: {
                      label: i18n.views.LayoutStandard.staffLogin(),
                      key: URL_TO_KEY_MAP.URL_STAFF_LOGIN as keyof typeof URL_TO_KEY_MAP,
                      icon: <Icon component={StaffLoginSvg} />,
                      link: URL_STAFF_LOGIN,
                      children: [
                          {
                              topMenuKey: URL_TO_KEY_MAP.URL_STAFF_LOGIN as keyof typeof URL_TO_KEY_MAP,
                              key: "1 start",
                              label: i18n.containers.staffLogin.StaffLogin.subPages.start.title(),
                              link: URL_STAFF_LOGIN_START,
                              children: [],
                          },
                          {
                              topMenuKey: URL_TO_KEY_MAP.URL_STAFF_LOGIN as keyof typeof URL_TO_KEY_MAP,
                              key: "2 files",
                              label: i18n.containers.staffLogin.StaffLogin.subPages.files.title(),
                              link: URL_STAFF_LOGIN_FILES,
                              children: [],
                          },
                      ],
                  },
              }
            : {}),
        ...(routePermissions[URL_TEMPLATES].includes(highestRole)
            ? {
                  templates: {
                      label: i18n.views.LayoutStandard.templates(),
                      key: URL_TO_KEY_MAP.URL_TEMPLATES as keyof typeof URL_TO_KEY_MAP,
                      icon: <Icon component={VorlagenSvg} />,
                      link: URL_TEMPLATES,
                      children: [
                          {
                              topMenuKey: URL_TO_KEY_MAP.URL_TEMPLATES as keyof typeof URL_TO_KEY_MAP,
                              key: "1 email-templates",
                              label: i18n.containers.templates.Templates.emailTemplates(),
                              link: URL_TEMPLATES_EMAIL,
                              children: [],
                          },
                          {
                              topMenuKey: URL_TO_KEY_MAP.URL_TEMPLATES as keyof typeof URL_TO_KEY_MAP,
                              key: "2 invoice-templates",
                              label: i18n.containers.templates.Templates.invoiceTemplatesTitle(),
                              link: URL_TEMPLATES_INVOICES,
                              children: [],
                          },
                      ],
                  },
              }
            : {}),
        ...(routePermissions[URL_NOTIFICATIONS].includes(highestRole)
            ? {
                  notifications: {
                      label: i18n.views.LayoutStandard.notifications(),
                      key: URL_TO_KEY_MAP.URL_NOTIFICATIONS as keyof typeof URL_TO_KEY_MAP,
                      icon: <Icon component={NotificationsSvg} />,
                      link: URL_NOTIFICATIONS,
                      children: [
                          {
                              topMenuKey: URL_TO_KEY_MAP.URL_NOTIFICATIONS as keyof typeof URL_TO_KEY_MAP,
                              key: "1 sent-mails",
                              label: i18n.containers.notifications.Notifications.sentMails(),
                              link: URL_NOTIFICATIONS_SENT_MAILS,
                              children: [],
                          },
                          ...(routePermissions[
                              URL_NOTIFICATIONS_FIXER
                          ].includes(highestRole)
                              ? [
                                    {
                                        topMenuKey: URL_TO_KEY_MAP.URL_NOTIFICATIONS as keyof typeof URL_TO_KEY_MAP,
                                        key: "2 notification-fixer",
                                        label: i18n.containers.notifications.Notifications.notificationFixer.tableTitle(),
                                        link: URL_NOTIFICATIONS_FIXER,
                                        children: [],
                                    },
                                ]
                              : []),
                      ],
                  },
              }
            : {}),
        ...(routePermissions[URL_BADGES].includes(highestRole)
            ? {
                  badges: {
                      label: i18n.views.LayoutStandard.badges(),
                      key: URL_TO_KEY_MAP.URL_BADGES as keyof typeof URL_TO_KEY_MAP,
                      icon: <Icon component={AbzeichenSvg} />,
                      link: URL_BADGES,
                      children: [
                          {
                              topMenuKey: URL_TO_KEY_MAP.URL_BADGES as keyof typeof URL_TO_KEY_MAP,
                              key: "1 badges",
                              label: i18n.containers.badges.BadgesOverview.allBadges(),
                              link: URL_BADGES,
                              children: [],
                          },
                      ],
                  },
              }
            : {}),
        ...(routePermissions[URL_SETTINGS].includes(highestRole)
            ? {
                  settings: {
                      label: i18n.views.LayoutStandard.settings(),
                      key: URL_TO_KEY_MAP.URL_SETTINGS as keyof typeof URL_TO_KEY_MAP,
                      icon: <Icon component={SettingsSvg} />,
                      link: URL_SETTINGS,
                      children: [
                          {
                              topMenuKey: URL_TO_KEY_MAP.URL_SETTINGS as keyof typeof URL_TO_KEY_MAP,
                              key: "1 app",
                              label: i18n.containers.settings.SettingsApp.title(),
                              link: URL_SETTINGS_APP,
                              children: [],
                          },
                          {
                              topMenuKey: URL_TO_KEY_MAP.URL_SETTINGS as keyof typeof URL_TO_KEY_MAP,
                              key: "2 company",
                              label: i18n.containers.settings.SettingsCompany.title(),
                              link: URL_SETTINGS_COMPANY,
                              children: [],
                          },
                          ...(routePermissions[
                              URL_SETTINGS_ENDUSERAPP
                          ].includes(highestRole)
                              ? [
                                    {
                                        topMenuKey: URL_TO_KEY_MAP.URL_SETTINGS as keyof typeof URL_TO_KEY_MAP,
                                        key: "3 enduserapp",
                                        label: "Web Modul",
                                        link: URL_SETTINGS_ENDUSERAPP,
                                        children: [],
                                    },
                                ]
                              : []),
                          ...(routePermissions[URL_SETTINGS_ADMIN].includes(
                              highestRole,
                          )
                              ? [
                                    {
                                        topMenuKey: URL_TO_KEY_MAP.URL_SETTINGS as keyof typeof URL_TO_KEY_MAP,
                                        key: "4 admin",
                                        label: "Superuser",
                                        link: URL_SETTINGS_ADMIN,
                                        children: [],
                                    },
                                ]
                              : []),
                          ...(routePermissions[
                              URL_SETTINGS_IMPORT_BOOKERS
                          ].includes(highestRole)
                              ? [
                                    {
                                        topMenuKey: URL_TO_KEY_MAP.URL_SETTINGS as keyof typeof URL_TO_KEY_MAP,
                                        key: "5 import-bookers",
                                        label: "Bucher importieren",
                                        link: URL_SETTINGS_IMPORT_BOOKERS,
                                        children: [],
                                    },
                                ]
                              : []),
                      ],
                  },
              }
            : {}),
    };

    return mainMenuItems;
};
