import React, {FC, memo, useEffect, useState} from "react";
import {
    TablesEnum,
    useGetCoursesWithPaginationQuery,
} from "../../../generated/graphql";
import {useLocalStorageOptions} from "../../../helpers/columnsLocalStorageHandler";
import CourseListSelection from "../../courses/CourseEdit/CourseListSelection/CourseListSelection";
import {CourseTableData} from "../../courses/CourseEdit/CourseListSelection/baseSetupVariables";

type SelectCourseListProps = {
    onAddCourses: ({courseIds}: {courseIds: Array<string>}) => void;
    selectedCourseIds?: Array<string>;
    currentCourseId: string;
};

const SelectCourseList: FC<SelectCourseListProps> = ({
    onAddCourses,
    selectedCourseIds,
    currentCourseId,
}) => {
    const tablesEnum = TablesEnum.CourseSelectPrerequisiteCourses;
    const [totalResults, setTotalResults] = useState<number>(0);
    const [options, setOptions] = useLocalStorageOptions({
        localStorageName: tablesEnum,
        defaultOptions: {},
        totalResults,
    });

    const {data, loading, error, refetch} = useGetCoursesWithPaginationQuery({
        variables: {
            options,
            excludedCourseIds: [currentCourseId],
        },
    });

    const tableData: CourseTableData = {
        dataSource: data?.coursesWithPagination.items ?? [],
        existMore: data?.coursesWithPagination.existsMore,
        total: data?.coursesWithPagination.total,
        loading,
    };

    useEffect(() => {
        if (data?.coursesWithPagination.total) {
            setTotalResults(data.coursesWithPagination.total);
        }
    }, [data]);

    const handleCourseSelection = (selectedIds: Array<string>) => {
        onAddCourses({courseIds: selectedIds});
    };

    if (error) {
        return <div>{error.message}</div>;
    }

    return (
        <CourseListSelection
            tableData={tableData}
            options={options}
            setOptions={setOptions}
            refetch={refetch}
            tablesEnum={tablesEnum}
            toolBarButtons={[]}
            tableTitle="Voraussetzungskurse wählen"
            defaultColumns={[
                "prefixedCourseNumber",
                "courseType",
                "firstCourseLesson",
            ]}
            handleCourseSelection={handleCourseSelection}
            selectedCourseIds={selectedCourseIds}
            selectionType="checkbox"
            liftRowSelection={(selection) => handleCourseSelection(selection)}
            showHeader={false}
            renderWithoutPage
        />
    );
};

export default memo(SelectCourseList);
