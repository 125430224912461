import {useEffect, useState} from "react";
import {
    useGetUserRolesQuery,
    useIsInstructorQuery,
} from "../../../generated/graphql";
import Roles from "../../../../shared/models/roles";
import {getHighestRole} from "../utils/helpers";

export const useRoles = (pollInterval: number) => {
    const [highestRole, setHighestRole] = useState<Roles>(Roles.Instructor);
    const [rolesOfCurrent, setRolesOfCurrent] = useState<Array<Roles>>([
        Roles.Instructor,
    ]);

    const {data: rolesData} = useGetUserRolesQuery({
        fetchPolicy: "cache-and-network",
        pollInterval,
    });

    const {data: isInstructorData} = useIsInstructorQuery();
    const isInstructor = isInstructorData?.isInstructor;

    useEffect(() => {
        if (rolesData?.getUserRoles?.rolesOfCurrent) {
            setRolesOfCurrent(rolesData.getUserRoles.rolesOfCurrent);
        }
    }, [rolesData]);

    useEffect(() => {
        setHighestRole(getHighestRole(rolesOfCurrent));
    }, [rolesOfCurrent]);

    return {
        highestRole,
        rolesOfCurrent,
        isInstructor,
    };
};
