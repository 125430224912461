import React, {useState} from "react";
import {blue, green} from "@ant-design/colors";
import {Badge, message, Popconfirm, Space, Tooltip} from "antd";
import {CloseCircleFilled} from "@ant-design/icons";
import {
    EnabledCourse,
    useRemoveBookableCourseMutation,
} from "../../../../generated/graphql";
import {Color, getTextColor} from "../../../../helpers/getTextColor";
import css from "../../TableColumnRenderers.less";
import EditRestrictiveBookingModal from "../../../../containers/restrictiveBookings/CreateEdit/EditRestrictiveBookingModal";
import Spin from "../../../Spin/Spin";

type RenderEnabledCourseProps = {
    key: string;
    enabledCourse?: EnabledCourse;
    prerquisiteCourseId: string;
    refetch?: () => void;
    style?: React.CSSProperties;
};

export const RenderEnabledCourse = ({
    key,
    enabledCourse,
    prerquisiteCourseId,
    refetch,
    style,
}: RenderEnabledCourseProps) => {
    const [open, setOpen] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [removeBookableCourse] = useRemoveBookableCourseMutation();

    const enabledRestriction = enabledCourse?.enabledRestriction;

    if (!enabledCourse || !enabledRestriction) {
        return null;
    }

    const {color} = enabledCourse.courseType;
    const theColor: Color = {name: color, value: color ? color : blue[3]};
    const {
        includeWaitingList,
        includeBookingList,
        // membersOnly,
    } = enabledRestriction;
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const actualStyle: React.CSSProperties = {
        backgroundColor: color ? color : blue[3],
        borderRadius: "50px",
        "--bg-color": color ? color : blue[3],
        "--text-color": getTextColor(theColor),
        display: "flex",
        alignItems: "center",
        ...style,
    } as React.CSSProperties;

    // FUNCTIONALITY
    const handleRemoveCourse = async ({courseId}: {courseId: string}) => {
        setDeleting(true);
        try {
            await removeBookableCourse({
                variables: {
                    bookableCourseId: courseId,
                    prerquisiteCourseId,
                },
            });
        } catch (error) {
            message.error("Fehler beim Entfernen des Kurses");
            console.error(error);
        } finally {
            refetch?.();
            setDeleting(false);
            message.success("Kurs erfolgreich entfernt");
        }
    };

    return (
        <div key={key}>
            {open && (
                <EditRestrictiveBookingModal
                    courseId={enabledCourse.id}
                    tooltipText="Kurs hinzufügen"
                    refetch={refetch}
                    initialOpen={open}
                    onSetOpenState={setOpen}
                />
            )}
            <Space style={actualStyle} className={css.coloredBadge}>
                <button
                    type="button"
                    onClick={() => setOpen(true)}
                    style={{
                        fontWeight: 600,
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                        padding: 0,
                    }}
                >
                    {enabledCourse.prefixedCourseNumber}
                </button>
                <Tooltip title="Kurs entfernen">
                    {deleting ? (
                        <Spin size="small" />
                    ) : (
                        <Popconfirm
                            title={`${enabledCourse.prefixedCourseNumber} entfernen?`}
                            onConfirm={async () =>
                                handleRemoveCourse({courseId: enabledCourse.id})
                            }
                        >
                            <CloseCircleFilled
                                style={{
                                    fontSize: "1.3em",
                                    paddingTop: 2,
                                    marginRight: -10.5,
                                }}
                            />
                        </Popconfirm>
                    )}
                </Tooltip>
            </Space>
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    paddingLeft: 12,
                    marginTop: -3,
                }}
            >
                {renderDot({letter: "B", bool: includeBookingList})}
                {renderDot({letter: "W", bool: includeWaitingList})}
            </div>
        </div>
    );
};

// HELPER FUNCTIONS
export const renderDot = ({letter, bool}: {letter: string; bool: boolean}) => {
    const color = bool ? green[3] : "#b8b8b8";
    const textWaitlist = bool
        ? "Warteliste wird berücksichtigt"
        : "Warteliste wird nicht berücksichtigt";
    const textBooking = bool
        ? "Buchungen werden berücksichtigt"
        : "Buchungen werden nicht berücksichtigt";

    return (
        <Tooltip title={letter === "W" ? textWaitlist : textBooking}>
            <Badge
                count={letter}
                showZero
                color={color}
                size="small"
                style={{
                    fontSize: "0.8em",
                    marginLeft: -1,
                    color: "black",
                }}
            />
        </Tooltip>
    );
};
