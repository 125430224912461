import React, {FC, memo} from "react";
import {FormikProps} from "formik";
import {Spin, Tag} from "antd";
import {
    NewBookingRestrictionInput,
    useGetCoursesByIdsMinimalQuery,
} from "../../../generated/graphql";
import css from "./EditRestrictiveBookings.less";

type SelectedCoursesPreviewProps = {
    courseIds?: Array<string>;
    formikProps?: FormikProps<NewBookingRestrictionInput>;
};

const SelectedCoursesPreview: FC<SelectedCoursesPreviewProps> = ({
    courseIds,
    formikProps,
}) => {
    // const courseIds = formikProps.values.prerequisiteCourses ?? [];
    const {data, loading} = useGetCoursesByIdsMinimalQuery({
        skip: !courseIds || courseIds.length === 0,
        variables: {
            ids: courseIds ?? [],
        },
    });
    const courses = data?.coursesByIds ?? [];
    const handleRemove = (courseId: string) => {
        formikProps?.setFieldValue(
            "prerequisiteCourses",
            formikProps.values.prerequisiteCourses?.filter(
                (id) => id !== courseId,
            ),
        );
    };

    if (loading) {
        return <Spin />;
    }

    if (courses.length === 0) {
        return (
            <div className={css.selectedCoursesPreview}>
                {"Keine Voraussetzungskurse"}
            </div>
        );
    }

    return (
        <div className={css.selectedCoursesPreview}>
            {courses?.map((course) => (
                <Tag
                    key={course.id}
                    color={course.courseType.color}
                    closable={formikProps !== undefined}
                    onClose={() => handleRemove(course.id)}
                    style={{margin: "2px", borderRadius: "20px"}}
                >
                    {course.prefixedCourseNumber}
                </Tag>
            ))}
        </div>
    );
};

export default memo(SelectedCoursesPreview);
