import {PlusCircleOutlined} from "@ant-design/icons";
import {Button, Modal} from "antd";
import React, {FC, memo, useState} from "react";
import {FormikProps} from "formik";
import {isEqual} from "lodash";
import css from "./EditRestrictiveBookings.less";
import i18n from "../../../services/i18n";
import {PosibleFormikProps} from "./EditRestrictiveBooking";
import SelectCourseList from "./SelectCourseList";
import SelectedCoursesPreview from "./SelectedCoursesPreview";

type PreRequisiteCoursesInputProps = {
    formikProps: FormikProps<PosibleFormikProps>;
    style?: React.CSSProperties;
};

const PreRequisiteCoursesInput: FC<PreRequisiteCoursesInputProps> = ({
    formikProps,
    style,
}) => {
    const [selectCourseModalOpen, setSelectCourseModalOpen] = useState(false);
    const handleAddCourses = ({courseIds}: {courseIds: Array<string>}) => {
        if (!isEqual(formikProps.values.prerequisiteCourses, courseIds)) {
            formikProps.setFieldValue("prerequisiteCourses", courseIds);
        }
    };

    const selectCourseModal = () => {
        return (
            <Modal
                title="Kurs hinzufügen"
                width="100vw"
                style={{
                    padding: 0,
                    // top: margin,
                    // maxHeight: `calc(100vh - ${marginL})`,
                    // position: "unset",
                    position: "static",
                }}
                open={selectCourseModalOpen}
                onCancel={() => setSelectCourseModalOpen(false)}
                okText="Auswahl schließen"
                onOk={() => setSelectCourseModalOpen(false)}
                cancelButtonProps={{style: {display: "none"}}}
                destroyOnClose
            >
                <SelectCourseList
                    onAddCourses={handleAddCourses}
                    selectedCourseIds={
                        formikProps.values.prerequisiteCourses ?? undefined
                    }
                    currentCourseId={formikProps.values.courseId}
                />
            </Modal>
        );
    };

    return (
        <div style={{...style}}>
            {selectCourseModal()}
            <SelectedCoursesPreview
                courseIds={formikProps.values.prerequisiteCourses ?? undefined}
                formikProps={formikProps}
            />
            <Button
                block
                icon={<PlusCircleOutlined />}
                style={{gridColumn: "1 / span 2"}}
                className={css.subButton}
                onClick={() => setSelectCourseModalOpen(true)}
            >
                {i18n.containers.restrictiveBooking.RestrictiveBookingCreateEdit.addCourses()}
            </Button>
        </div>
    );
};

export default memo(PreRequisiteCoursesInput);
