import React, {FC, useEffect, useState} from "react";
import {
    CheckCircleFilled,
    CloseCircleFilled,
    ExclamationCircleOutlined,
    FilePdfOutlined,
    InfoCircleOutlined,
    ClockCircleOutlined,
    LoadingOutlined,
    QuestionCircleOutlined,
    CheckCircleOutlined,
    EyeOutlined,
    WarningOutlined,
    FileOutlined,
    FileImageOutlined,
    CloseOutlined,
    PlusOutlined,
    CloseCircleOutlined,
} from "@ant-design/icons";
import {
    Badge,
    Card,
    Checkbox,
    Descriptions,
    Modal,
    Space,
    Spin,
    Tag,
    Tooltip,
    Typography,
    message,
} from "antd";
import classnames from "classnames";
import moment, {MomentFormatSpecification} from "moment";
import {blue, cyan, green, grey, orange, red, yellow} from "@ant-design/colors";
import Maybe from "graphql/tsutils/Maybe";
import {Link} from "react-router-dom";
import {orderBy} from "lodash";
import {Email} from "node-mailjet";
import {CheckboxChangeEvent} from "antd/lib/checkbox";
import Button from "../Button/Button";
import css from "./TableColumnRenderers.less";
import i18n from "../../services/i18n";
import {
    PaymentInterval,
    BookerPaymentMethodInput,
    PaymentType,
    Gender,
    Instructor,
    CourseType,
    Location,
    SalutationType,
    Venue,
    GeneralLocationContact,
    Course,
    useGetCourseLessonsByCourseIdLazyQuery,
    GetCourseLessonsByCourseIdQuery,
    TemplateType,
    CourseLesson,
    useGetBookingsByCourseMinimalQuery,
    useGetEmailNotificationsByBookingIdQuery,
    Booking,
    UserType,
    useGetUserInfoByIdQuery,
    useGetCourseLessonsByCourseIdQuery,
    BookingsKo9,
    useGetBookerByEmailQuery,
    GetBookersOverviewDocument,
    useAddFaUserByBookerIdMutation,
    Booker,
    PaymentTransferType,
    EmailNotification,
    useGetSentEmailStatusFromMailjetQuery,
    MessageHistory,
    GetEmailNotificationsQuery,
    useChangeAttendeeMemberStatusMutation,
    Attendee,
    AttendeeOverviewGetAttendeesMinimalDocument,
    MailDataType,
    EnabledCourse,
    BookingRestriction,
    ExpireAction,
    WithEnabledCourse,
} from "../../generated/graphql";
import {
    CurrencyShort,
    renderAmount as renderAmountHelper,
} from "../../helpers/renderAmount";
import {renderPercent as renderPercentHelper} from "../../helpers/renderPercent";
import {
    paymentMethodTypeLabels,
    paymentTransferTypeLabels,
} from "../PaymentMethodBadge/PaymentMethodBadge";
import {getPaymentMethodType} from "../../helpers/getPaymentMethodType";
import {
    colorBackgroundBase,
    colorBlueDark,
    colorDarkGrey,
    colorGrey,
    colorLightGrey,
    colorSuperLightGrey,
    primaryColor75,
} from "../../styles/colors";
import {renderAsMoney} from "../../helpers/renderAsMoney";
import {isDefined, ISODateString} from "../../helpers/typeScriptHelpers";
import {naturalLanguageAge} from "../../helpers/natualLanguageAge";
import {BookingType} from "../../../server/booking/booking.entity";
import {TableType as CourseTableType} from "../../containers/courses/CourseEdit/CourseListColumns";
import {Color, getTextColor} from "../../helpers/getTextColor";
import LoadingContent from "../LoadingContent/LoadingContent";
import {LocationState, QueryItem} from "../../types";
import formatDate from "../../helpers/formatDate";
import {
    URL_BOOKERS_DETAILS_ATTENDEES_ID,
    URL_BOOKERS_DETAILS_ID,
    URL_BOOKINGS_SETTLEMENT_DETAILS,
    URL_BOOKING_EDIT_ID,
    URL_INSTRUCTOR_BOOKING_EDIT_ID,
    URL_INSTRUCTOR_INVOICE_SETTLEMENT_DETAILS,
    URL_INVOICE_SETTLEMENT_DETAILS,
    URL_MY_BOOKINGS_EDIT_ID,
} from "../../models/url";
import BookingCreate from "../../containers/bookings/CreateEditBookings/BookingCreate";
import {margin, panelWidth} from "../../styles/layout";
import {TableType} from "../../containers/bookings/BookingList/BookingListColumns";
import {useCurrency} from "../../helpers/currencyHelper";
import Roles from "../../../shared/models/roles";
import nearlyEqual from "../../helpers/compareNumber";
import {AttachementHistory} from "../../../server/types";
import SettlementDetails from "../../containers/invoice/SettlementDetails";
import MailDataTypeBadge from "../../containers/templates/MailTemplateEditor/MailDataTypeBadge";
import {
    MailjetStatusOptions,
    mailjetStatusOptions,
} from "../../containers/notifications/emaiNotificationStatusText";
import CellEnabledCourses from "./BiggerComponents/CellEnabledCourses/CellEnabledCourses";

moment.locale(navigator.language);

export type ReqiredPaymentData = Pick<
    BookerPaymentMethodInput,
    | "id"
    | "cash"
    | "creditCardAccountholder"
    | "creditcardNumber"
    | "expirationDate"
    | "validFrom"
    | "cvc"
    | "paypalAccountholder"
    | "email"
    | "iban"
    | "bank"
    | "bic"
    | "sepaAccountholder"
>;

export const renderTitle = (title: string) => {
    return <div className={classnames(css.header)}>{title}</div>;
};

export const renderTextStandard = (
    text: string | undefined | number | null,
    bold?: boolean,
) => {
    const classes = bold
        ? classnames(css.text__standard, css.text__bold)
        : classnames(css.text__standard);

    return <div className={classes}>{text ?? ""}</div>;
};

export const renderUnknown = (text: unknown) => {
    if (typeof text === "string" || typeof text === "number") {
        return renderTextStandard(text);
    }

    return <div>{"!!Attention!!"}</div>;
};

type TextColoredInterface = {
    text: string | number;
    color: string;
    centered?: boolean;
};

export const renderTextColored = ({
    text,
    color,
    centered,
}: TextColoredInterface) => {
    return (
        <div
            style={{backgroundColor: color || "transparent"}}
            // className={classnames(centered ? (css.text__colored css.text__centered) : (css.text__colored))}
            className={
                centered
                    ? classnames(css.text__colored, css.text__centered)
                    : css.text__colored
            }
        >
            <span>{text}</span>
        </div>
    );
};

export const renderBill = (text: string, cb: () => void) => {
    return (
        <div
            tabIndex={0}
            role="button"
            onKeyDown={cb}
            onClick={cb}
            className={classnames(css.bill)}
        >
            <FilePdfOutlined style={{paddingRight: "8px"}} />
            <span>{text}</span>
        </div>
    );
};

export const renderFirstCourseLesson = ({
    courseLessons,
    startEnd,
}: {
    courseLessons?: Array<CourseLesson>;
    startEnd?: {startDateTime: string | Date; endDateTime: string | Date};
}) => {
    const firstLesson: {
        startDateTime?: string | Date;
        endDateTime?: string | Date;
    } = {
        startDateTime: startEnd?.startDateTime,
        endDateTime: startEnd?.endDateTime,
    };

    if (courseLessons) {
        const sortedLessons: Array<CourseLesson> = orderBy(
            courseLessons,
            ["startDateTime"],
            ["asc"],
        );

        firstLesson.startDateTime = sortedLessons[0].startDateTime;
        firstLesson.endDateTime = sortedLessons[0].endDateTime;
    }

    if (startEnd) {
        firstLesson.startDateTime = startEnd.startDateTime;
        firstLesson.endDateTime = startEnd.endDateTime;
    }

    moment.locale(navigator.language);

    return (
        <>
            {firstLesson.startDateTime && firstLesson.endDateTime && (
                <>
                    <div
                        style={{
                            whiteSpace: "nowrap",
                            fontWeight: 450,
                            color: grey[6],
                            lineHeight: "1.3em",
                        }}
                    >
                        {`${moment(firstLesson.startDateTime).format(
                            "dd",
                        )} ${moment(firstLesson.startDateTime).format("LL")}`}
                    </div>
                    <div
                        style={{
                            color: grey[5],
                            fontWeight: 500,
                            borderTop: `1px solid ${blue[1]}`,
                            background: `linear-gradient(170deg, ${blue[1]} 0%, white 70%, white 100%)`,
                            paddingLeft: 2,
                            whiteSpace: "nowrap",
                            paddingTop: 0,
                            lineHeight: "1.4em",
                        }}
                    >
                        {moment(firstLesson.startDateTime).format("LT") +
                            " - " +
                            moment(firstLesson.endDateTime).format("LT")}
                    </div>
                </>
            )}
        </>
    );
};

export const renderLastCourseLesson = ({
    courseLessons,
}: {
    courseLessons?: Array<Partial<CourseLesson>>;
}) => {
    if (!courseLessons) {
        return <div>{"-"}</div>;
    }

    const sortedLessons: Array<Partial<CourseLesson>> = orderBy(
        courseLessons,
        ["startDateTime"],
        ["asc"],
    );

    const lastLessonStartDateTime =
        sortedLessons[sortedLessons.length - 1].startDateTime;
    const lastLessonEndDateTime =
        sortedLessons[sortedLessons.length - 1].endDateTime;

    moment.locale(navigator.language);

    return (
        <>
            {lastLessonStartDateTime && lastLessonEndDateTime && (
                <>
                    <div
                        style={{
                            whiteSpace: "nowrap",
                            fontWeight: 450,
                            color: grey[6],
                            lineHeight: "1.3em",
                        }}
                    >
                        {`${moment(lastLessonStartDateTime).format(
                            "dd",
                        )} ${moment(lastLessonStartDateTime).format("LL")}`}
                    </div>
                    <div
                        style={{
                            color: grey[5],
                            fontWeight: 500,
                            borderTop: `1px solid ${blue[1]}`,
                            background: `linear-gradient(170deg, ${blue[1]} 0%, white 70%, white 100%)`,
                            paddingLeft: 2,
                            whiteSpace: "nowrap",
                            paddingTop: 0,
                            lineHeight: "1.4em",
                        }}
                    >
                        {moment(lastLessonStartDateTime).format("LT") +
                            " - " +
                            moment(lastLessonEndDateTime).format("LT")}
                    </div>
                </>
            )}
        </>
    );
};

// export const renderLastCourseLesson = ({
//     courseLessons,
//     startEnd,
// }: {
//     courseLessons?: Array<CourseLesson>;
//     startEnd?: {startDateTime: string | Date; endDateTime: string | Date};
// }) => {
//     const lastLesson: {
//         startDateTime?: string | Date;
//         endDateTime?: string | Date;
//     } = {
//         startDateTime: startEnd?.startDateTime,
//         endDateTime: startEnd?.endDateTime,
//     };

//     if (courseLessons) {
//         const sortedLessons: Array<CourseLesson> = orderBy(
//             courseLessons,
//             ["startDateTime"],
//             ["asc"],
//         );

//         lastLesson.startDateTime =
//             sortedLessons[sortedLessons.length - 1].startDateTime;
//         lastLesson.endDateTime =
//             sortedLessons[sortedLessons.length - 1].endDateTime;
//     }

//     if (startEnd) {
//         lastLesson.startDateTime = startEnd.startDateTime;
//         lastLesson.endDateTime = startEnd.endDateTime;
//     }

//     moment.locale(navigator.language);

//     return (
//         <>
//             {lastLesson.startDateTime && lastLesson.endDateTime && (
//                 <>
//                     <div style={{whiteSpace: "nowrap"}}>
//                         {`${moment(lastLesson.startDateTime).format(
//                             "dd",
//                         )} ${moment(lastLesson.startDateTime).format("LL")}`}
//                     </div>
//                     <div
//                         style={{
//                             color: colorLightGrey,
//                             whiteSpace: "nowrap",
//                         }}
//                     >
//                         {moment(lastLesson.startDateTime).format("LT") +
//                             " - " +
//                             moment(lastLesson.endDateTime).format("LT")}
//                     </div>
//                 </>
//             )}
//         </>
//     );
// };

export const RenderLastCourseLessonForCourseList = ({
    courseId,
}: {
    courseId: string;
}) => {
    const [
        getCourseLessons,
        {data, loading, error},
    ] = useGetCourseLessonsByCourseIdLazyQuery();

    useEffect(() => {
        getCourseLessons({
            variables: {courseId},
        });
    }, [courseId, getCourseLessons]);

    if (loading) {
        return <LoadingOutlined />;
    }

    if (error) {
        return <div>{error.message}</div>;
    }

    if (data) {
        const courseLessons = data.courseLessonsByCourseId;

        const lastLesson: {
            startDateTime?: string | Date;
            endDateTime?: string | Date;
        } = {
            startDateTime: courseLessons[0].startDateTime,
            endDateTime: courseLessons[0].endDateTime,
        };

        const sortedLessons: GetCourseLessonsByCourseIdQuery["courseLessonsByCourseId"] = orderBy(
            courseLessons,
            ["startDateTime"],
            ["asc"],
        );

        lastLesson.startDateTime =
            sortedLessons[sortedLessons.length - 1].startDateTime;
        lastLesson.endDateTime =
            sortedLessons[sortedLessons.length - 1].endDateTime;

        moment.locale(navigator.language);

        return (
            <>
                {lastLesson.startDateTime && lastLesson.endDateTime && (
                    <>
                        <div style={{whiteSpace: "nowrap"}}>
                            {`${moment(lastLesson.endDateTime).format(
                                "dd",
                            )} ${moment(lastLesson.endDateTime).format("LL")}`}
                        </div>
                        <div
                            style={{
                                color: colorLightGrey,
                                whiteSpace: "nowrap",
                            }}
                        >
                            {moment(lastLesson.endDateTime).format("LT") +
                                " - " +
                                moment(lastLesson.endDateTime).format("LT")}
                        </div>
                    </>
                )}
            </>
        );
    }

    return <div>{"-"}</div>;
};

export const renderFirstCourseLessonOld = (
    startDateTime: string | Date,
    endDateTime: string | Date,
) => {
    return (
        <>
            <div>
                {startDateTime &&
                    formatDate(new Date(startDateTime), "iii dd. MMM. yyyy")}
            </div>
            <div
                style={{
                    color: colorLightGrey,
                }}
            >
                {formatDate(new Date(startDateTime), "HH:mm") +
                    " - " +
                    formatDate(new Date(endDateTime), "HH:mm")}
            </div>
        </>
    );
};

export const renderAmount = (amount: number, style?: React.CSSProperties) => {
    return (
        <div
            className={classnames([css.text__standard, css.text__bold])}
            style={style}
        >
            <RenderAmount amount={amount} />
        </div>
    );
};

export const RenderAmount = ({
    amount,
    style,
    className,
}: {
    amount?: number;
    style?: React.CSSProperties;
    className?: string;
}) => {
    const currency = useCurrency();

    if (amount === undefined || amount === null) {
        return <div>{"-"}</div>;
    }

    return (
        <div
            // className={classnames([css.text__standard, css.text__bold])}
            className={classnames([css.text__standard], className)}
            style={style}
        >
            {renderAmountHelper({
                amount,
                currency: currency.short as CurrencyShort,
                short: true,
            })}
        </div>
    );
};

export const renderPercent = (amount: number) => {
    return renderPercentHelper(amount);
};

export const renderDueDate = (date: string, color: string) => {
    return (
        <div className={classnames(css.date__due)}>
            <Badge color={color || "#d9d9d9"} />
            <span>{date && moment(date).format("DD.MM.YYYY")}</span>
        </div>
    );
};

export const renderDueDateColored = (date: Date, customText?: string) => {
    const today = moment();
    const dueDate = moment(date);
    const diffInDays = dueDate.diff(today, "days");

    const color = diffInDays > 0 ? (diffInDays > 7 ? "" : orange[2]) : red[2];

    return (
        <>
            {renderTextColored({
                text: customText ?? moment(date).format("L"),
                color,
                centered: true,
            })}
        </>
    );
};

export const renderPaymentDate = (date?: string | null) => {
    if (isDefined(date)) {
        return <span>{moment(date).format("L")}</span>;
    }

    return <span>{"offen"}</span>;
};

export const renderPaymentType = (type: PaymentType | null | undefined) => {
    let paymentType = "-";

    switch (type) {
        case PaymentType.PrePaid:
            paymentType = i18n.containers.payments.DonePaymentsList.prePaid();
            break;
        case PaymentType.Bill:
            paymentType = i18n.containers.payments.DonePaymentsList.bill();
            break;
        case PaymentType.Monthly:
            paymentType = i18n.containers.payments.DonePaymentsList.monthly();
            break;
        case PaymentType.OnTheSpot:
            paymentType = i18n.containers.payments.DonePaymentsList.onTheSpot();
            break;
        default:
            paymentType = "-";
    }

    return renderTextStandard(paymentType);
};

export const renderCustomerNumber = (
    customerNumberPrefix: string,
    customerNumber: number,
    className?: string,
) => (
    <div
        className={className}
    >{`${customerNumberPrefix} ${customerNumber}`}</div>
);

export const RenderReceiverName = ({
    receiverName,
    bookerId,
    email,
}: {
    receiverName?: string;
    bookerId?: string;
    email?: string;
}) => {
    const {data: bookerFromMailData, loading} = useGetBookerByEmailQuery({
        // skip: !email || Boolean(bookerId),
        skip: !email,
        variables: {email: email ?? ""},
    });

    const bookerIdFromMail = bookerFromMailData?.getBookerByEmail?.id;

    if (!receiverName) {
        return <span className={css.secondary}>{" - "}</span>;
    }

    if (bookerIdFromMail) {
        return (
            <Link
                to={{
                    pathname: URL_BOOKERS_DETAILS_ID.replace(
                        ":id",
                        bookerIdFromMail,
                    ),
                }}
            >
                <Button type="link" size="small">
                    <div style={{fontSize: "0.8em"}}>{`${receiverName}`}</div>
                </Button>
            </Link>
        );
    }

    return <div>{`${receiverName}`}</div>;

    // if (Boolean(bookerId) === true) {
    //     return (
    //         <Link
    //             to={{
    //                 pathname: URL_BOOKERS_DETAILS_ID.replace(":id", bookerId),
    //             }}
    //         >
    //             <Button type="link" size="small">
    //                 <div style={{fontSize: "0.8em"}}>{`${receiverName}`}</div>
    //             </Button>
    //         </Link>
    //     );
    // } else if (email) {
    //     if (bookerIdFromMail) {
    //         return (
    //             <Link
    //                 to={{
    //                     pathname: URL_BOOKERS_DETAILS_ID.replace(
    //                         ":id",
    //                         bookerIdFromMail,
    //                     ),
    //                 }}
    //             >
    //                 <Button type="link" size="small">
    //                     <div
    //                         style={{fontSize: "0.8em"}}
    //                     >{`${receiverName}`}</div>
    //                 </Button>
    //             </Link>
    //         );
    //     }

    //     return <div>{`${receiverName}`}</div>;
    // }

    return <div>{`${receiverName}`}</div>;
};

export const renderFullName = (
    firstname?: string,
    lastname?: string,
    className?: string,
    nonDisclosureNotice?: string,
) => {
    if (!firstname && !lastname) {
        return <span className={css.secondary}>{" - "}</span>;
    }

    if (nonDisclosureNotice) {
        return (
            <div className={className} style={{color: red[5], fontWeight: 600}}>
                <Tooltip
                    title={
                        <span>
                            <div>
                                {`${i18n.views.TableColumnTitles.nonDisclosureNotice()}:`}
                            </div>
                            {nonDisclosureNotice}
                        </span>
                    }
                >
                    <ExclamationCircleOutlined style={{marginRight: 5}} />
                    <span>{`${firstname} ${lastname}`}</span>
                </Tooltip>
            </div>
        );
    }

    return <div className={className}>{`${firstname} ${lastname}`}</div>;
};

export const renderAttendeeFullName = (
    bookerId?: string,
    firstname?: string,
    lastname?: string,
    className?: string,
    nonDisclosureNotice?: string,
) => {
    if (!firstname && !lastname) {
        return <span className={css.secondary}>{" - "}</span>;
    }

    if (nonDisclosureNotice) {
        return (
            <div className={className} style={{color: red[5], fontWeight: 600}}>
                <Tooltip
                    title={
                        <span>
                            <div>
                                {`${i18n.views.TableColumnTitles.nonDisclosureNotice()}:`}
                            </div>
                            {nonDisclosureNotice}
                        </span>
                    }
                >
                    <Link
                        style={{color: red[5]}}
                        to={{
                            pathname: URL_BOOKERS_DETAILS_ATTENDEES_ID.replace(
                                ":id",
                                bookerId ?? "",
                            ),
                        }}
                    >
                        <div style={{whiteSpace: "nowrap"}}>
                            <ExclamationCircleOutlined
                                style={{marginRight: 5}}
                            />
                            <Button
                                type="link"
                                size="small"
                                style={{color: red[5]}}
                            >
                                <span
                                    style={{fontSize: "0.8em"}}
                                    className={className}
                                >{`${firstname} ${lastname}!!!`}</span>
                            </Button>
                        </div>
                    </Link>
                </Tooltip>
            </div>
        );
    }

    return (
        <Link
            to={{
                pathname: URL_BOOKERS_DETAILS_ATTENDEES_ID.replace(
                    ":id",
                    bookerId ?? "",
                ),
            }}
        >
            <Button type="link" size="small">
                {/* <span>{`${firstname} ${lastname}!!!`}</span> */}
                <div
                    className={className}
                    style={{fontSize: "0.8em"}}
                >{`${firstname} ${lastname}`}</div>
            </Button>
        </Link>
    );
};

export const renderBookerFullName = (
    bookerId?: string,
    firstname?: string,
    lastname?: string,
    className?: string,
    nonDisclosureNotice?: string,
) => {
    if (!firstname && !lastname) {
        return <span className={css.secondary}>{" - "}</span>;
    }

    const detailsUrl = URL_BOOKERS_DETAILS_ID.replace(":id", bookerId ?? "");

    if (nonDisclosureNotice) {
        return (
            <div className={className} style={{color: red[5], fontWeight: 600}}>
                <Tooltip
                    title={
                        <span>
                            <div>
                                {`${i18n.views.TableColumnTitles.nonDisclosureNotice()}:`}
                            </div>
                            {nonDisclosureNotice}
                        </span>
                    }
                >
                    <Link
                        style={{color: red[5]}}
                        to={{
                            pathname: detailsUrl,
                        }}
                    >
                        <div style={{whiteSpace: "nowrap"}}>
                            <ExclamationCircleOutlined
                                style={{marginRight: 5}}
                            />
                            <Button
                                type="link"
                                size="small"
                                style={{color: red[5]}}
                            >
                                <span
                                    style={{fontSize: "0.8em"}}
                                    className={className}
                                >{`${firstname} ${lastname}!!!`}</span>
                            </Button>
                        </div>
                    </Link>
                </Tooltip>
            </div>
        );
    }

    return (
        <Link
            to={{
                pathname: detailsUrl,
            }}
        >
            <Button type="link" size="small">
                {/* <span>{`${firstname} ${lastname}!!!`}</span> */}
                <div
                    className={className}
                    style={{fontSize: "0.8em"}}
                >{`${firstname} ${lastname}`}</div>
            </Button>
        </Link>
    );
};

export const renderLastName = (
    lastname?: string,
    className?: string,
    nonDisclosureNotice?: string,
) => {
    if (!lastname) {
        return <span className={css.secondary}>{" - "}</span>;
    }

    if (nonDisclosureNotice) {
        return (
            <div className={className} style={{color: red[5], fontWeight: 600}}>
                <Tooltip
                    title={
                        <span>
                            <div>
                                {`${i18n.views.TableColumnTitles.nonDisclosureNotice()}:`}
                            </div>
                            {nonDisclosureNotice}
                        </span>
                    }
                >
                    <ExclamationCircleOutlined style={{marginRight: 5}} />
                    <span>{`${lastname}`}</span>
                </Tooltip>
            </div>
        );
    }

    return <div className={className}>{`${lastname}`}</div>;
};

export const renderAttendeesOfBooker = (
    attendees: Array<{firstname: string; lastname: string; id: string}> | null,
) => {
    if (attendees !== null && attendees.length > 0) {
        return attendees.map((attendee) => {
            return (
                <div
                    key={attendee.id}
                >{`${attendee.firstname} ${attendee.lastname}`}</div>
            );
        });
    }

    return (
        <div className={css.secondary}>
            {i18n.containers.customers.BookersOverview.noAttendees()}
        </div>
    );
};

// As we don't need the houseNumber as an extra field in the address, it is ignored here
export const renderAddress = (
    street: string,
    houseNumber: string,
    postCode: string,
    city: string,
) => {
    const renderStreetAndHouseNumber = (
        street: string,
        houseNumber: string,
    ) => {
        const hourseNumberInStreet = /([A-Za-z])+.+\d/.test(street);

        if (hourseNumberInStreet) {
            return street;
        }

        return `${street} ${houseNumber}`;
    };

    return (
        <div>
            <div>{renderStreetAndHouseNumber(street, houseNumber)}</div>
            <div>
                {postCode} {city}
            </div>
        </div>
    );
};

export const renderPaymentMethods = (
    availablePaymentMethods: Array<ReqiredPaymentData> | null,
) => {
    if (availablePaymentMethods !== null) {
        return availablePaymentMethods.map((method) => {
            return (
                <div key={method.id}>
                    {paymentMethodTypeLabels[getPaymentMethodType(method)]}
                </div>
            );
        });
    }

    return "-";
};

export const renderPaymentMethodType = (
    record: ReqiredPaymentData,
    size?: "small" | "default" | "large",
) => {
    const fontSize =
        size === "small" ? "1em" : size === "large" ? "1.7em" : "1.3em";

    return (
        <span style={{fontSize, fontWeight: 600}}>
            {paymentMethodTypeLabels[getPaymentMethodType(record)]}
        </span>
    );
};

export const renderPaymentMethod = (
    paymentMethod: ReqiredPaymentData | null,
) => {
    if (paymentMethod !== null) {
        return paymentMethodTypeLabels[getPaymentMethodType(paymentMethod)];
    }

    return "-";
};

export const renderPaymentTransferType = (
    transferType?: PaymentTransferType | null,
) => {
    if (transferType !== null && transferType !== undefined) {
        return paymentTransferTypeLabels[transferType];
    }

    return "-";
};

export const renderBooleanAsCheckmark = (
    value: boolean,
    size?: "small" | "default" | "large",
) => {
    const fontSize =
        size === "small" ? "1.3em" : size === "large" ? "2em" : "1.7em";

    if (value === true) {
        return <CheckCircleFilled style={{color: green[3], fontSize}} />;
    }

    return (
        <CloseCircleFilled
            style={{
                color: grey[1],
                fontSize,
            }}
        />
    );
};

// export const renderPricePer = (
//     feeAmount?: Maybe<number>,
//     paymentInterval?: Maybe<PaymentInterval>,
// ) => {
//     const amount = isDefined(feeAmount) ? feeAmount : 0;

//     switch (paymentInterval) {
//         case PaymentInterval.PerCourse:
//             return renderAsMoney(
//                 amount,
//                 i18n.containers.courses.CourseEdit.perCourse(),
//             );
//             break;
//         case PaymentInterval.PerLesson:
//             return renderAsMoney(
//                 amount,
//                 i18n.containers.courses.CourseEdit.perLesson(),
//             );
//             break;
//         default:
//             return amount;
//     }
// };

export const RenderPricePer = ({
    feeAmount,
    paymentInterval,
}: {
    feeAmount?: Maybe<number>;
    paymentInterval?: Maybe<PaymentInterval>;
}) => {
    const amount = isDefined(feeAmount) ? feeAmount : 0;

    switch (paymentInterval) {
        case PaymentInterval.PerCourse:
            return (
                <div style={{display: "flex", fontSize: "1em"}}>
                    <RenderAmount amount={amount} style={{fontSize: "1em"}} />
                    <>{" / "}</>
                    <>{i18n.containers.courses.CourseEdit.perCourse()}</>
                </div>
            );
        case PaymentInterval.PerLesson:
            return (
                <div style={{display: "flex"}}>
                    <RenderAmount amount={amount} />
                    <>{" / "}</>
                    <>{i18n.containers.courses.CourseEdit.perLesson()}</>
                </div>
            );
        default:
            return <RenderAmount amount={amount} />;
    }
};

export const renderCourseTypeFee = (
    feeAmount?: Maybe<number>,
    paymentInterval?: Maybe<PaymentInterval>,
) => {
    const amount = isDefined(feeAmount) ? feeAmount : 0;

    switch (paymentInterval) {
        case PaymentInterval.PerCourse:
            return renderAsMoney(
                amount,
                i18n.containers.courses.CourseEdit.perCourse(),
            );
        case PaymentInterval.PerLesson:
            return renderAsMoney(
                amount,
                i18n.containers.courses.CourseEdit.perLesson(),
            );
        default:
            return amount;
    }
};

export const renderBirthdayAsAge = (value: ISODateString) => {
    return (
        <div className={classnames(css.text__standard)}>
            {naturalLanguageAge(moment().diff(value, "months"))}
        </div>
    );
};

export const renderDate = (value: Maybe<ISODateString>, time?: boolean) => {
    if (!value || value === "1970-01-01T00:00:00.000Z") {
        return <div className={classnames(css.text__standard)}>{" - "}</div>;
    }

    moment.locale(navigator.language);
    const datum = moment(value).format("LLL");

    moment.locale(navigator.language);

    return (
        <Tooltip title={datum}>
            <div className={classnames(css.text__standard)}>
                {moment(value).format("L")}
                <span className={css.secondary}>
                    {time ? ` ${moment(value).format("LT")}` : null}
                </span>
            </div>
        </Tooltip>
    );
};

export const renderBookerAndCustomerNumber = (booker?: {
    firstname?: string;
    lastname?: string;
    customerNumberPrefix?: string;
    customerNumber?: number;
}) => {
    if (booker) {
        return (
            <div>
                <div>
                    {booker.firstname} {booker.lastname}
                </div>
                <div className={css.secondary}>
                    {booker.customerNumberPrefix}
                    {" - "}
                    {booker.customerNumber}
                </div>
            </div>
        );
    }

    return <span>{"-"}</span>;
};

export const renderGender = (gender: Gender, style?: React.CSSProperties) => {
    return <span style={style}>{i18n.containers.common.Gender[gender]()}</span>;
};

export const renderCourseType = ({
    name,
    color,
    style,
}: {
    name: string;
    color?: string;
    style?: React.CSSProperties;
}) => {
    const theColor: Color = {name: color, value: color ? color : blue[3]};

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const actualStyle: React.CSSProperties = {
        backgroundColor: color ? color : blue[3],
        // minWidth: minWidth ?? "204px",
        borderRadius: "50px",
        "--bg-color": color ? color : blue[3],
        "--text-color": getTextColor(theColor),
        ...style,
    } as React.CSSProperties;

    return (
        <div style={actualStyle} className={css.coloredBadge}>
            <div>{name}</div>
        </div>
    );
};

export const renderCourseNumber = (
    {
        courseNumber,
        courseId,
        detailsLink,
        style,
    }: {
        courseNumber?: string;
        courseId: string;
        detailsLink?: string;
        style?: React.CSSProperties;
    },
    className?: string,
) => {
    return (
        <Button
            type="link"
            size="small"
            style={{fontSize: "1em", fontWeight: 600, ...style}}
            href={detailsLink?.replace(":id", courseId)}
        >
            <span>{courseNumber}</span>
        </Button>
    );
};

export const renderFreePlaces = (freePlaces: number) => {
    const color = freePlaces
        ? freePlaces > 3
            ? green[2]
            : freePlaces > 0
            ? orange[2]
            : red[2]
        : red[2];

    return <>{renderTextColored({text: freePlaces, color, centered: true})}</>;
};

export const renderInstructors = (
    instructors: Array<Pick<Instructor, "id" | "firstname" | "lastname">>,
) => {
    // PREVENT ERROR IF IS CALLED IN A PLACE WHERE IT SHOULDN'T BE
    if (!instructors || instructors.length === 0) {
        return <div>{"-"}</div>;
    }

    return instructors.map((instructor, index) => {
        return (
            <div key={instructor.id}>
                {instructor.firstname + " " + instructor.lastname}
                {index + 1 < instructors.length ? "," : null}
            </div>
        );
    });
};

// export const renderCourseLessons = (
//     lessons: Array<Pick<CourseLesson, "id" | "startDateTime" | "endDateTime">>,
//     showTime?: boolean,
// ) => {
//     return lessons.map((lesson, index) => {
//         return (
//             <div key={lesson.id}>
//                 <span className={css.primary}>{index + 1 + " - "}</span>
//                 <span>{moment(lesson.startDateTime).format("DD.MM.YY")}</span>
//                 {showTime ? (
//                     <span className={css.secondary}>
//                         {" | " +
//                             moment(lesson.startDateTime).format("HH:mm") +
//                             "-" +
//                             moment(lesson.endDateTime).format("HH:mm")}
//                     </span>
//                 ) : null}
//                 {/* {index + 1 < lessons.length ? "," : null} */}
//             </div>
//         );
//     });
// };

type GetCourseLessonsByCourseIdQueryItem = QueryItem<
    GetCourseLessonsByCourseIdQuery["courseLessonsByCourseId"]
>;

type RenderCourseLessonsProps = {
    courseId: Course["id"];
    showTime?: boolean;
};

export const RenderCourseLessons: FC<RenderCourseLessonsProps> = ({
    courseId,
    showTime = false,
}) => {
    const [
        getCourseLessons,
        {data, loading},
    ] = useGetCourseLessonsByCourseIdLazyQuery({});

    const courseLessons: Array<GetCourseLessonsByCourseIdQueryItem> =
        data?.courseLessonsByCourseId ?? [];

    useEffect(() => {
        getCourseLessons({variables: {courseId}});
    }, [courseId, getCourseLessons]);

    return (
        <>
            {loading ? (
                <LoadingContent />
            ) : (
                courseLessons.map((lesson, index) => {
                    return (
                        <div key={lesson.id}>
                            <span className={css.primary}>
                                {index + 1 + " - "}
                            </span>
                            <span>
                                {moment(lesson.startDateTime).format("L")}
                            </span>
                            {showTime ? (
                                <span className={css.secondary}>
                                    {" | " +
                                        moment(lesson.startDateTime).format(
                                            "LT",
                                        ) +
                                        "-" +
                                        moment(lesson.endDateTime).format("LT")}
                                </span>
                            ) : null}
                        </div>
                    );
                })
            )}
        </>
    );
};

export const renderCourseLessons = (courseId: string, showTime?: boolean) => {
    return <RenderCourseLessons courseId={courseId} showTime={showTime} />;
};

export const renderMonthAsAge = (months?: Maybe<number>) => {
    if (months) {
        return naturalLanguageAge(months);
    }

    return <div>{"-"}</div>;
};

export const renderContinuousText = (
    text: Maybe<string>,
    maxWidth?: number,
    style?: React.CSSProperties,
) => {
    const replaceBreaks = (text: string) => {
        const regex = /<br\s*\/?>/gi;

        return text.replace(regex, "\r\n");
    };

    const actualStyle: React.CSSProperties = {
        // maxWidth: maxWidth ?? 200,
        // textOverflow: "ellipsis",
        // border: "1px solid red",
        ...style,
    };

    return (
        <div style={{maxWidth: maxWidth ?? 200}}>
            <Tooltip
                title={
                    <div style={{whiteSpace: "pre-line"}}>
                        {replaceBreaks(text ?? "")}
                    </div>
                }
            >
                {/* <Tooltip title={<div>{text}</div>}> */}
                <Typography.Text ellipsis={true} style={actualStyle}>
                    {replaceBreaks(text ?? "")}
                </Typography.Text>
            </Tooltip>
        </div>
    );
};

export const renderLinkedCourseTypes = (
    courseTypes: Array<Pick<CourseType, "id" | "name" | "color">>,
) => {
    return courseTypes.map((courseType, index) => {
        // return <Tag key={courseType.id}>{courseType.name}</Tag>;
        return (
            <div key={courseType.id} style={{margin: 4}}>
                {renderCourseType({
                    name: courseType.name,
                    color: courseType.color,
                })}
            </div>
        );
    });
};

export const renderJobTitle = (level?: number) => {
    if (level === 0) {
        return (
            <span>
                {i18n.containers.staff.StaffCreate.steps.workData.jobTitle.instructorLevel[
                    level
                ]()}
            </span>
        );
    } else if (level === 1) {
        return (
            <span>
                {i18n.containers.staff.StaffCreate.steps.workData.jobTitle.instructorLevel[
                    level
                ]()}
            </span>
        );
    }

    return "-";
};

export const renderAssignedLocations = (
    locations: Array<Pick<Location, "id" | "name">>,
) => {
    return locations.map((location) => {
        return <div key={location.id}>{`${location.name}`}</div>;
    });
};

export const renderSalutation = (salutation: SalutationType) => {
    return (
        <span>
            {i18n.containers.staff.StaffCreate.steps.personalData.salutation.options[
                salutation
            ]()}
        </span>
    );
};

export const renderVenues = (venues: Array<Pick<Venue, "id" | "name">>) => {
    return venues.map((venue, index) => {
        return (
            <div key={venue.id}>
                {venue.name}
                {index + 1 < venues.length ? "," : null}
            </div>
        );
    });
};

export const renderGeneralLocationContact = (
    contact: Pick<GeneralLocationContact, "email" | "phoneNumber">,
) => {
    return (
        <>
            <div>{contact.email}</div>
            <div>{contact.phoneNumber}</div>
        </>
    );
};

export const renderCourses = (
    courses: Maybe<Array<Pick<Course, "id" | "prefixedCourseNumber">>>,
) => {
    if (courses) {
        return courses.map((course, index) => {
            return (
                <div key={course.id}>
                    {course.prefixedCourseNumber}
                    {index + 1 < courses.length ? "," : null}
                </div>
            );
        });
    }

    return "-";
};

export const renderBookingType = (
    bookingType: Extract<BookingType, string> | string,
) => {
    switch (bookingType) {
        case "Booking":
            return renderTextColored({
                text: i18n.containers.bookings.BookingList.bookingTypes[
                    bookingType
                ](),
                color: blue[1],
                centered: true,
            });
            break;
        case "Waitlist":
            return renderTextColored({
                text: i18n.containers.bookings.BookingList.bookingTypes[
                    bookingType
                ](),
                color: yellow[1],
                centered: true,
            });
            break;
        case "PlaceReservation":
            return renderTextColored({
                text: i18n.containers.bookings.BookingList.bookingTypes[
                    bookingType
                ](),
                color: red[1],
                centered: true,
            });
            break;

        default:
            return <span>{bookingType}</span>;
            break;
    }
};

export const renderBookingNumber = ({
    bookingNumberPrefix,
    bookingNumber,
    bookingType,
    className,
    canceled,
    style,
    bookingId,
    isSettled,
    highestRole,
}: {
    bookingNumberPrefix: string;
    bookingNumber: number | string;
    bookingType?: Extract<BookingType, string> | string;
    className?: string;
    canceled?: boolean;
    style?: React.CSSProperties;
    bookingId?: string;
    isSettled?: boolean;
    highestRole?: Roles;
}) => {
    const color = () => {
        switch (bookingType) {
            case "Booking":
                return blue[5];
                break;
            case "Waitlist":
                return yellow[5];
                break;
            case "PlaceReservation":
                return red[5];
                break;

            default:
                return blue[5];
                break;
        }
    };

    const canceledText = canceled ? "storniert" : "";

    const actualClassNames: Array<any> = [
        className,
        css.bookingNumber,
        canceled ? css.canceled : null,
    ];

    const renderBadge = () => (
        <Badge
            style={style}
            color={color()}
            text={
                <span
                    className={classnames(actualClassNames)}
                >{`${bookingNumberPrefix}-${bookingNumber} ${canceledText}`}</span>
            }
        />
    );

    if (bookingId) {
        const isInstructor = highestRole === Roles.Instructor;
        const editLink = isInstructor
            ? URL_INSTRUCTOR_BOOKING_EDIT_ID
            : URL_BOOKING_EDIT_ID;
        const settledDetailsLink = isInstructor
            ? URL_INSTRUCTOR_INVOICE_SETTLEMENT_DETAILS
            : URL_INVOICE_SETTLEMENT_DETAILS;
        // const editLink = URL_BOOKING_EDIT_ID;
        // const settledDetailsLink = URL_INVOICE_SETTLEMENT_DETAILS;

        if (isSettled) {
            return (
                <Link to={settledDetailsLink.replace(":id", bookingId)}>
                    {renderBadge()}
                </Link>
            );
        }

        return (
            <Link to={editLink.replace(":id", bookingId)}>{renderBadge()}</Link>
        );
    }

    return renderBadge();
};

export const RenderBookingNumber = ({
    bookingNumberPrefix,
    bookingNumber,
    bookingType,
    className,
    canceled,
    style,
    bookingId,
    invoiceId,
    reversalInvoiceId,
    bookingDeleted,
}: {
    bookingNumberPrefix?: string;
    bookingNumber?: number | string;
    bookingType?: Extract<BookingType, string> | string;
    className?: string;
    canceled?: boolean;
    style?: React.CSSProperties;
    bookingId?: string;
    invoiceId?: string;
    reversalInvoiceId?: string;
    bookingDeleted?: null | Date;
}) => {
    const [showBookingDetailsModal, setShowBookingDetailsModal] = useState(
        false,
    );

    const color = () => {
        switch (bookingType) {
            case "Booking":
                return blue[5];
                break;
            case "Waitlist":
                return yellow[5];
                break;
            case "PlaceReservation":
                return red[5];
                break;

            default:
                return blue[5];
                break;
        }
    };

    const canceledText = canceled ? "storniert" : "";

    const actualClassNames: Array<any> = [
        className,
        css.bookingNumber,
        canceled ? css.canceled : null,
    ];

    const renderBadge = () => (
        <Badge
            style={style}
            color={color()}
            text={
                <span
                    className={classnames(actualClassNames)}
                >{`${bookingNumberPrefix}-${bookingNumber} ${canceledText}`}</span>
            }
        />
    );

    if (!bookingId) {
        const Test = ({
            content,
            bookingDeleted,
        }: {
            content: string;
            bookingDeleted?: null | Date;
        }) => {
            return (
                <Tooltip
                    title={
                        bookingDeleted
                            ? `Buchung wurde am ${moment(bookingDeleted).format(
                                  "L",
                              )} gelöscht`
                            : "Keine Buchung zuordenbar"
                    }
                >
                    <div style={{width: "100%", textAlign: "center"}}>
                        {content}
                    </div>
                </Tooltip>
            );
        };

        if (bookingDeleted) {
            return <Test content="gelöscht" bookingDeleted={bookingDeleted} />;
        }

        return <Test content="-" />;
    }

    if (bookingId && bookingType) {
        return (
            <>
                <Modal
                    open={showBookingDetailsModal}
                    onCancel={() => setShowBookingDetailsModal(false)}
                    width={`calc(${panelWidth}px - (4 * ${margin}))`}
                    closable={false}
                    style={{
                        top: margin,
                        padding: 0,
                    }}
                    bodyStyle={{marginLeft: -15, marginRight: -15, padding: 0}}
                    cancelButtonProps={{style: {display: "none"}}}
                    okButtonProps={{style: {display: "none"}}}
                >
                    <SettlementDetails
                        bookingId={bookingId}
                        modalStyle={{
                            border: "none",
                            padding: 0,
                            marginTop: 0,
                        }}
                        setModalOpen={() => setShowBookingDetailsModal(false)}
                    />
                </Modal>

                <Button
                    type="link"
                    onClick={() => setShowBookingDetailsModal(true)}
                >
                    {renderBadge()}
                </Button>
            </>
        );
    }

    return renderBadge();
};

export const renderDetailsButton = (
    detailsLink: string,
    id: string,
    record: unknown,
    tableData: unknown,
) => {
    return (
        <Button type="link" size="small">
            <Link
                to={{
                    pathname: detailsLink.replace(":id", id),

                    state: {record, tableData},
                }}
            >
                {i18n.containers.invoice.Invoice.details()}
            </Link>
        </Button>
    );
};

export const renderCourseDetails = (
    path: string,
    record: CourseTableType & LocationState,
) => (
    <Link
        to={{
            pathname: path,
            state: record,
        }}
    >
        <Button size="small" type="link">
            {i18n.containers.invoice.Invoice.details()}
        </Button>
    </Link>
);

export const renderSettled = (
    settledBookingCount: number,
    bookingCount: number,
) => {
    return (
        <div>{`${settledBookingCount} ${i18n.containers.invoice.Invoice.of()} ${bookingCount}`}</div>
    );
};

export const renderDateFormatted = (
    value: ISODateString,
    format?: MomentFormatSpecification,
) => {
    if (typeof format === "string") {
        return (
            <div className={classnames(css.text__standard)}>
                {moment(value).format(format)}
            </div>
        );
    }

    return (
        <div className={classnames(css.text__standard)}>
            {moment(value).format("DD.MM.YYYY")}
        </div>
    );
};

export const renderDateAsWeekdayTag = ({
    value,
    style,
    color,
}: {
    value: ISODateString;
    style?: React.CSSProperties;
    color?: string;
}) => {
    const actualColor = color ?? primaryColor75;
    const weekday = moment(value).format("dddd");

    return (
        <Tag style={style} color={actualColor}>
            {weekday}
        </Tag>
    );
};

export const renderStartEndTime = ({
    startTime,
    endTime,
    format,
}: {
    startTime: ISODateString;
    endTime: ISODateString;
    format?: MomentFormatSpecification;
}) => {
    const actualFormat = typeof format === "string" ? format : "HH:mm";

    return (
        <div>{`${moment(startTime).format(actualFormat)} - ${moment(
            endTime,
        ).format(actualFormat)}`}</div>
    );
};

export const renderMailSentStatus = ({status}: {status: string}) => {
    const getStatus = (Status: string) => {
        switch (Status) {
            case "Success":
                return renderTextColored({
                    text: "Versendet",
                    color: green[2],
                    centered: true,
                });
                break;
            case "Error":
                return renderTextColored({
                    text: "Fehler",
                    color: red[2],
                    centered: true,
                });
                break;
            default:
                return renderTextColored({
                    text: "Unbekannt",
                    color: grey[2],
                    centered: true,
                });
                break;
        }
    };

    return getStatus(status);
};

export const renderTimeFromNow = ({
    date,
    short,
}: {
    date: ISODateString;
    short?: boolean;
}) => {
    moment.locale(navigator.language);
    const datum = moment(date).format("LLL");

    return (
        <Tooltip title={datum}>
            <div>{moment(date).fromNow()}</div>
        </Tooltip>
    );
};

export const renderTemplateType = ({
    templateType,
}: {
    templateType: Maybe<string>;
}) => {
    // THIS NEEDS TO BE FIXED THE TEMPLATE ID THAT IS SAVE IN DB IS THE ID OF MAILJET NOT OUR OWN DB-TEMPLATE
    // console.log("templateId", templateType);

    const TemplateRender = ({templateType}: {templateType: string}) => {
        // console.log("templateType", templateType);

        const templateTypeTranslation: TemplateType =
            TemplateType.BookingConfirmation;

        const templateName = () => {
            switch (templateType) {
                case "BookingConfirmation":
                    return i18n.containers.templates.EmailTemplates.defaultTemplates.bookingConfirmation();
                    break;
                case "SettlementConfirmation":
                    return i18n.containers.templates.EmailTemplates.defaultTemplates.settlementConfirmation();
                    break;
                case "SettledBookingCancellation":
                    return i18n.containers.templates.EmailTemplates.defaultTemplates.settledBookingCancellation();
                    break;
                case "WaitingListConfirmation":
                    return i18n.containers.templates.EmailTemplates.defaultTemplates.waitingListConfirmation();
                    break;

                case "invoice":
                    return i18n.containers.templates.EmailTemplates.defaultTemplates.invoice();
                    break;

                case "cancelInvoice":
                    return i18n.containers.templates.EmailTemplates.defaultTemplates.cancelInvoice();
                    break;

                case "Reminder":
                    return i18n.containers.templates.EmailTemplates.defaultTemplates.courseMemory();
                    break;

                case "Warning":
                    return i18n.containers.templates.EmailTemplates.defaultTemplates.warning();
                    break;
                case "NoTemplate":
                    return i18n.containers.templates.EmailTemplates.defaultTemplates.noTemplate();
                    break;

                default:
                    return i18n.containers.templates.EmailTemplates.defaultTemplates.unknown();
                    break;
            }
        };

        // console.log("templateTypeTranslation", templateTypeTranslation);

        return <div>{templateName()}</div>;
    };

    if (templateType) {
        return <TemplateRender templateType={templateType} />;
    }

    return <div>{"nichts gefunden"}</div>;
};

const TestComponent = ({
    courseId,
    kind,
    freePlaces,
}: {
    courseId: string;
    kind: "waitlist" | "bookings" | "placereservations" | "all";
    freePlaces?: number;
}) => {
    const {data, loading, refetch} = useGetBookingsByCourseMinimalQuery({
        variables: {id: courseId},
    });

    useEffect(() => {
        refetch();
    }, [freePlaces, refetch]);

    const bookingsIncludingCanceled = data?.getBookingsByCourse["items"];

    // const allBookings = data?.getBookingsByCourse["items"];
    const allBookings = bookingsIncludingCanceled?.filter((booking) => {
        return booking.canceled === null;
    });

    const waitingListEntries = allBookings?.filter((booking) => {
        return booking.bookingNumberPrefix === "W";
    });

    const placervationsEntries = allBookings?.filter((booking) => {
        return booking.bookingNumberPrefix === "P";
    });

    const bookingEntries = allBookings?.filter((booking) => {
        return booking.bookingNumberPrefix === "B" && !booking.canceled;
    });

    if (loading) {
        // return <LoadingContent />;
        return <Spin size="small" style={{width: "100%"}} />;
    }

    switch (kind) {
        case "waitlist":
            return (
                <>
                    <div>{waitingListEntries?.length}</div>
                </>
            );
        case "bookings":
            return (
                <>
                    <div>{bookingEntries?.length}</div>
                </>
            );
        case "placereservations":
            return (
                <>
                    <div>{placervationsEntries?.length}</div>
                </>
            );
        case "all":
            return (
                <>
                    <div>
                        <Badge
                            color={blue[5]}
                            text={
                                <span style={{fontSize: "0.8rem"}}>
                                    <strong>{bookingEntries?.length}</strong>
                                    {" Buchungen"}
                                </span>
                            }
                        />
                    </div>
                    <div>
                        <Badge
                            color={yellow[5]}
                            text={
                                <span style={{fontSize: "0.8rem"}}>
                                    <strong>
                                        {waitingListEntries?.length}
                                    </strong>
                                    {" Wartelisteeinträge"}
                                </span>
                            }
                        />
                    </div>
                    <div>
                        <Badge
                            color={red[5]}
                            text={
                                <span style={{fontSize: "0.8rem"}}>
                                    <strong>
                                        {placervationsEntries?.length}
                                    </strong>
                                    {" Platzreservierungen"}
                                </span>
                            }
                        />
                    </div>
                </>
            );
        default:
            return (
                <>
                    <div>
                        {"Warteliste:"} {waitingListEntries?.length}
                    </div>
                    <div>
                        {"Buchungen:"} {bookingEntries?.length}
                    </div>
                    <div>
                        {"Platzreservierungen:"} {placervationsEntries?.length}
                    </div>
                </>
            );
            break;
    }
};

export const renderWaitlistCount = ({
    courseId,
    kind,
    freePlaces,
}: {
    courseId: string;
    kind: "waitlist" | "bookings" | "placereservations" | "all";
    freePlaces?: number;
}) => {
    return (
        <TestComponent
            courseId={courseId}
            kind={kind}
            freePlaces={freePlaces}
        />
    );
};

export const RenderUserName = ({userId}: {userId: string}) => {
    const [userData, setUserData] = useState<Partial<UserType> | undefined>();
    const {data, loading} = useGetUserInfoByIdQuery({
        variables: {userId},
    });

    useEffect(() => {
        setUserData(data?.getInfoUserByUserId);
    }, [data]);

    if (loading) {
        return <LoadingContent />;
    }

    const {firstName, lastName, email} = userData ?? {};

    let nameToShow = email;

    if (firstName && lastName) {
        nameToShow = `${firstName} ${lastName}`;
    }

    return <div>{nameToShow ?? "-"}</div>;
};

export const RenderLastCourseLesson = ({
    // courseLessons,
    courseId,
}: {
    // courseLessons?: Array<CourseLesson>;
    courseId: string;
}) => {
    const [courseLessons, setCourseLessons] = useState<
        | Array<Pick<CourseLesson, "id" | "startDateTime" | "endDateTime">>
        | undefined
    >();
    const {data, loading} = useGetCourseLessonsByCourseIdQuery({
        variables: {courseId},
    });

    // const courseLessons = data?.courseLessonsByCourseId;
    useEffect(() => {
        setCourseLessons(data?.courseLessonsByCourseId);
    }, [data, loading]);

    if (loading) {
        return <Spin size="small" style={{width: "100%"}} />;
    }

    if (courseLessons) {
        const sortedLessons = courseLessons.sort((a, b) => {
            if (a.startDateTime && b.startDateTime) {
                return a.startDateTime > b.startDateTime ? 1 : -1;
            }

            return 0;
        });

        const lastCourseLesson = sortedLessons[sortedLessons.length - 1];

        return (
            <>
                {lastCourseLesson.startDateTime &&
                    lastCourseLesson.endDateTime && (
                        <>
                            <div style={{whiteSpace: "nowrap"}}>
                                {moment(lastCourseLesson.startDateTime).format(
                                    "dd DD. MMM yyyy",
                                )}
                            </div>
                            <div
                                style={{
                                    color: colorLightGrey,
                                    whiteSpace: "nowrap",
                                }}
                            >
                                {moment(lastCourseLesson.startDateTime).format(
                                    "HH:mm",
                                ) +
                                    " - " +
                                    moment(lastCourseLesson.endDateTime).format(
                                        "HH:mm",
                                    )}
                            </div>
                        </>
                    )}
            </>
        );
    }

    return <div>{"-"}</div>;
};

export const renderBookingsKo9 = ({
    bookingsKo9,
}: {
    bookingsKo9?: Array<BookingsKo9>;
}) => {
    if (!bookingsKo9) {
        return <div>{"-"}</div>;
    }

    return bookingsKo9.map((booking) => {
        return (
            <div key={booking.id}>
                <Tooltip
                    title={`${booking.courseName} - ${moment(
                        booking.firstCourseLesson,
                    ).format("DD.MM.YYYY")}`}
                >
                    {booking.courseNumber}
                </Tooltip>
            </div>
        );
    });
};

export const renderBookingPrice = (record: TableType) => {
    const {isSpecial, grossPrice} = record;
    const coursePrice = record.coursePrice.grossPrice;

    if (isSpecial || grossPrice !== coursePrice) {
        return (
            <Tooltip
                title={i18n.containers.bookings.BookingList.specialPrice.description(
                    {
                        grossPrice: grossPrice.toString(),
                        coursePrice: coursePrice.toString(),
                    },
                )}
            >
                <div style={{fontWeight: 550}}>{`${grossPrice}€*`}</div>
            </Tooltip>
        );
    }

    return <div>{`${grossPrice}€`}</div>;
};

export const RenderBookingPrice = ({record}: {record: TableType}) => {
    const {grossPrice} = record;

    const courseLessonCount = record.courseLessons.length;
    const coursePrice =
        record.courseLessons[0].course.paymentInterval ===
        PaymentInterval.PerLesson
            ? record.coursePrice.grossPrice * courseLessonCount
            : record.coursePrice.grossPrice;

    // if ( grossPrice !== coursePrice) {
    if (
        !nearlyEqual(
            Number(grossPrice),
            Number(coursePrice),
            0.03,
        ) /* grossPrice !== coursePrice */
    ) {
        return (
            <Tooltip
                title={i18n.containers.bookings.BookingList.specialPrice.description(
                    {
                        grossPrice: grossPrice.toString(),
                        coursePrice: coursePrice.toString(),
                    },
                )}
            >
                <div className={css.specialPrice}>
                    <RenderAmount
                        amount={grossPrice}
                        className={css.text__bold}
                    />
                    {"*"}
                </div>
            </Tooltip>
        );
    }

    return <RenderAmount amount={grossPrice} />;
};

export const RenderFaUser = ({
    email,
    bookerId,
    userId,
    faUserId,
    showEmailAddress,
}: {
    email: string;
    bookerId: Booker["id"];
    userId?: string;
    faUserId?: (faUserId: string) => void;
    showEmailAddress?: boolean;
}) => {
    const [createFaUser] = useAddFaUserByBookerIdMutation({
        refetchQueries: [
            "GetBookersOverview",
            {query: GetBookersOverviewDocument},
        ],
    });

    if (userId) {
        return (
            <div>
                <Tooltip title={userId}>
                    {renderBooleanAsCheckmark(true)}
                </Tooltip>
            </div>
        );
    }

    if (!email) {
        return (
            <Tooltip title="Bucher ohne Email-Adresse können keinen Onlineugang erhalten">
                <div style={{color: grey[3]}}>{"Keine Email"}</div>
            </Tooltip>
        );
    }

    return (
        <div>
            <Tooltip
                title={`Es wird ein neuer Zungang für ${email}angelegt, oder der bestehende verknüpft.`}
            >
                <Button
                    size="small"
                    type="primary"
                    onClick={async () =>
                        createFaUser({variables: {bookerId}}).then((res) => {
                            if (res.data?.addFaUserByBookerId?.id) {
                                faUserId?.(res.data.addFaUserByBookerId.id);
                            }
                        })
                    }
                >
                    {"Anlegen"}
                </Button>
            </Tooltip>
            {showEmailAddress && (
                <div style={{color: grey[3], fontSize: 10}}>{email}</div>
            )}
        </div>
    );
};

export const RenderCourseLessonPosition = ({
    index,
}: {
    index?: number | null;
}) => {
    if (index === undefined || index === null) {
        return <div>{"-"}</div>;
    }

    return <div>{index + 1}</div>;
};

export const renderMessageHistory = (
    messageHistory: GetEmailNotificationsQuery["emailNotifications"]["items"][0]["messageHistory"],
) => {
    const iconStyle = (color: string) => {
        return {
            fontSize: "1.1em",
            color,
            paddingTop: 4,
        };
    };

    const fallBack: MailjetStatusOptions = {
        value: "unknown",
        label: "Unbekannt",
        color: "red",
        icon: <QuestionCircleOutlined style={iconStyle(red[5])} />,
        description: `Wenn eine Email den Status "Unbekannt" hat, haben wir keine Informationen erhalten. In den aller meisten Fällen bedeutet dies jedoch, dass die Email normal zugestellt wurde.`,
    };

    const Status = ({status}: {status: string}) => {
        const translatedStatus = (): {
            color: string;
            label: string;
            icon: React.Component | any;
            description: string;
        } => {
            switch (status) {
                case "unknown":
                    return (
                        mailjetStatusOptions.find(
                            (option) => option.value === "unknown",
                        ) ?? fallBack
                    );
                case "queued":
                    return (
                        mailjetStatusOptions.find(
                            (option) => option.value === "queued",
                        ) ?? fallBack
                    );
                case "sent":
                    return (
                        mailjetStatusOptions.find(
                            (option) => option.value === "sent",
                        ) ?? fallBack
                    );
                case "opened":
                    return (
                        mailjetStatusOptions.find(
                            (option) => option.value === "opened",
                        ) ?? fallBack
                    );
                case "clicked":
                    return (
                        mailjetStatusOptions.find(
                            (option) => option.value === "clicked",
                        ) ?? fallBack
                    );
                case "bounce":
                    return (
                        mailjetStatusOptions.find(
                            (option) => option.value === "bounce",
                        ) ?? fallBack
                    );
                case "spam":
                    return (
                        mailjetStatusOptions.find(
                            (option) => option.value === "spam",
                        ) ?? fallBack
                    );
                case "unsub":
                    return (
                        mailjetStatusOptions.find(
                            (option) => option.value === "unsub",
                        ) ?? fallBack
                    );
                case "blocked":
                    return (
                        mailjetStatusOptions.find(
                            (option) => option.value === "blocked",
                        ) ?? fallBack
                    );
                case "hardbounced":
                    return (
                        mailjetStatusOptions.find(
                            (option) => option.value === "hardbounced",
                        ) ?? fallBack
                    );
                case "softbounced":
                    return (
                        mailjetStatusOptions.find(
                            (option) => option.value === "softbounced",
                        ) ?? fallBack
                    );
                case "deferred":
                    return (
                        mailjetStatusOptions.find(
                            (option) => option.value === "deferred",
                        ) ?? fallBack
                    );
                default:
                    return (
                        mailjetStatusOptions.find(
                            (option) => option.value === "unknown",
                        ) ?? fallBack
                    );
            }
        };

        return (
            <Space size={5}>
                {translatedStatus().icon}
                <Tag
                    color={translatedStatus().color}
                    style={{
                        fontSize: "0.7rem",
                        paddingLeft: 4,
                        paddingRight: 4,
                        lineHeight: 1.3,
                    }}
                >
                    {translatedStatus().label}
                </Tag>
            </Space>
        );
    };

    const RenderHistory = () => {
        if (!messageHistory?.Count || messageHistory.Count === 0) {
            return (
                <Space size={5}>
                    <QuestionCircleOutlined style={iconStyle(grey[5])} />
                    <span style={{color: grey[3]}}>{"Keine Historie"}</span>
                </Space>
            );
        }

        return (
            <Space direction="vertical" size={3}>
                {messageHistory.Data.map((entry, index) => {
                    if (index + 1 === messageHistory.Count) {
                        return <Status key={index} status={entry.EventType} />;
                    }
                })}
            </Space>
        );
    };

    return <RenderHistory />;
};

export const renderMessageStatus = (
    messageStatus: GetEmailNotificationsQuery["emailNotifications"]["items"][0]["messageStatus"],
) => {
    const iconStyle = (color: string) => {
        return {
            fontSize: "1.1em",
            color,
            paddingTop: 4,
        };
    };
    const Status = ({
        status,
        arrivedAt,
    }: {
        status?: string;
        arrivedAt?: string;
    }) => {
        const translatedStatus = (): {
            color: string;
            text: string;
            icon: React.Component | any;
            description: string;
        } => {
            switch (status) {
                case "unknown":
                    return {
                        text: "Unbekannt",
                        color: "red",
                        icon: (
                            <QuestionCircleOutlined style={iconStyle(red[5])} />
                        ),
                        description: `Wenn eine Email den Status "Unbekannt" hat, haben wir keine Informationen erhalten. In den aller meisten Fällen bedeutet dies jedoch, dass die Email normal zugestellt wurde.`,
                    };
                case "queued":
                    return {
                        text: "In Warteschlange",
                        color: "orange",
                        icon: (
                            // <ClockCircleOutlined style={iconStyle(orange[5])} />
                            <LoadingOutlined style={iconStyle(orange[5])} />
                        ),
                        description: `Hat eine Email den Status "In Warteschlange", haben wir diese erfolgreich an unseren Versanddienstleister übermittelt, wo sie auf den Versand vorbeitet wird.\n
                        Normalerweise wird eine E-Mail innerhalb von 5 Minuten nach dem Versand aus der Warteschlange entfernt und der Status wird auf "Zugestellt" geändert.\n
                        In seltenen Fällen kann es jedoch vorkommen, dass eine E-Mail länger als 5 Minuten in der Warteschlange bleibt. Dies kann z.B. passieren, wenn der Empfänger-Server nicht erreichbar ist oder sehr viele Anfragen abgearbeitet werden müssen.`,
                    };
                case "sent":
                    return {
                        text: "Zugestellt",
                        color: "green",
                        icon: (
                            <CheckCircleOutlined style={iconStyle(green[5])} />
                        ),
                        description: `Hat eine E-Mail den Status „Zugestellt“, wurde diese erfolgreich gesendet und vom Empfangsserver akzeptiert.\n
                        "Zugestellt" bedeutet nicht zwingend, dass die E-Mail dem „Posteingang des Empfängers zugestellt wird“. Nur der Empfänger allein kann wissen, ob die Nachricht seinem Posteingang oder Spam-Ordner zugestellt wurde.`,
                    };
                case "opened":
                    return {
                        text: "Geöffnet",
                        color: "green",
                        icon: <CheckCircleFilled style={iconStyle(green[5])} />,
                        description: `Hat eine Email den Status "Geöffnet", wurde diese erfolgreich gesendet und vom Empfangsserver akzeptiert und vom Empfänger geöffnet.`,
                    };
                case "clicked":
                    return {
                        text: "Angeklickt",
                        color: "green",
                        icon: (
                            <CheckCircleOutlined style={iconStyle(green[5])} />
                        ),
                        description: `Hat eine Email den Status "Angeklickt", wurde diese erfolgreich gesendet und vom Empfangsserver akzeptiert und vom Empfänger angeklickt.`,
                    };
                case "bounce":
                    return {
                        text: "Rückläufer",
                        color: "orange",
                        icon: (
                            <ClockCircleOutlined style={iconStyle(orange[5])} />
                        ),
                        description: `Hat eine Email den Status "Rückläufer" wurde diese dem Empfänger nicht zugestellt. Es gibt 2 unterschiedliche Arten von Rückläufern:\n
                        Soft Bounce:
                        - Hierbei handelt es sich um ein vorübergehendes Problem, z.B. wenn der Posteingang des Empfängers voll ist oder ein Verbindungstimeout stattgefunden hat.\n
                        Hard Bounce:
                        - In diesem Fall liegt ein dauerhafter Zustellungsfehler vor, der durch eine ungültige E-Mail-Adresse verursacht wurde (z. B. falsch eingegebene E-Mail-Adresse, nicht vorhandener Zielserver).`,
                    };
                case "spam":
                    return {
                        text: "Spam",
                        color: "red",
                        icon: <WarningOutlined style={iconStyle(red[5])} />,
                        description: `Hat eine Email den Status "Spam", wurde diese erfolgreich gesendet und vom Empfangsserver akzeptiert.\n
                        Das Melden einer EMail als Spam ist eine Aktion, die vom Empfänger nach Erhalt Ihrer E-Mail durchgeführt wird.\n
                        Spam-Beschwerden entstehen dann, wenn eine E-Mail laut Meinung des Empfängers unerwünscht ist.`,
                    };
                case "unsub":
                    return {
                        text: "Abbestellt",
                        color: "red",
                        icon: <WarningOutlined style={iconStyle(red[5])} />,
                        description: `Hat eine Email den Status "Abbestellt", wurde diese erfolgreich gesendet und vom Empfangsserver akzeptiert und vom Empfänger über den Abmeldelink abbestellt.`,
                    };
                case "blocked":
                    return {
                        text: "Geblockt",
                        color: "red",
                        icon: <WarningOutlined style={iconStyle(red[5])} />,
                        description: `Hat eine Email den Status "Geblockt", wurde die Email von unserem Versanddienstleister Mailjet geblockt.\n
                        Um zu entscheiden, ob eine E-Mail blockiert werden soll, werden vom Mailjet-System mehrere Parameter überprüft, einschließlich Ihres Versandverlaufs. Beispiele für die Gründe einer Blockierung der E-Mail:\n
                        - Diese E-Mail-Adresse gibt es nicht. (Wahrscheinlichster Grund!)\n
                        - Ein Empfänger hat Ihre E-Mail als Spam gemeldet.\n
                        - Eine E-Mail-Adresse, bei der bereits ein Hard Bounce aufgetreten ist.\n
                        - Eine E-Mail, die aufgrund ihres Inhalts als Spam eingestuft würde. Bevor Ihre Kampagnen versendet werden, durchsucht unser System die Kampagne mithilfe einer Anti-Spam-Software, um diese auf potenzielle spambezogene Auslöser oder Probleme hin zu überprüfen (z. B. GROSSBUCHSTABEN, schlechtes Verhältnis von Text und Bildern, Spam-Wörter).`,
                    };
                case "hardbounced":
                    return {
                        text: "Rückläufer (Hard Bounce)",
                        color: "red",
                        icon: <WarningOutlined style={iconStyle(red[5])} />,
                        description: `Hat eine Email den Status "Rückläufer (Hard Bounce)" wurde diese dem Empfänger nicht zugestellt. Es gibt 2 unterschiedliche Arten von Rückläufern:\n
                        Soft Bounce:
                        - Hierbei handelt es sich um ein vorübergehendes Problem, z.B. wenn der Posteingang des Empfängers voll ist oder ein Verbindungstimeout stattgefunden hat.\n
                        Hard Bounce:
                        - In diesem Fall liegt ein dauerhafter Zustellungsfehler vor, der durch eine ungültige E-Mail-Adresse verursacht wurde (z. B. falsch eingegebene E-Mail-Adresse, nicht vorhandener Zielserver).`,
                    };
                case "softbounced":
                    return {
                        text: "Rückläufer (Soft Bounce)",
                        color: "orange",
                        icon: (
                            <ClockCircleOutlined style={iconStyle(orange[5])} />
                        ),
                        description: `Hat eine Email den Status "Rückläufer (Soft Bounce)" wurde diese dem Empfänger nicht zugestellt. Es gibt 2 unterschiedliche Arten von Rückläufern:\n
                        Soft Bounce:
                        - Hierbei handelt es sich um ein vorübergehendes Problem, z.B. wenn der Posteingang des Empfängers voll ist oder ein Verbindungstimeout stattgefunden hat.\n
                        Hard Bounce:
                        - In diesem Fall liegt ein dauerhafter Zustellungsfehler vor, der durch eine ungültige E-Mail-Adresse verursacht wurde (z. B. falsch eingegebene E-Mail-Adresse, nicht vorhandener Zielserver).`,
                    };
                case "deferred":
                    return {
                        text: "Erneuter Versuch",
                        color: "orange",
                        icon: (
                            <ClockCircleOutlined style={iconStyle(orange[5])} />
                        ),
                        description: `Wenn eine E-Mail als „Wiederholungs-E-Mail“ eingestuft wird, bedeutet dies, dass wir versuchen, sie zuzustellen und auf eine Antwort des Zielservers (Gmail, Hotmail usw.) warten.\n
                        Diese Antwort kann ein paar Sekunden bis hin zu 24 Stunden auf sich warten lassen. Sobald 24 Stunden vergangen sind, wird die E-Mail als Soft Bounce markiert und nicht zugestellt.`,
                    };
                default:
                    return {
                        text: "Unbekannt",
                        color: grey[3],
                        icon: (
                            <QuestionCircleOutlined
                                style={iconStyle(grey[5])}
                            />
                        ),
                        description: `Wenn eine Email den Status "Unbekannt" hat, haben wir keine Informationen erhalten. In den aller meisten Fällen bedeutet dies jedoch, dass die Email normal zugestellt wurde.`,
                    };
            }
        };

        return (
            <>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Space size={5}>
                        {translatedStatus().icon}
                        <Tag
                            color={translatedStatus().color}
                            style={{
                                fontSize: "0.7rem",
                                paddingLeft: 4,
                                paddingRight: 4,
                                lineHeight: 1.3,
                            }}
                        >
                            {translatedStatus().text}
                        </Tag>
                    </Space>
                    <Tooltip
                        title={
                            <span style={{whiteSpace: "pre-line"}}>
                                {translatedStatus().description}
                            </span>
                        }
                        trigger={["click"]}
                        mouseLeaveDelay={100}
                        placement="right"
                    >
                        <InfoCircleOutlined style={{color: blue[5]}} />
                    </Tooltip>
                </div>
                {arrivedAt && (
                    <div
                        style={{
                            fontSize: "1em",
                            color: grey[3],
                            maxWidth: "80px",
                        }}
                    >
                        {renderTimeFromNow({date: arrivedAt, short: true})}
                    </div>
                )}
            </>
        );
    };

    return (
        <Status
            // key={index}
            status={messageStatus?.Status}
            arrivedAt={messageStatus?.ArrivedAt}
        />
    );
};

export const renderAttachments = ({
    attachments,
}: {
    attachments?: Array<{contentType?: "string"; fileName?: "string"}>;
}) => {
    const color = blue[4];

    if (!attachments || attachments.length === 0) {
        return <div style={{color: grey[1]}}>{"keine Anhänge"}</div>;
    }

    const getFileIcon = (contentType?: "string") => {
        if (contentType?.includes("pdf")) {
            return <FilePdfOutlined style={{color}} />;
        } else if (contentType?.includes("image")) {
            return <FileImageOutlined style={{color}} />;
        }

        return <FileOutlined style={{color}} />;
    };

    return (
        <Space direction="vertical" size={3}>
            {attachments.map((attachment, index) => {
                return (
                    <Tag
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${attachment.fileName}-${index}`}
                        color="blue"
                        icon={getFileIcon(attachment.contentType)}
                        style={{
                            color,
                            paddingLeft: 3,
                            paddingRight: 4,
                            lineHeight: 1.3,
                        }}
                    >
                        {`${attachment.fileName}`}
                    </Tag>
                );
            })}
        </Space>
    );
};

export const RenderLinkedBooking = ({
    bookingId,
}: // settled,
{
    bookingId?: string;
    // settled?: boolean;
}) => {
    if (!bookingId) {
        return <div>{"-"}</div>;
    }

    const link = URL_MY_BOOKINGS_EDIT_ID.replace(":id", bookingId);

    // const link = settled
    //     ? URL_BOOKINGS_SETTLEMENT_DETAILS.replace(":id", bookingId)
    //     : URL_MY_BOOKINGS_EDIT_ID.replace(":id", bookingId);

    return (
        <Link to={link}>
            <Button type="link" size="small">
                {bookingId}
            </Button>
        </Link>
    );
};

export const renderInvoiceTitle = (title?: string) => {
    if (!title) {
        return <div style={{color: grey[3]}}>{"Automatisch"}</div>;
    }

    return <div>{title}</div>;
};

export const MemberStatusCheckbox = ({
    attendeeId,
    isMember,
}: {
    attendeeId: Attendee["id"];
    isMember: boolean;
}) => {
    const [isChecked, setIsChecked] = useState(isMember);
    const [
        updateMemberStatus,
        {loading},
    ] = useChangeAttendeeMemberStatusMutation();

    const handleChange = async (e: CheckboxChangeEvent) => {
        const checked = e.target.checked;

        try {
            await updateMemberStatus({
                variables: {
                    id: attendeeId,
                    member: checked,
                },
                // refetchQueries: [
                //     "AttendeeOverviewGetAttendeesMinimal",
                //     {
                //         query: AttendeeOverviewGetAttendeesMinimalDocument,
                //     },
                // ],
            }).then((res) => {
                const newMemberStatus =
                    res.data?.changeAttendeeMemberStatus.member;

                console.log("newMemberStatus", newMemberStatus);
                if (newMemberStatus !== null && newMemberStatus !== undefined) {
                    setIsChecked(newMemberStatus);
                } else {
                    setIsChecked(isMember);
                }
            });
        } catch (error) {
            console.error("Failed to update member status", error);
            message.error("Fehler beim Aktualisieren des Mitgliedsstatus");
        }
    };

    if (loading) {
        return <Spin size="small" />;
    }

    return <Checkbox checked={isChecked} onChange={handleChange} />;
};

export const renderAdditionalInfo = ({
    additionalInfo,
}: {
    additionalInfo: Maybe<string> | undefined;
}) => {
    // additionalInfo is a string that is an json object as string and needs to be parsed

    if (!additionalInfo) {
        return <div>{"-"}</div>;
    }

    let parsedInfo: Record<string, string> | undefined;

    try {
        parsedInfo = JSON.parse(additionalInfo);
    } catch (error) {
        console.error("Failed to parse additional info", error);
    }

    if (!parsedInfo) {
        return <div>{additionalInfo}</div>;
    }

    return (
        <Space direction="vertical" size={3}>
            {Object.entries(parsedInfo).map(([key, value], index) => {
                if (value === "null") {
                    return null;
                }

                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={index}>
                        <strong>{key}</strong>
                        {": "}
                        {value}
                    </div>
                );
            })}
        </Space>
    );
};

export const renderMailDataType = ({
    mailDataType,
}: {
    mailDataType?: Maybe<MailDataType>;
}) => {
    if (!mailDataType) {
        return <div>{"-"}</div>;
    }

    return <MailDataTypeBadge mailDataType={mailDataType} />;
};

export const renderEnabledCourses = ({
    enabledCourses,
    prerquisiteCourseId,
    bookingRestrictionId,
    refetch,
}: {
    enabledCourses?: WithEnabledCourse["enabledCourses"];
    prerquisiteCourseId: string;
    bookingRestrictionId?: string;
    refetch?: () => void;
}) => {
    return (
        <CellEnabledCourses
            enabledCourses={enabledCourses}
            prerquisiteCourseId={prerquisiteCourseId}
            bookingRestrictionId={bookingRestrictionId}
            refetch={refetch}
        />
    );
};

//     if (!enabledCourses) {
//         return <div>{"-"}</div>;
//     }

//     const space = 1;

//     const renderDot = ({letter, bool}: {letter: string; bool: boolean}) => {
//         return (
//             <div
//                 style={{
//                     display: "flex",
//                     alignItems: "center",
//                     marginRight: space * 2,
//                     marginLeft: space * 2,
//                 }}
//             >
//                 {letter}
//                 {bool ? (
//                     <CheckCircleFilled
//                         style={{
//                             color: green[4],
//                             marginRight: space,
//                             marginLeft: space,
//                             fontSize: "1em",
//                         }}
//                     />
//                 ) : (
//                     <CloseCircleFilled
//                         style={{
//                             color: grey[1],
//                             marginRight: space,
//                             marginLeft: space,
//                             fontSize: "1em",
//                         }}
//                     />
//                 )}
//             </div>
//         );
//     };

//     const renderCourse = ({
//         name,
//         color,
//         enabledCourse,
//         style,
//     }: {
//         name: string;
//         color?: string;
//         enabledCourse?: EnabledCourse;
//         style?: React.CSSProperties;
//     }) => {
//         if (!enabledCourse) {
//             return null;
//         }

//         const theColor: Color = {name: color, value: color ? color : blue[3]};
//         const {enabledRestriction} = enabledCourse;

//         if (!enabledRestriction) {
//             return null;
//         }

//         const {
//             includeWaitingList,
//             includeBookingList,
//             membersOnly,
//         } = enabledRestriction;

//         const tooltipTitle = `Warteliste wird berücksichtigen: ${includeWaitingList}\nBuchungen werden berücksichtigen: ${includeBookingList}\nNur für Mitglieder: ${membersOnly}`;

//         // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
//         const actualStyle: React.CSSProperties = {
//             backgroundColor: color ? color : blue[3],
//             borderRadius: "50px",
//             "--bg-color": color ? color : blue[3],
//             "--text-color": getTextColor(theColor),
//             display: "flex",
//             alignItems: "center",
//             // justifyContent: "space-between",
//             ...style,
//         } as React.CSSProperties;

//         return (
//             <div>
//                 <Tooltip title={tooltipTitle}>
//                     <Space style={actualStyle} className={css.coloredBadge}>
//                         <div>{name}</div>
//                         <CloseCircleFilled
//                             style={{
//                                 fontSize: "1.3em",
//                                 paddingTop: 2,
//                                 marginRight: -10.5,
//                             }}
//                         />
//                     </Space>
//                 </Tooltip>
//                 <div
//                     style={{
//                         width: "100%",
//                         display: "flex",
//                         justifyContent: "center",
//                     }}
//                 >
//                     {renderDot({letter: "W", bool: includeWaitingList})}
//                     {renderDot({letter: "B", bool: includeBookingList})}
//                 </div>
//             </div>
//         );
//     };
//
//     return (
//         <div style={{display: "flex", flexWrap: "wrap", gap: 4}}>
//             {enabledCourses.map((pc) => (
//                 <div key={pc.id}>
//                     {renderCourse({
//                         name: pc.prefixedCourseNumber,
//                         color: pc.courseType.color,
//                         enabledCourse: pc,
//                         style: {fontSize: "0.9em", width: "max-content"},
//                     })}
//                 </div>
//             ))}
//             <Tooltip title="Kurse hinzufügen">
//                 <Button
//                     type="default"
//                     shape="round"
//                     size="small"
//                     style={{
//                         width: 20,
//                         height: 20,
//                         padding: 0,
//                         borderColor: blue[4],
//                         color: blue[5],
//                     }}
//                     icon={<PlusOutlined />}
//                     onClick={() => message.warn("Noch nicht implementiert")}
//                 />
//             </Tooltip>
//         </div>
//     );
// };

export const renderBookingRestriction = ({
    bookingRestriction,
}: {
    bookingRestriction?: BookingRestriction;
}) => {
    if (!bookingRestriction) {
        return <div>{"-"}</div>;
    }

    const {
        expireAction,
        startDateTime,
        endDateTime,
        includeWaitingList,
        includeBookingList,
        membersOnly,
        blockedPlaces,
    } = bookingRestriction;

    const startDate = startDateTime
        ? moment(startDateTime).format("DD.MM.YY")
        : "";
    const endDate = endDateTime ? moment(endDateTime).format("DD.MM.YY") : "";

    // console.log("expireAction", expireAction);
    const onExpire = expireAction
        ? expireAction === ExpireAction.ShowToAll
            ? "Anzeigen"
            : "Verbergen"
        : "-";

    return (
        <Descriptions
            column={1}
            size="small"
            layout="horizontal"
            colon={false}
            // bordered
            labelStyle={{fontSize: "1em", width: "90%"}}
            contentStyle={{fontSize: "1em", width: "10%"}}
        >
            {/* <Descriptions.Item label="Start - Ende">
                {`${startDate} - ${endDate}`}
            </Descriptions.Item>
            <Descriptions.Item label="Nach Ablauf">
                {onExpire}
            </Descriptions.Item> */}
            <Descriptions.Item label="Warteliste wird berücksichtigen">
                {renderBooleanAsCheckmark(includeWaitingList)}
            </Descriptions.Item>
            <Descriptions.Item label="Buchungen werden berücksichtigen">
                {renderBooleanAsCheckmark(includeBookingList)}
            </Descriptions.Item>
            {/* <Descriptions.Item label="Nur für Mitglieder">
                {renderBooleanAsCheckmark(membersOnly)}
            </Descriptions.Item> */}
            {/* <Descriptions.Item label="Blockierte Plätze">
                {blockedPlaces}
            </Descriptions.Item> */}
        </Descriptions>
    );
};
