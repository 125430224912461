import Roles from "../../../../shared/models/roles";
import {getSubDomain} from "../../../helpers/getFirstPathnameSegment";

const SUBDOMAIN = getSubDomain();

export const getHighestRole = (roles: Array<Roles>): Roles => {
    if (roles.includes(Roles.Superuser)) return Roles.Superuser;
    if (roles.includes(Roles.BetaTester)) return Roles.BetaTester;
    if (roles.includes(Roles.Admin)) return Roles.Admin;
    if (roles.includes(Roles.Instructor)) return Roles.Instructor;
    if (roles.includes(Roles.Staff)) return Roles.Staff;

    return Roles.EndUser;
};

export const subDomainShort = (): string => {
    if (SUBDOMAIN === "stage") return "stage";
    if (SUBDOMAIN === "localhost") return "dev";
    if (SUBDOMAIN === "app") return "";

    return SUBDOMAIN;
};
