import React, {FC, memo, ReactNode} from "react";
import {LoadingOutlined} from "@ant-design/icons";
import {Spin as AntdSpin} from "antd";
import css from "./Spin.less";

const icon = <LoadingOutlined className={css.icon} spin />;

type SpinProps = {
    children?: ReactNode;
    style?: React.CSSProperties;
    size?: "small" | "default" | "large";
};

const Spin: FC<SpinProps> = ({children, style, size}) => {
    return (
        <AntdSpin
            className={css.root}
            indicator={icon}
            delay={100}
            size={size ?? "default"}
            style={style}
        >
            {children}
        </AntdSpin>
    );
};

export default memo(Spin);
