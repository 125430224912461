import React, {FC, memo, useRef, useEffect} from "react";
import {Button, message, Tooltip, Modal} from "antd";
import {CopyOutlined} from "@ant-design/icons";
import iframeResizer from "iframe-resizer/js/iframeResizer";
import css from "./ReferenceCourseLinkPreview.less";

type ReferenceCourseLinkPreviewProps = {
    url: string;
    visible: boolean;
    onClose: () => void;
};

const ReferenceCourseLinkPreview: FC<ReferenceCourseLinkPreviewProps> = ({
    url,
    visible,
    onClose,
}) => {
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!iframeRef.current) return;

        const resizer = iframeResizer(
            {
                log: false,
                warningTimeout: 0,
                heightCalculationMethod: "lowestElement",
                checkOrigin: false,
                sizeWidth: false,
                scrolling: "auto",
                onResized: ({height}) => {
                    // Wenn die Höhe größer als der Viewport ist, setzen wir eine maximale Höhe
                    if (containerRef.current) {
                        const maxHeight = window.innerHeight - 120; // 120px für Header und Padding

                        containerRef.current.style.height =
                            Math.min(height, maxHeight) + "px";
                    }
                },
                resizeDebounce: 100,
            },
            iframeRef.current,
        )[0];

        // eslint-disable-next-line consistent-return
        return () => {
            if (resizer?.removeListeners) {
                resizer.removeListeners();
            }
        };
    }, [visible]); // Re-initialize when modal visibility changes

    const handleCopyClick = async () => {
        try {
            await navigator.clipboard.writeText(url);
            message.success("Link wurde in die Zwischenablage kopiert");
        } catch (err) {
            message.error("Fehler beim Kopieren des Links");
        }
    };

    const CopyLinkButton = ({key}: {key: string}) => {
        return (
            <Tooltip key={key} title="Link kopieren">
                <Button
                    onClick={handleCopyClick}
                    block
                    type="link"
                    style={{cursor: "pointer"}}
                >
                    <CopyOutlined />
                    <span>{url}</span>
                </Button>
            </Tooltip>
        );
    };

    return (
        <Modal
            title="Vorschau der Kundenansicht"
            open={visible}
            onCancel={onClose}
            footer={[<CopyLinkButton key="copyLinkButton" />]}
            width="1200px"
            style={{
                top: 24,
                paddingBottom: 0,
                padding: 0,
            }}
            bodyStyle={{
                padding: 0,
                margin: 0,
            }}
            // destroyOnClose
        >
            <div ref={containerRef} className={css.iFrameContainer}>
                <iframe
                    id="iframe"
                    ref={iframeRef}
                    title="Preview"
                    src={url}
                    scrolling="no"
                    className={css.iframe}
                />
            </div>
        </Modal>
    );
};

export default memo(ReferenceCourseLinkPreview);
