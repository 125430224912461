import React, {FC, memo, useEffect, useState} from "react";
import {Layout} from "antd";
import {Link} from "react-router-dom";
import {CloseOutlined, MenuOutlined} from "@ant-design/icons";
import {
    GetCompanyInfoQuery,
    useGetCompanyInfoQuery,
} from "../../generated/graphql";
import {URL_CHANGELOG} from "../../models/url";
import LogoTypographic from "../../assets/logo-typographic.svg";
import packageJson from "../../../../package.json";
import css from "./LayoutStandard.less";
import {LayoutStandardProps} from "./types";
import {subDomainShort} from "./utils/helpers";
import {useRoles} from "./hooks/useRoles";
import MainMenu from "./components/MainMenu";
import {menuWidth} from "../../styles/layout";

const LayoutStandard: FC<LayoutStandardProps> = ({
    navigationIsOpen = true,
    secondLevelNavigation = null,
    main = null,
    pageTitle,
    secondLevelNavigationItems,
}) => {
    const [_navigationIsOpen, toggleNavigation] = useState(navigationIsOpen);
    const contentAreaClasses = [css.contentArea];
    const menuToggleClasses = [css.mainMenuToggle];
    const menuTextClasses = [css.mainMenuItemTextClosed];
    const isStage = subDomainShort() === "stage";
    // const isStage = true;

    if (_navigationIsOpen) {
        menuToggleClasses.push(css.mainMenuIsOpen);
        contentAreaClasses.push(css.withMenuOpen);
        menuTextClasses.push(css.mainMenuItemTextOpen);
    }

    const pollIntervallInMinutes = 5;
    const pollIntervallInMilliseconds = pollIntervallInMinutes * 60 * 1000;

    const [companyInfo, setCompanyInfo] = useState<
        GetCompanyInfoQuery["companyInfo"] | undefined
    >(undefined);
    const {data: companyInfoData} = useGetCompanyInfoQuery();

    useEffect(() => {
        if (companyInfoData?.companyInfo) {
            setCompanyInfo(companyInfoData.companyInfo);
        }
    }, [companyInfoData]);

    const {highestRole, rolesOfCurrent, isInstructor} = useRoles(
        pollIntervallInMilliseconds,
    );

    return (
        <>
            <Layout className={css.root}>
                <Layout.Header className={isStage ? css.stage : css.header}>
                    <button
                        type="button"
                        // type="text"
                        className={menuToggleClasses.join(" ")}
                        onClick={() => toggleNavigation(!_navigationIsOpen)}
                    >
                        {_navigationIsOpen ? (
                            <CloseOutlined style={{fontSize: "0.95em"}} />
                        ) : (
                            <MenuOutlined />
                        )}
                        {_navigationIsOpen ? "Menu" : ""}
                    </button>
                    {secondLevelNavigation !== null && (
                        <div className={css.secondLevelNavigation}>
                            {pageTitle !== undefined && (
                                <h2 className={css.pageTitle}>
                                    {pageTitle(_navigationIsOpen)}
                                </h2>
                            )}
                            {secondLevelNavigation}
                        </div>
                    )}
                    <div className={css.secondLevelNavigationLogo}>
                        <img src={LogoTypographic} alt="logo-typographic" />
                        <Link
                            to={{
                                pathname: URL_CHANGELOG.replace(
                                    ":highestRole",
                                    highestRole,
                                ),
                            }}
                        >
                            <div className={css.versionNumber}>
                                {`V ${packageJson.version} ${subDomainShort()}`}
                            </div>
                        </Link>
                    </div>
                </Layout.Header>
                <Layout className={css.contentAreaWrapper}>
                    <Layout.Sider
                        collapsed={!_navigationIsOpen}
                        collapsedWidth={38}
                        // collapsedWidth={138}
                        breakpoint="xxl"
                        onBreakpoint={(isClosed) => {
                            toggleNavigation(!isClosed);
                        }}
                        width={menuWidth}
                        trigger={null}
                    >
                        <MainMenu
                            className={isStage ? css.stage : undefined}
                            highestRole={highestRole}
                            rolesOfCurrent={rolesOfCurrent}
                            isInstructor={isInstructor}
                            menuTextClasses={menuTextClasses}
                            closed={!_navigationIsOpen}
                            companyInfo={companyInfo}
                            secondLevelNavigationItems={
                                secondLevelNavigationItems
                            }
                            navigationIsOpen={_navigationIsOpen}
                            isStage={isStage}
                        />
                    </Layout.Sider>
                    <Layout className={contentAreaClasses.join(" ")}>
                        <Layout id="modalmount">{main}</Layout>
                    </Layout>
                </Layout>
            </Layout>
        </>
    );
};

export default memo(LayoutStandard);
