import {ReactNode} from "react";
import {SubMenuType} from "antd/lib/menu/hooks/useItems";
import {MenuProps} from "antd";
import {GetCompanyInfoQuery} from "../../generated/graphql";
import Roles from "../../../shared/models/roles";

// URL Mapping Type
export const URL_TO_KEY_MAP = {
    URL_DASHBOARD: "dashboard",
    URL_CUSTOMERS: "customers",
    URL_COURSES: "courses",
    URL_LOCATIONS: "locations",
    URL_BOOKINGS: "bookings",
    URL_LESSONS_TODAY: "lessonsToday",
    URL_LESSONS_TODAY_CALENDAR_WEEKVIEW: "lessonsToday",
    URL_MY_BOOKINGS: "my-bookings",
    URL_INVOICE: "invoice",
    URL_PAYMENTS: "payments",
    URL_STAFF: "staff",
    URL_STAFF_LOGIN: "staffLogin",
    URL_TEMPLATES: "templates",
    URL_NOTIFICATIONS: "notifications",
    URL_BADGES: "badges",
    URL_SETTINGS: "settings",
} as const;

// Navigation Types
export type SecondLevelNavigationItem = SubMenuType & {
    link: string;
    topMenuKey: keyof typeof URL_TO_KEY_MAP;
};

export type MenuNavigationItem = MenuProps["items"][0]["children"][number];

export type CustomMenuProps = MenuProps["items"][0] & {url: string};

export type LayoutStandardProps = {
    navigationIsOpen?: boolean;
    secondLevelNavigation?: ReactNode;
    main?: ReactNode;
    pageTitle?: (navigationOpen: boolean) => string | JSX.Element;
    secondLevelNavigationItems?: Array<SecondLevelNavigationItem>;
};

// Menu Types
export type MainMenuProps = {
    className?: string;
    highestRole: Roles;
    rolesOfCurrent: Array<Roles>;
    isInstructor?: boolean;
    menuTextClasses?: Array<string>;
    closed?: boolean;
    companyInfo?: GetCompanyInfoQuery["companyInfo"];
    secondLevelNavigationItems?: Array<SecondLevelNavigationItem>;
    navigationIsOpen?: boolean;
    isStage?: boolean;
};

export type MenuItem = Required<MenuProps>["items"][number];
