import {Button, Space, Spin, Table} from "antd";
import React, {FC, memo, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import Maybe from "graphql/tsutils/Maybe";
import {MinusSquareOutlined, PlusSquareOutlined} from "@ant-design/icons";
import {
    FilterValue,
    RowSelectionType,
    SorterResult,
    TableCurrentDataSource,
    TablePaginationConfig,
} from "antd/lib/table/interface";
import {ColumnProps} from "antd/lib/table";
import {
    ArrayDataOptions,
    BlankMailTypes,
    GetBookingsByCourseQuery,
    GetBookingsByCourseQueryVariables,
    GetCoursesWithPaginationQuery,
    // GetReceiversByCourseIdsQuery,
    MailDataType,
    SortOrder,
    TablesEnum,
    useGetBookingsByCourseQuery,
    // useGetReceiversByCourseIdsLazyQuery,
} from "../../../generated/graphql";
import {
    URL_COURSES_CREATE,
    URL_COURSES_DETAILS_ID,
    URL_COURSES_EDIT_ID,
} from "../../../models/url";
import i18n from "../../../services/i18n";
import Page from "../../../views/Page/Page";
import Section from "../../../views/Section/Section";
import TableToolBar, {
    SelectedRowKeysType,
    useRowSelection,
} from "../../common/tableTools/TableToolBar";
import {TableType, useHandleColumns, rowKey} from "./CourseListColumns";
import BookingList from "../../bookings/BookingList/BookingList";
import {getPagination} from "../../../helpers/getPagination";
import {TableType as BookingTableType} from "../../bookings/BookingList/BookingListColumns";
import ActiveFilters from "../../common/tableTools/ActiveFilters";
import {updateFilters} from "../../../helpers/updateFilters";

const checkbox: RowSelectionType = "checkbox";
const radio: RowSelectionType = "radio";

export type TableTypeWithOmitedColumns = Omit<
    TableType,
    | "bookingCountWithoutWaitlist"
    | "waitListCount"
    | "bookingCount"
    | "canceledBookingsCount"
    | "startDateTime"
    | "endDateTime"
    | "endDateTimeFirstCourseLesson"
    | "monthlySchedule"
    | "installmentRates"
    | "visibility"
>;

export const allColumnsDefault = (): {
    [key in keyof TableTypeWithOmitedColumns]: string;
} => {
    const columnNames: {[key in keyof TableTypeWithOmitedColumns]: string} = {
        actions: i18n.views.TableColumnTitles.actions(),
        id: i18n.views.TableColumnTitles.id(),
        courseNumber: i18n.views.TableColumnTitles.courseNumber(),
        prefixedCourseNumber: i18n.views.TableColumnTitles.prefixedCourseNumber(),
        waitlistCount: i18n.views.TableColumnTitles.waitlistCount(),
        bookingsCount: i18n.views.TableColumnTitles.bookingsCount(),
        placeReservationsCount: i18n.views.TableColumnTitles.placeReservationsCount(),
        bookingOverview: i18n.views.TableColumnTitles.bookingOverview(),
        billedBookingCount: i18n.views.TableColumnTitles.billedBookingCount(),
        freePlaces: i18n.views.TableColumnTitles.freePlaces(),
        settledBookingCount: i18n.views.TableColumnTitles.settledBookingCount(),
        courseType: i18n.views.TableColumnTitles.courseType(),
        lastCourseLesson: i18n.views.TableColumnTitles.lastCourseLesson(),
        location: i18n.views.TableColumnTitles.location(),
        venue: i18n.views.TableColumnTitles.venue(),
        annotation: i18n.views.TableColumnTitles.annotation(),
        lessons: i18n.views.TableColumnTitles.lessons(),
        instructors: i18n.views.TableColumnTitles.instructors(),
        maxAttendees: i18n.views.TableColumnTitles.maxAttendees(),
        coursePrice: i18n.views.TableColumnTitles.coursePrice(),
        paymentInterval: i18n.views.TableColumnTitles.paymentInterval(),
        allowMonthlyPayment: i18n.views.TableColumnTitles.allowMonthlyPayment(),
        city: i18n.views.TableColumnTitles.city(),
        firstCourseLesson: i18n.views.TableColumnTitles.firstCourseLesson(),
        isActive: i18n.views.TableColumnTitles.isActive(),
        showInWeb: i18n.views.TableColumnTitles.showInWeb(),
        courseDetails: i18n.views.TableColumnTitles.courseDetails(),
        lessonCount: i18n.views.TableColumnTitles.lessonCount(),
    };

    return columnNames;
};

const standardHiddenColumns: Array<keyof TableType> = [
    "id",
    "actions",
    "courseNumber",
    "paymentInterval",
    "actions",
];

const mandatoryColumns: Array<keyof TableType> = ["prefixedCourseNumber"];

const standardColumns: Array<keyof TableType> = [
    "actions",
    "prefixedCourseNumber",
    "courseType",
    "firstCourseLesson",
    "location",
    "venue",
    "freePlaces",
    "maxAttendees",
    "instructors",
    "coursePrice",
];

const createLink = URL_COURSES_CREATE;
const editLink = URL_COURSES_EDIT_ID;
const detailsLink = URL_COURSES_DETAILS_ID;

type CourseListProps = {
    toolBarButtons?: Array<
        "columnSelector" | "pdf" | "eMail" | "bulkDelete" | "checkList" | "sms"
    >;
    bookingCourse?: boolean;
    tableTitle?: string;
    defaultColumns?: Array<Extract<keyof TableType, string>>;
    hiddenColumns?: Array<Extract<keyof TableType, string>>;
    actionColumn?: Function;
    handleCourseSelection?: Function;
    selectedCourseId?: Maybe<string>;
    defaultPageSize?: number;
    tableData: {
        // dataSource: Array<TableType>;
        dataSource: GetCoursesWithPaginationQuery["coursesWithPagination"]["items"];
        existMore?: GetCoursesWithPaginationQuery["coursesWithPagination"]["existsMore"];
        total?: GetCoursesWithPaginationQuery["coursesWithPagination"]["total"];
        loading?: boolean;
    };
    isExpandable?: boolean;
    customMenu?: React.ReactNode;
    options: Maybe<ArrayDataOptions>;
    setOptions: (options: ArrayDataOptions) => void;
    refetch: () => void;
    // mandatoryColumns?: Array<string>;
    tablesEnum: TablesEnum;
    customAllColumns?: {[key in keyof TableTypeWithOmitedColumns]: string};
};

const CourseList: FC<CourseListProps> = ({
    toolBarButtons = ["columnSelector", "pdf", "eMail", "sms", "bulkShowInWeb"],
    bookingCourse = false,
    tableTitle = i18n.containers.courses.Courses.courseTableTitle(),
    defaultColumns = standardColumns,
    actionColumn,
    hiddenColumns = standardHiddenColumns,
    handleCourseSelection,
    selectedCourseId,
    defaultPageSize = 15,
    tableData,
    // optionsCallBack,
    isExpandable = false,
    customMenu,
    options,
    setOptions,
    refetch,
    tablesEnum,
    customAllColumns,

    // mandatoryColumns,
}) => {
    const allColumns = customAllColumns
        ? customAllColumns
        : allColumnsDefault();
    const [columnsToShow, setColumnsToShow] = useState<
        Array<Extract<keyof TableType, string>>
    >([]);

    const hideFiltersAndSorters = false;

    const columns: Array<ColumnProps<TableType>> = useHandleColumns(
        columnsToShow,
        editLink,
        detailsLink,
        options,
        setOptions,
        actionColumn,
        options?.filter,
        hideFiltersAndSorters,
    );

    // ------------------------------ 1. Column Selector ------------------------------

    const history = useHistory();

    const [selectionType, setSelectionType] = useState<RowSelectionType>(
        bookingCourse ? radio : checkbox,
    );

    // CHECK IF THERE IS A DEFAULTSELECTION GIVEN ------------------------------------------------------------
    const [defaultSelection, setDefaultSelection] = useState<
        SelectedRowKeysType
    >(selectedCourseId ? [selectedCourseId] : []);

    const rowSelection = useRowSelection({
        type: selectionType,
        defaultSelectedKeys: defaultSelection,
    });

    // PRESELECTED COURSE WHEN IN CREATE BOOKING -----------------------------------------------------------

    useEffect(() => {
        if (selectedCourseId) {
            setDefaultSelection([selectedCourseId]);
        }
    }, [selectedCourseId]);

    useEffect(() => {
        // Update the selected course
        const rowData = tableData.dataSource.find(
            (row) => row.id === rowSelection.selectedRowKeys[0],
        );

        if (rowData) {
            handleCourseSelection?.([rowData]);
        }
    }, [
        handleCourseSelection,
        rowSelection.selectedRowKeys,
        tableData.dataSource,
    ]);

    // ROW EXPAND ----------------------------------------------------------
    const [expandedRowKeys, setExpandedRowKeys] = useState<Array<string>>([]);
    const [bookingOptions, setBookingOptions] = useState<
        GetBookingsByCourseQueryVariables["options"]
    >({});

    const rowIsExpanded = expandedRowKeys.length > 0;
    const idIsDefined = expandedRowKeys[0] !== undefined;
    const skip = !rowIsExpanded || !idIsDefined || !bookingOptions;

    const {
        data: bookingsByCourse,
        loading: loadingBookingsByCourse,
        refetch: refetchBookingsByCourse,
    } = useGetBookingsByCourseQuery({
        skip,
        variables: {id: expandedRowKeys[0], options: bookingOptions},
        fetchPolicy: "network-only",
        pollInterval: skip ? undefined : 30000,
    });

    // const bookingsByCourseData = [];
    const bookingsByCourseData: GetBookingsByCourseQuery["getBookingsByCourse"]["items"] =
        bookingsByCourse?.getBookingsByCourse?.items ?? [];
    const bookingsByCourseTotal =
        bookingsByCourse?.getBookingsByCourse?.total ?? 0;

    useEffect(() => {
        setBookingOptions({});
    }, [expandedRowKeys]);

    useEffect(() => {
        if (expandedRowKeys.length > 0) {
            console.log("REFETCH ::: bookingOptions: ", bookingOptions);
            refetchBookingsByCourse({
                id: expandedRowKeys[0],
                options: bookingOptions,
            });
        }
    }, [bookingOptions, expandedRowKeys, refetchBookingsByCourse]);

    // MENU -----------------------------------------------------------

    const standardMenu = (
        <Button
            type="primary"
            size="small"
            onClick={() => history.push(createLink)}
        >
            {i18n.containers.courses.Courses.createCourse()}
        </Button>
    );

    // ----------------------------------------------------------------------

    const onChange: (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<TableType> | Array<SorterResult<TableType>>,
        extra: TableCurrentDataSource<TableType>,
    ) => void = (pagination, filters, sorter, extra) => {
        // console.log("params", pagination, filters, sorter, extra);

        const updatedFilters = updateFilters({
            filters,
            options,
            table: "course",
        });

        const sortColumn = sorter.field;
        const sortOrder: Maybe<SortOrder> =
            sorter.order === "ascend"
                ? SortOrder.Ascending
                : sorter.order === "descend"
                ? SortOrder.Descending
                : undefined;

        const updatedOptions = {
            ...options,
            filter: updatedFilters,
            limit: getPagination({
                pagination,
                defaultSize: defaultPageSize,
            }).limit,
            offset: getPagination({
                pagination,
                defaultSize: defaultPageSize,
            }).offset,
            sortColumn,
            sortOrder,
        };

        setOptions(updatedOptions);
    };

    // Expandable -------------------------------------------------------------

    const renderExpandedRow = (
        course: TableType /* , dataSource:Array<GetCoursesQueryItem> */,
    ) => {
        if (isExpandable) {
            const defaultColumnsBookings = [
                "bookingNumber",
                "attendeeFullName",
                "bookerFullName",
                "grossPrice",
                "actions",
            ];

            if (bookingsByCourseData.length) {
                return (
                    <div style={{margin: 0, marginTop: 10}}>
                        <BookingList
                            hideCreate
                            hideHeader
                            hidePagination
                            tableData={{
                                dataSource: bookingsByCourseData as Array<
                                    BookingTableType
                                >,
                                total: bookingsByCourseTotal,
                                loading: loadingBookingsByCourse,
                            }}
                            defaultColumns={
                                defaultColumnsBookings as Array<
                                    keyof BookingTableType
                                >
                            }
                            // actionColumn={(record: BookingTableType) => {
                            //     return (
                            //         <CancelSettledBookingAction
                            //             record={record}
                            //             refetchRequest={refetchBookingsByCourse}
                            //         />
                            //     );
                            // }}
                            options={bookingOptions}
                            setOptions={setBookingOptions}
                            refetch={refetchBookingsByCourse}
                            tablesEnum={
                                TablesEnum.BookingsByCourseExpandedBookings
                            }
                        />
                    </div>
                );
            }

            return (
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    {loadingBookingsByCourse ? (
                        <Spin />
                    ) : (
                        <div>
                            {i18n.containers.bookings.BookingList.expandable.noBookings()}
                        </div>
                    )}
                </div>
            );
        }

        return null;
    };

    const expandIcon = ({expanded, onExpand, record}) =>
        expanded ? (
            <MinusSquareOutlined
                onClick={(e) => onExpand(record, e)}
                tabIndex={0}
                size={16}
            />
        ) : (
            <PlusSquareOutlined
                onClick={(e) => onExpand(record, e)}
                tabIndex={0}
                size={16}
            />
        );

    const onExpand = (expanded: boolean, record: TableType) => {
        if (expanded) {
            setExpandedRowKeys([record.id]);
        } else {
            setExpandedRowKeys([]);
            setBookingOptions({});
        }
    };

    // RENDER ------------------------------------------------------
    const renderCourseTable = () => {
        return (
            <Section
                showHeader={!bookingCourse}
                title={tableTitle}
                menu={customMenu ? customMenu : standardMenu}
                secondLineLeft={
                    <Space
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignContent: "center",
                            padding: "2px 0px",
                        }}
                    >
                        <TableToolBar
                            buttons={toolBarButtons}
                            columns={columns}
                            allColumns={allColumns}
                            variableKeys={allColumns}
                            columnsToShow={columnsToShow}
                            setSelectedColumns={(e: Array<keyof TableType>) =>
                                setColumnsToShow(e)
                            }
                            mandatoryColumns={mandatoryColumns}
                            hiddenColumns={hiddenColumns}
                            rowSelection={rowSelection}
                            selectMode={(type: RowSelectionType) =>
                                setSelectionType(type)
                            }
                            exportFileName={tableTitle}
                            dataSource={tableData.dataSource}
                            type={BlankMailTypes.Course}
                            mailDataType={MailDataType.Course}
                            tablesEnum={tablesEnum}
                            defaultColumns={defaultColumns as Array<string>}
                            refetch={refetch}
                        />
                        <ActiveFilters
                            options={options}
                            setOptions={setOptions}
                            refetch={refetch}
                        />
                    </Space>
                }
                content={
                    <Table<TableType>
                        showSorterTooltip={false}
                        size="small"
                        bordered
                        columns={columns}
                        dataSource={tableData.dataSource}
                        rowKey={rowKey}
                        loading={tableData.loading}
                        rowSelection={rowSelection.result}
                        sticky={tableData.dataSource.length > 0}
                        scroll={
                            tableData.dataSource.length
                                ? {
                                      x: `max-content`,
                                      y: "calc(100vh - 300px)",
                                  }
                                : {x: "auto"}
                        }
                        onChange={onChange}
                        pagination={{
                            current:
                                options?.offset && options?.limit
                                    ? options.offset / options.limit + 1
                                    : 1,
                            pageSize: options?.limit ?? defaultPageSize,
                            showSizeChanger: true,
                            pageSizeOptions: ["10", "15", "20", "25"],
                            defaultPageSize,
                            total: tableData.total,
                        }}
                        onRow={(record) => {
                            if (rowSelection.type === "radio") {
                                return {
                                    onClick: () => {
                                        rowSelection.setSelectedRowKeys([
                                            record.id,
                                        ]);
                                    },
                                };
                            }

                            return {};
                        }}
                        expandIconAsCell={false}
                        showExpandColumn={isExpandable}
                        expandable={{
                            expandedRowRender: renderExpandedRow,
                            onExpand,
                            expandedRowKeys,
                            expandIcon,
                        }}
                        footer={(currentPageData) =>
                            `${i18n.containers.bookings.BookingList.totalCount()} ${
                                tableData.total
                            }`
                        }
                    />
                }
            />
        );
    };

    if (bookingCourse) {
        return renderCourseTable();
    }

    return <Page data-testid="CourseList" content={renderCourseTable()} />;
};

export default memo(CourseList);
