import React, {FC, memo} from "react";
import {Maybe} from "type-graphql";
import {message} from "antd";
import {Formik} from "formik";
import {
    Course,
    ExpireAction,
    GetCourseByIdDocument,
    GetRestrictiveBookingsByCourseIdDocument,
    GetRestrictiveBookingsByCourseIdQuery,
    NewBookingRestrictionInput,
    UpdateBookingRestrictionInput,
    useCreateBookingRestrictionMutation,
    useUpdateBookingRestrictionMutation,
    useDeleteBookingRestrictionMutation,
    GetCoursesWithPaginationDocument,
} from "../../../generated/graphql";
import RestrictiveBookingForm from "./RestrictiveBookingForm";

export type PosibleFormikProps = NewBookingRestrictionInput;

type EditRestrictiveBookingProps = {
    courseId: Maybe<Course["id"]> | undefined;
    setOpen: (open: boolean) => void;
    setActive: (active: boolean) => void;
    existingRestrictiveBooking?: GetRestrictiveBookingsByCourseIdQuery["getBookingRestrictionByCourseId"];
    refetch?: () => void;
};

const EditRestrictiveBooking: FC<EditRestrictiveBookingProps> = ({
    courseId,
    setOpen,
    setActive,
    existingRestrictiveBooking,
    refetch,
}) => {
    const [submitting, setSubmitting] = React.useState(false);
    const [createNew] = useCreateBookingRestrictionMutation({
        refetchQueries: [
            "GetRestrictiveBookingsByCourseId",
            {
                query: GetRestrictiveBookingsByCourseIdDocument,
                variables: {courseId},
            },
            "GetCourseById",
            {
                query: GetCourseByIdDocument,
                variables: {id: courseId},
            },
        ],
    });
    const [updateExisting] = useUpdateBookingRestrictionMutation({
        refetchQueries: [
            "GetRestrictiveBookingsByCourseId",
            {
                query: GetRestrictiveBookingsByCourseIdDocument,
                variables: {courseId},
            },
            "GetCourseById",
            {
                query: GetCourseByIdDocument,
                variables: {id: courseId},
            },
            "GetCoursesWithPagination",
            {
                query: GetCoursesWithPaginationDocument,
                // variables: {
                //     options: {
                //         courseIds: [courseId],
                //     },
                // },
            },
        ],
    });

    const [deleteBookingRestriction] = useDeleteBookingRestrictionMutation({
        refetchQueries: [
            "GetRestrictiveBookingsByCourseId",
            {
                query: GetRestrictiveBookingsByCourseIdDocument,
                variables: {courseId},
            },
            "GetCourseById",
            {
                query: GetCourseByIdDocument,
                variables: {id: courseId},
            },
        ],
    });

    const isEmptyRestriction = (values: PosibleFormikProps): boolean => {
        return (
            !values.membersOnly &&
            (!values.prerequisiteCourses ||
                values.prerequisiteCourses.length === 0) &&
            !values.startDateTime &&
            !values.endDateTime &&
            !values.blockedPlaces
        );
    };

    const handleSubmit = async (values: NewBookingRestrictionInput) => {
        if (existingRestrictiveBooking && isEmptyRestriction(values)) {
            // Wenn die Buchungsbeschränkung leer ist, lösche sie
            await handleDelete();

            return;
        }

        if (existingRestrictiveBooking) {
            const {id} = existingRestrictiveBooking;
            const updateBookingRestrictionInput: UpdateBookingRestrictionInput = {
                id,
                courseId: values.courseId,
                // active: isSomethingActive(values),
                active: values.active,
                membersOnly: values.membersOnly,
                visibleForAll: values.visibleForAll,
                blockedPlaces: values.blockedPlaces ?? undefined,
                includeBookingList: values.includeBookingList,
                includeWaitingList: values.includeWaitingList,
                startDateTime: values.startDateTime,
                endDateTime: values.endDateTime,
                prerequisiteCourseIds: values.prerequisiteCourses,
                expireAction: values.expireAction,
            };

            try {
                setSubmitting(true);
                await updateExisting({
                    variables: {
                        updateBookingRestrictionInput,
                    },
                }).then(() => {
                    setSubmitting(false);
                    refetch?.();
                    message.success("Booking restriction updated");
                });
            } catch (error) {
                console.error(error);
                message.error("Error creating booking restriction");
            } finally {
                setSubmitting(false);
            }
        } else {
            // Erstelle keine neue Beschränkung wenn sie leer ist
            if (isEmptyRestriction(values)) {
                setOpen(false);

                return;
            }
            try {
                setSubmitting(true);
                await createNew({
                    variables: {
                        newBookingRestrictionInput: values,
                    },
                }).then(() => {
                    setSubmitting(false);
                    message.success("Buchungsbeschränkung erstellt");
                });
            } catch (error) {
                console.error(error);
                message.error("Fehler beim Erstellen der Buchungsbeschränkung");
            } finally {
                setSubmitting(false);
            }
        }
    };

    const handleDelete = async () => {
        if (!existingRestrictiveBooking?.id) return;

        try {
            setSubmitting(true);
            await deleteBookingRestriction({
                variables: {
                    bookingRestrictionId: existingRestrictiveBooking.id,
                },
                // Add awaitRefetchQueries to ensure queries are refetched before continuing
                awaitRefetchQueries: true,
            });
            message.success("Buchungsbeschränkung wurde gelöscht");
            setActive(false);
            setOpen(false);
        } catch (error) {
            console.error(error);
            message.error("Fehler beim Löschen der Buchungsbeschränkung");
        } finally {
            setSubmitting(false);
        }
    };

    if (!courseId) {
        return <div>{"No courseId"}</div>;
    }

    const prerequisiteCourseIds =
        existingRestrictiveBooking?.prerequisiteCourses?.map(
            (course) => course.id,
        ) ?? undefined;

    const initialValues: NewBookingRestrictionInput = existingRestrictiveBooking
        ? {
              courseId: existingRestrictiveBooking.courseId,
              active: existingRestrictiveBooking.active,
              membersOnly: existingRestrictiveBooking.membersOnly,
              visibleForAll: existingRestrictiveBooking.visibleForAll,
              blockedPlaces: existingRestrictiveBooking.blockedPlaces,
              includeBookingList: existingRestrictiveBooking.includeBookingList,
              includeWaitingList: existingRestrictiveBooking.includeWaitingList,
              startDateTime: existingRestrictiveBooking.startDateTime,
              endDateTime: existingRestrictiveBooking.endDateTime,
              prerequisiteCourses: prerequisiteCourseIds,
              expireAction: existingRestrictiveBooking.expireAction,
          }
        : {
              courseId,
              active: false,
              membersOnly: false,
              visibleForAll: true,
              blockedPlaces: 0,
              includeBookingList: true,
              includeWaitingList: false,
              startDateTime: null,
              endDateTime: null,
              prerequisiteCourses: undefined,
              expireAction: ExpireAction.ShowToAll,
          };

    return (
        <Formik<PosibleFormikProps>
            initialValues={initialValues}
            onSubmit={async (values) => {
                // console.log("values", values);
                await handleSubmit(values);
            }}
            enableReinitialize
        >
            {(formikProps) => {
                // console.log("formikProps.values", formikProps.values);

                return (
                    <RestrictiveBookingForm
                        formikProps={formikProps}
                        submit={async (values) =>
                            handleSubmit(values).then(() => setOpen(false))
                        }
                        submitting={submitting}
                        setActive={setActive}
                        onDelete={handleDelete}
                        existingBookingRestriction={Boolean(
                            existingRestrictiveBooking,
                        )}
                    />
                );
            }}
        </Formik>
    );
};

export default memo(EditRestrictiveBooking);
